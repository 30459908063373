import React from "react";

// reactstrap components
import {    
    Container
 } from "reactstrap";

// core components
import ColorNavbarATM from "../components/Navbars/ColorNavbarATM.js";
import FooterATMVenta from "../components/Footers/FooterATMVenta.js";
import AboutUsHeader from "../components/Headers/AboutUsHeader.js";
import FooterATM from "../components/Footers/FooterATM.js";
import { TipoOficina } from "../othelloConstantes.jsx";

function Condiciones()
{

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("about-us");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("about-us");
        };
    });

    const [oficina, setOficina] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        fetch('api/Data/GeCondicionesOficinaSelected')
            .then(response => response.json())
            .then(data => {
                setOficina(data);
                setLoading(false)
            }).catch(error => {
                setOficina(null);
                setLoading(false)
            });

    }, [loading]);


  return (
      <>
          <ColorNavbarATM />
          <AboutUsHeader texto={!loading && oficina != null ? oficina.nombre : ""}/>
          <div className="main">
              <div className="section">
                  <Container style={{ fontWeight: 'bold' }}>
                      <h3 className="title-uppercase"><b>{!loading && oficina != null ? oficina.nombre : ""}</b></h3>                     
                      <p>
                          {!loading && oficina != null && oficina.descripcion != null && oficina.descripcion != "" ? oficina.descripcion.split("\n").map((i, key) => { return <div key={key}>{i}</div>; }) : ""}
                      </p>                                   
                  </Container>
              </div>
          </div>
          {!loading && oficina != null && oficina.tipoofi == TipoOficina.Venta ? <FooterATMVenta /> : <FooterATM />}                    
    </>
  );
}

export default Condiciones;
