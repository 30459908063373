import React from "react";
// reactstrap components
import {
    Button,
    Modal,   
} from "reactstrap";

import { useTranslation } from 'react-i18next';

function HorarioModal({...props })
{
    const { t } = useTranslation();

    //variables de las propiedas recibidas
    const { open, setModal, titulo, horario } = props;

    //<button
    //    className="close"
    //    onClick={() => setModal(false)}
    //    type="button"
    //>
    //    ×
    //      </button>
    return (
        <>           
            <Modal isOpen={open} toggle={() => setModal(false)}>
                <div className="modal-header">
                    <h3 className="modal-title" id="myModalLabel">
                        <b>{titulo}</b>
                    </h3>                                     
                </div>
                <div className="modal-body">                                                       
                    <h4><b>{t("_horarioapertura")}</b></h4>                              
                    <br/>
                    <h5>{horario != null && horario != "" ? horario.split("\n").map((i, key) => { return <div key={key}>{i}</div>; }) : ""}</h5>                                                       
                </div>
                <div className="modal-footer">
                    <Button
                        className="btn-link"
                        color="primary"
                        type="button"
                        onClick={() => setModal(false)}
                    >
                        <b>{t("_ok")}</b>
          </Button>
                </div>
            </Modal>
        </>
    );
}

export default HorarioModal;