export const DataStatePage =
{
    dsDesconocido: 'desconocido',
    dsInsert: 'insert',
    dsEdit: 'edit',
    dsSearch: 'search',    
};

export const DataStateModal =
{
    dsClosed: false,
    dsOpen: true
}

export const ModoModal =
{
    SinDefinir : '',
    Back: 'back',
    Undo: 'undo',
    Delete: 'delete',    
};

export const ModoModalIdioma =
{
    Closed: 'close',
    Edit: 'edit',
    New: 'new'
};

export const TipoOthelloFieldGrid =
{
    OthelloComboBox: 'OthelloComboBox',
    OthelloEdit: 'OthelloEdit',  
    OthelloImagen: 'OthelloImagen', 
    OthelloTimer: 'OthelloTimer',
    OthelloButton: 'OthelloButton'
};

export const ModelosOficina = [
    { valor: "0", name: "Clasico" },
    { valor: "1", name: "Lista" },
    { valor: "2", name: "Imagen con Lista" }];

export const TiposOficina = [
    { valor: "0", name: "Estandar" },
    { valor: "1", name: "CaminoSantiago" },
    { valor: "2", name: "Ruta" },
    { valor: "3", name: "Venta" },
    { valor: "4", name: "Corporativa" },
];

export const TipoOficina =
{    
    Estandar: 0,    
    CaminoSantiago: 1,    
    Ruta: 2,    
    Venta: 3,    
    Corporativa: 4    
};

export const SiNo = {
    No : 0,
    Si : 1
}


export const OpcionSiNo = [
{ valor: "0", name: "No" },
{ valor: "1", name: "Si" }];


export const OpcionSiNoChar = [
    { valor: "N", name: "No" },
    { valor: "S", name: "Si" }];

export const LimiteProximidad = [
    { valor: "0", name: "Ninguna" },
    { valor: "1", name: "25" },
    { valor: "2", name: "50" },
    { valor: "3", name: "75" },
    { valor: "4", name: "100" },
    { valor: "5", name: "200" }];

export const Roles = {
    SuperUsuario : -1,
    Administrador : 0,
    Usuario : 1,
    UsuarioApp : 2
}

export const RolesUsuarios = [
    { valor: Roles.SuperUsuario, name: "SuperUsuario" },
    { valor: Roles.Administrador, name: "Administrador" },
    { valor: Roles.Usuario, name: "Usuario" },
    { valor: Roles.UsuarioApp, name: "UsuarioApp" }];

export const TipoArea =
{
    /// Area normal implica que tiene un funcionamiento normal, basado en si es afiliado 
    Normal: 0,
    /// Implica que los pois se gestiona de una manera distinta, es decir, como notificacion, noticia, agenda, por tanto no aperecera en los mapas
    Agenda: 1,
    /// Implica que en el mapa principal de menu, se va a mostrar siempre
    Principal: 2,
    /// Implica que el area direcciona a una web
    Web: 3,
    /// acceso a opciones externas que no funcionan dentro de la opcion web de las apps
    Externo: 4,
    /// Acceso al tiempo en una localidad
    Tiempo: 5,
    /// Gestiona la opcion de estar en un mes concreto y nos lleva al mes actual
    Calendario: 6,
    Telefono: 7,
    Whastapp: 8
};


export const TiposArea = [
    { valor: TipoArea.Normal, name: "Estandar" },
    { valor: TipoArea.Agenda, name: "Agenda" },
    { valor: TipoArea.Principal, name: "Principal" },
    { valor: TipoArea.Web, name: "Web" },
    { valor: TipoArea.Externo, name: "Externo" },
    { valor: TipoArea.Tiempo, name: "Tiempo" },
    { valor: TipoArea.Calendario, name: "Calendario" },
    { valor: TipoArea.Telefono, name: "Telefono" },
    { valor: TipoArea.Whastapp, name: "WhastApp" }
];

export const OrdenacionPoi = {
    Titulo: 0,
    OrdenVisual: 1,
    Proximidad: 2
};

export const OrdenacionPois = [
    { valor: OrdenacionPoi.Titulo, name: "Por Nombre POI" },
    { valor: OrdenacionPoi.OrdenVisual, name: "Por Orden Visual" },   
    { valor: OrdenacionPoi.Proximidad, name: "Por Proximidad" }];


export const  Visibilidad = {
    No : -1,
    Si : 0,
}

export const OpcionVisibilidad = [
    { valor: Visibilidad.No, name: "No" },
    { valor: Visibilidad.Si, name: "Si" }];


export const VisibilidadPoi = {
    Todos : -1,
    No: 1,
    Si: 0,
}

export const OpcionVisibilidadPoi = [
    { valor: VisibilidadPoi.No, name: "No" },
    { valor: VisibilidadPoi.Si, name: "Si" }];


export const TiposCategorias = {
    SinCategoria: '0',
    UnaEstrella: '1',
    DosEstrellas: '2',
    TresEstrellas: '3',
    CuatroEstrellas: '4',
    CincoEstrellas : '5',
    HostalUnaEstrella : '6',
    HostalDosEstrella : '7',
    PuntoMotero : '8',
    Escorial : '9',
    UnaLlave : 'a',
    DosLlaves : 'b',
    TresLlaves: 'c',
    InteresPeregrinos: 'd'
}

 export const Categorias =[
     { valor: TiposCategorias.SinCategoria, name: "Sin Categoria" },
     { valor: TiposCategorias.UnaEstrella, name: "Una Estrella" },
     { valor: TiposCategorias.DosEstrellas, name: "Dos Estrellas" },
     { valor: TiposCategorias.TresEstrellas, name: "Tres Estrellas" },
     { valor: TiposCategorias.CuatroEstrellas, name: "Cuatro Estrellas" },
     { valor: TiposCategorias.CincoEstrellas, name: "Cinco Estrellas" },
     { valor: TiposCategorias.HostalUnaEstrella, name: "Hostal Una Estrella" },
     { valor: TiposCategorias.HostalDosEstrella, name: "Hostal Dos Estrellas" },
     { valor: TiposCategorias.PuntoMotero, name: "Punto Motero" },
     { valor: TiposCategorias.Escorial, name: "Escorial" },
     { valor: TiposCategorias.UnaLlave, name: "Una Llave" },
     { valor: TiposCategorias.DosLlaves, name: "Dos Llaves" },
     { valor: TiposCategorias.TresLlaves, name: "Tres Llaves" },
     { valor: TiposCategorias.InteresPeregrinos, name: "Interes Peregrinos" }
];

export const ModoAreaPoi = {
    Total : 0,
    ModoArea : 1,
    ModoPoi : 2,
}

export const ModoAreasPoi = [
    { valor: ModoAreaPoi.Total, name: "Total" },
    { valor: ModoAreaPoi.ModoArea, name: "Modo Areas" },
    { valor: ModoAreaPoi.ModoPoi, name: "Modo Pois" }]; 

export const ModoVisualizaNotificar = {
    NotificarYVisualizar : 0,
    Notificar : 1,
    Visualizar : 2,
}

export const ModosVisualizaNotifica = [
    { valor: ModoVisualizaNotificar.NotificarYVisualizar, name: "Notificar Y Visualizar" },
    { valor: ModoVisualizaNotificar.Notificar, name: "Notificar" },
    { valor: ModoVisualizaNotificar.Visualizar, name: "Visualizar" }];


export const PopUpResult =
{
    Closed: '',
    OK: 'ok',
    KO: 'ko',
    Info: 'info'
};

export const ModoRuta = {
    Automatica: 0,
    Manual: 1   
}

export const ModosRuta = [
    { valor: ModoRuta.Automatica, name: "Automatica" },
    { valor: ModoRuta.Manual, name: "Manual"  }]; 


export const MetodoRuta = {
    Coche: 0,
    Bicicleta: 1,
    APie: 2
    //Transit, aqui se puede poner hora de salida, llegada https://developers.google.com/maps/documentation/directions/intro#TravelModes
}

export const MetodosRuta = [
    { valor: MetodoRuta.Coche, name: "Coche" },
    { valor: MetodoRuta.Bicicleta, name: "Bicicleta" },
    { valor: MetodoRuta.APie, name: "A Pie" }]; 

export const ModelosArea = [
    { valor: "0", name: "Clasico" },
    { valor: "1", name: "Lista Imagen" },
   ];

export const TiposInstalacionReserva = {
    Piscina: 0,
    Padel: 1,
    Tenis: 2
}

export const OpcionTiposInstalacionReserva = [
    { valor: TiposInstalacionReserva.Piscina, name: "Piscina" },
    { valor: TiposInstalacionReserva.Padel, name: "Padel" },
    { valor: TiposInstalacionReserva.Tenis, name: "Tenis" }];

export const MessageOthelloBoxButtons = {
    //
    // Resumen:
    //     The message box contains an OK button.
    OK: 0,
    //
    // Resumen:
    //     The message box contains OK and Cancel buttons.
    OKCancel: 1,
    //
    // Resumen:
    //     The message box contains Abort, Retry, and Ignore buttons.
    AbortRetryIgnore: 2,
    //
    // Resumen:
    //     The message box contains Yes, No, and Cancel buttons.
    YesNoCancel: 3,
    //
    // Resumen:
    //     The message box contains Yes and No buttons.
    YesNo: 4,
    //
    // Resumen:
    //     The message box contains Retry and Cancel buttons.
    RetryCancel: 5
}

export const DialogOthelloResult = {

    None: 0,
    OK: 1,
    Cancel: 2,
    Abort: 3,
    Retry: 4,
    Ignore: 5,
    Yes: 6,
    No: 7
}

export const EstadoReserva = {
    Confirmada : 0,
    Cancelada : 1
}

export const EstadosReserva = [
    { valor: EstadoReserva.Confirmada, name: "Confirmada" },
    { valor: EstadoReserva.Cancelada, name: "Cancelada" },
];

export const ResultadoReserva = {
    OK : 0,
    SinDisponibilidad : 1,
    ErrorExcepcion : 2,
    UsuarioMaxTramosDia : 3
}

export const  TiposEntrega = {
    SinDefinir : 0,
    EnLocal : 1,
    EnCasa : 2,
    EnLocalYCasa : 3,
    Comer : 4,
    EnLocalYComer : 5,
    EnCasaYComer : 6,
    EnLocalYCasaYComer : 7
}

export const TipoEntrega = [
    { valor: TiposEntrega.SinDefinir, name : "Sin Definir" },
    { valor: TiposEntrega.EnLocal, name : "En el Local" },
    { valor: TiposEntrega.EnCasa, name : "En Casa" },
    { valor: TiposEntrega.Comer, name : "Comer" },
    { valor: TiposEntrega.EnLocalYCasa, name : "En el Local y en Casa" },
    { valor: TiposEntrega.EnLocalYComer, name : "En el Local y Comer" },
    { valor: TiposEntrega.EnCasaYComer, name : "En Casa y Comer" },
    { valor: TiposEntrega.EnLocalYCasaYComer, name : "En el Local y en Casa y Comer" }

];


export const TipoCarrusel =
{    
    Simple: 1,
    Portada: 2, 
};

export const EstadoPedido = {
    Procesando: 0,
    Tramitado : 1,
    PendientePago : 2,
    EnEspera : 3,
    Enviado : 4,
    Completado : 5,
    Cancelado : 6,
    Reembolsado : 7,
    Fallido : 8
}


export const EstadoArticulo = {
    EnStock: 0,
    Agotado: 1
}


export const EstadosArticulo = [
    { valor: EstadoArticulo.EnStock, name: "En Stock" },
    { valor: EstadoArticulo.Agotado, name: "Agotado" }];


export const TiposItemUrl = {
    SinDefinir : -1,
    Segmentos : 1,
    Areas : 2,
    Promociones : 3
}

