import React from "react";
// reactstrap components
import {
    Button,
    Form,
    Input,
    Modal,
    Container,   
    Row,
    Col
} from "reactstrap";

import { useTranslation } from 'react-i18next';

function PeticionAltaEstablecimiento({...props })
{
    const { t } = useTranslation();

    //variables de las propiedas recibidas
    const { open, setModal} = props;
    
    
    function GuardarPeticion(event) {
        event.preventDefault();
        
        let formData = new FormData(event.target);

        fetch('api/Data/GuardarPeticionAltaEstablecimiento', {
            method: 'POST',
            body: formData,

        }).then(response => response.json()).then(res => {
            //Si hubo error
            if (!res)
                setSaveError(true);
            else {
                setModal(false);             
            }

        }).catch(error => {
            setSaveError(true);
        });
    }

    const [saveerror, setSaveError] = React.useState(false);                   

    return (
        <>           
            <Modal isOpen={open} toggle={() => setModal(false)}>    
                <div className="modal-header">
                    <span style={{ fontSize: 15, fontWeight: "bold" }}>
                        <b>{t("_peticionaltaestable")}</b>
                    </span>
                </div>
               <br/>
                    <Container>
                      
                    <Form className="formmidirecc" onSubmit={(e) => { e.preventDefault(); GuardarPeticion(e) }}>
                        <br/>
                        <Row>
                            <Col md="6">
                                <Input className="border-input" placeholder={t("_nombre") + " *"} type="text" id="nombre" name="nombre" />
                            </Col>
                            <Col md="6">
                                <Input className="border-input" placeholder={t("_apellidos") + " *"} type="text" id="apellido" name="apellido" />
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md="6">
                                <Input className="border-input" placeholder={t("_email") + " *"} type="text" id="eMail" name="eMail" />
                            </Col>
                            <Col md="6">
                                <Input className="border-input" placeholder={t("_telefono") + " *"} type="text" id="telefono" name="telefono" />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md="6">
                                <Input className="border-input" type="password" placeholder={t("_contrasena") + " *"} id="pass" name="pass" /> 
                            </Col>
                            <Col md="6">
                                <Input className="border-input" type="password" placeholder={t("_repetircontrasena") + " *"} id="repeatPass" name="repeatPass" />
                            </Col>
                        </Row>
                        <br />                   
                        <Row>
                            <Col md="12">
                                <Input className="border-input" placeholder={t("_establecimiento") + " *"} type="text" id="title" name="title" />                                
                            </Col>                            
                        </Row>
                        <br />
                        <Row>
                            <Col md="12">
                                <Input className="border-input" placeholder={t("_direccion") + " *"} type="text" id="footNote" name="footNote" />
                            </Col>                            
                        </Row>
                        <br />
                        <Row>
                            <Col md="6">
                                <Input className="border-input" placeholder={t("_latitud")} type="text" id="latStringAux" name="latStringAux" />
                            </Col>
                            <Col md="6">
                                <Input className="border-input" placeholder={t("_longitud")} type="text" id="lonStringAux" name="lonStringAux" />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md="12">
                                <Input
                                    id="descripcion" name='descripcion'
                                    className="textarea-limited"
                                    maxLength="250"
                                    placeholder={t("_descripbreve") + " *"}
                                    rows="3"
                                    type="textarea"
                                />
                            </Col>                          
                        </Row>
                        <br />
                        <Row>
                            <Col md="12">
                                <Input
                                    id="descripcion" name='descripcionLarga'
                                    className="textarea-limited"
                                    maxLength="1050"
                                    placeholder={t("_descriplarga") + " *"}
                                    rows="7"
                                    type="textarea"
                                />
                            </Col>                          
                        </Row>
                        <br />
                        {saveerror ? <center><span className='text-danger'><b>{t("_notdooperacion")}</b></span></center> : ""}
                        <br />
                        <Row>
                            <Col md="12">
                                <div className="pull-right">
                                    <Button className="btn-wd" color="danger" outline style={{ marginRight: 5 }} onClick={(e) => { e.preventDefault(); setModal(false) }}>{t("_cancelar")}</Button>
                                    <Button className="btn-wd" color="danger" type="submit" >{t("_guardar")}</Button>                                    
                                </div>
                            </Col>
                        </Row>
                    </Form>
                    <div outline style={{ marginRight: 5, height:25 }} />
                </Container>               
                
            </Modal>
        </>
    );
}

export default PeticionAltaEstablecimiento;