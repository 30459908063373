import React from "react";


// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";


import iconoandroid from "../../assets/img/google500.png"
import iconoios from "../../assets/img/apple500.png"

import OthelloVersionWeb from "../../componentsothello/OthelloVersionWeb.jsx";
import { getTextoFooterCorporativa, getSubTextoFooterCorporativa, getContactoFooterCorporativa } from "../../othelloFunciones";
import { TipoOficina } from "../../othelloConstantes";

import { useTranslation } from 'react-i18next';
import OthelloCookieConsent from "../../componentsothello/OthelloCookieConsent";

function FooterATM() {

    const { t } = useTranslation();

    const [oficina, setOficina] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        fetch('api/Data/GetOficinaVirtual')
            .then(response => response.json())
            .then(data => {
                setOficina(data);
                setLoading(false)
            }).catch(error => {
                setOficina(null); 
                setLoading(false)
            });

    }, [loading]);

  return (
    <>
      <footer className="footer footer-big footer-black">
        <Container>
          <Row>         
            <Col className="ml-auto mr-auto" md="12" >
              <Row>
                <Col lg="3" md="3" sm="6" xs="6">
                                  <p style={{ fontWeight: 'bold' }}>{!loading && oficina != null && oficina.tipoOfi != TipoOficina.Corporativa ? t("_conocenos") : getTextoFooterCorporativa(oficina, t)}</p>
                                  <div className="links">                                           
                                      <p>{!loading && oficina != null && oficina.tipoOfi != TipoOficina.Corporativa ? t("_conocenosinfo") : getSubTextoFooterCorporativa(oficina, t)}</p>
                  </div>
                </Col>
                <Col lg="3" md="4" sm="6" xs="6">
                    <div className="links">
                                      {!loading && oficina != null && (oficina.googleplay != null && oficina.googleplay != "" || oficina.applestore != null && oficina.applestore) ? <p style={{ fontWeight: 'bold' }}>{t("_descargapp")}</p> : "" }
                        {!loading && oficina != null && oficina.googleplay != null && oficina.googleplay != "" ? <a target="_blank" href={oficina.googleplay}> <img src={iconoandroid} width="200" height="65" alt="Logo" style={{ marginTop: 10 }} /> </a> : ""}
                        {!loading && oficina != null && oficina.applestore != null && oficina.applestore != "" ? <a target="_blank" href={oficina.applestore}><img src={iconoios} width="200" height="65" alt="Logo" style={{ marginTop: 10 }} /></a> : ""}                                                                      
                    </div>
                </Col>
                <Col lg="3" md="3" sm="6" xs="6">
                    <div className="links">
                        <p style={{ fontWeight: 'bold' }}>{t("_ayuda")}</p>
                        <ul className="stacked-links">                           
                            <li>
                                              <a href="/aviso-legal" ><b>{t("_avisolgeal")}</b></a>
                            </li>
                            <li>
                                              <a href="/privacidad"><b>{t("_privacidad")}</b></a>
                            </li>
                            <li>
                                              <a href="/contacto"><b>{t("_contacto")}</b></a>
                            </li>                            
                        </ul>
                    </div>
                </Col>
                <Col lg="3" md="2" sm="6" xs="6">
                    <div className="social-area">
                        {!loading && oficina != null && oficina.linkFacebook != null && oficina.linkFacebook != "" ? <Button href={oficina.linkFacebook} target="_blank" className="btn-just-icon btn-round mr-1" color="facebook" ><i className="fa fa-facebook" /></Button> : ""}
                        {!loading && oficina != null && oficina.linkTwitter != null && oficina.linkTwitter != "" ? <Button href={oficina.linkTwitter} target="_blank" className="btn-just-icon btn-round mr-1" color="twitter" ><i className="fa fa-twitter" /></Button> : ""}
                        {!loading && oficina != null && oficina.linkInstagram != null && oficina.linkInstagram != "" ? <Button href={oficina.linkInstagram} target="_blank" className="btn-just-icon btn-round mr-1" color="reddit" ><i className="fa fa-instagram" /></Button> : ""}                   
                        {!loading && oficina != null && oficina.linkYoutube != null && oficina.linkYoutube != "" ? <Button href={oficina.linkYoutube} target="_blank" className="btn-just-icon btn-round mr-1" color="reddit" ><i className="fa fa-youtube-play" /></Button> : ""} 
                        <br />
                        {!loading && oficina != null && oficina.tipoOfi != TipoOficina.Corporativa ? "" : getContactoFooterCorporativa(oficina, t)}
                  </div>
                </Col>
              </Row>
              <hr />
              <div className="copyright">
                <div className="pull-left">
                                  <b> © {new Date().getFullYear()}  Aplicaciones Turísticas en Movilidad S.L. </b>     
                                  
                </div>  
                <div className="pull-right">         
                    <OthelloVersionWeb /> 
                </div>
              </div>
            </Col>
                  </Row>
              </Container>
              {!loading && oficina ? <OthelloCookieConsent oficina={oficina} /> : ""}
      </footer>
    </>
  );
}

export default FooterATM;
