import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function AboutUsHeader({ ...props }) {
  return (
    <>
      <div
        className="page-header page-header-small"
        style={{
          backgroundImage:
                "url(" + require("../../assets/img/sections/gerrit-vermeulen.jpg") + ")",
        }}
      >
        <div className="filter filter-danger" />
        <div className="content-center">
                  <Container>
                      <h1>{props.texto != null ? props.texto : ""}</h1>            
          </Container>
        </div>
      </div>
    </>
  );
}

export default AboutUsHeader;


//<h3>Let us tell you more about what we do.</h3>