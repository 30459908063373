import React from "react";

// reactstrap components
import {
    Button,
    Container,
    Row,
    Col,
    Badge
} from "reactstrap";


import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} from "react-google-maps";

import { TipoOficina, SiNo, EstadoPedido } from "./othelloConstantes.jsx";

// core components
export function GetGoogleMaps(latitud, longitud)
{
    const MapWrapper = withScriptjs(
        withGoogleMap((props) => (
            <GoogleMap
                defaultZoom={17}
                defaultCenter={{ lat: latitud, lng: longitud }}
                defaultOptions={{
                    styles: [
                        {
                            featureType: "water",
                            elementType: "geometry",
                            stylers: [
                                {
                                    color: "#e9e9e9",
                                },
                                {
                                    lightness: 17,
                                },
                            ],
                        },
                        {
                            featureType: "landscape",
                            elementType: "geometry",
                            stylers: [
                                {
                                    color: "#f5f5f5",
                                },
                                {
                                    lightness: 20,
                                },
                            ],
                        },
                        {
                            featureType: "road.highway",
                            elementType: "geometry.fill",
                            stylers: [
                                {
                                    color: "#ffffff",
                                },
                                {
                                    lightness: 17,
                                },
                            ],
                        },
                        {
                            featureType: "road.highway",
                            elementType: "geometry.stroke",
                            stylers: [
                                {
                                    color: "#ffffff",
                                },
                                {
                                    lightness: 29,
                                },
                                {
                                    weight: 0.2,
                                },
                            ],
                        },
                        {
                            featureType: "road.arterial",
                            elementType: "geometry",
                            stylers: [
                                {
                                    color: "#ffffff",
                                },
                                {
                                    lightness: 18,
                                },
                            ],
                        },
                        {
                            featureType: "road.local",
                            elementType: "geometry",
                            stylers: [
                                {
                                    color: "#ffffff",
                                },
                                {
                                    lightness: 16,
                                },
                            ],
                        },
                        {
                            featureType: "poi",
                            elementType: "geometry",
                            stylers: [
                                {
                                    color: "#f5f5f5",
                                },
                                {
                                    lightness: 21,
                                },
                            ],
                        },
                        {
                            featureType: "poi.park",
                            elementType: "geometry",
                            stylers: [
                                {
                                    color: "#dedede",
                                },
                                {
                                    lightness: 21,
                                },
                            ],
                        },
                        {
                            elementType: "labels.text.stroke",
                            stylers: [
                                {
                                    visibility: "on",
                                },
                                {
                                    color: "#ffffff",
                                },
                                {
                                    lightness: 16,
                                },
                            ],
                        },
                        {
                            elementType: "labels.text.fill",
                            stylers: [
                                {
                                    saturation: 36,
                                },
                                {
                                    color: "#333333",
                                },
                                {
                                    lightness: 40,
                                },
                            ],
                        },
                        {
                            elementType: "labels.icon",
                            stylers: [
                                {
                                    visibility: "off",
                                },
                            ],
                        },
                        {
                            featureType: "transit",
                            elementType: "geometry",
                            stylers: [
                                {
                                    color: "#f2f2f2",
                                },
                                {
                                    lightness: 19,
                                },
                            ],
                        },
                        {
                            featureType: "administrative",
                            elementType: "geometry.fill",
                            stylers: [
                                {
                                    color: "#fefefe",
                                },
                                {
                                    lightness: 20,
                                },
                            ],
                        },
                        {
                            featureType: "administrative",
                            elementType: "geometry.stroke",
                            stylers: [
                                {
                                    color: "#fefefe",
                                },
                                {
                                    lightness: 17,
                                },
                                {
                                    weight: 1.2,
                                },
                            ],
                        },
                    ],
                    scrollwheel: false, //we disable de scroll over the map, it is a really annoing when you scroll through page
                }}
            >
                <Marker position={{ lat: latitud, lng: longitud }} />
            </GoogleMap>
        )));

    return <MapWrapper
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDTyawbO2Bp07flFyiB_34AMAE8Xw0AU00"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
    />
}

export function GetBotonAccion(tipoOfi, actividad, texto, uri)
{
    let icono = "";
    let urifinal = uri;

    //web
    if (actividad == 1)
        icono = <i class="fa fa-chrome" aria-hidden="true" />;
    //audio
    else if (actividad == 2)
        icono = <i class="fa fa-play" aria-hidden="true" />;
    //llamar
    else if (actividad == 4)
        icono = <i class="fa fa-phone" aria-hidden="true" />;
    //correo
    else if (actividad == 5) {

        if (!uri.includes("mailto:"))
            urifinal = "mailto:" + uri;
        
        icono = <i class="fa fa-envelope-o" aria-hidden="true" />;
    }
    //pdf
    else if (actividad == 6)
        icono = <i class="fa fa-file-pdf-o" aria-hidden="true" />;
        //mp3
    else if (actividad == 7)
        icono = <i class="fa fa-music" aria-hidden="true" />;
        //Video
    else if (actividad == 8)
        icono = <i class="fa fa-video-camera" aria-hidden="true" />;
        //Facebook
    else if (actividad == 9)
        icono = <i class="fa fa-facebook" aria-hidden="true"/>;
        //twitter
    else if (actividad == 10)
        icono = <i class="fa fa-twitter" aria-hidden="true" />;
        //instagram
    else if (actividad == 11)
        icono = <i class="fa fa-instagram" aria-hidden="true" />;
        //tryadvisor
    else if (actividad == 12)
        icono = <i class="fa fa-tripadvisor" aria-hidden="true"/>;
        //youtube
    else if (actividad == 13)
        icono = <i class="fa fa-youtube-play" aria-hidden="true" />;
        //pinterest
    else if (actividad == 14)
        icono = <i class="fa fa-pinterest-p" aria-hidden="true" />;
        //GooglePlus
    else if (actividad == 15)
        icono = <i class="fa fa-google-plus" aria-hidden="true" />;
        //Reservar
    else if (actividad == 16)
        icono = <i class="fa fa-book" aria-hidden="true"/>;
        //Wikiloc
    else if (actividad == 17)
        icono = <i class="fa fa-wikipedia-w" aria-hidden="true" />;
        //TRack
    else if (actividad == 18)
        icono = <i class="fa fa-chevron-circle-up" aria-hidden="true"/>;
        //whatsapp
    else if (actividad == 19)
        icono = <i class="fa fa-whatsapp" aria-hidden="true" />;
        //maestro detalla
    else if (actividad == 38)
        icono = <i class="fa fa-info" aria-hidden="true" />;
        //ir a
    else if (actividad == 39)
        icono = <i class="fa fa-globe-europe" />;

    //Si estamos con una audio guia pero sin mp4
    if (actividad == 2 && (uri == null || (uri != null && uri == "")))
        return "";
    else {
        return <Button style={{ margin: 5 }}
            className="btn-round"
            color={actividad == 6 ? "danger" : "default"}
            href={urifinal}
            target='_blank'>
            {icono} {texto}
        </Button>
    }
}

export function GetCarouselItemsPortada(ModeloCarruselList, literal)
{
    let items = []
    ModeloCarruselList.map(x => {

        let urlfinal = x.src;

        if (window.innerWidth <= 768)
            urlfinal = x.src_sm;
        else if (window.innerWidth <= 992)
            urlfinal = x.src_md;
        else if (window.innerWidth <= 1200)
            urlfinal = x.src_lg;
        else
            urlfinal = x.src;
          
        items.push(
            {
                //src: "url(" + require("./assets/img/sections/section-header-1.jpg") + ")",
                src: "url(" + (urlfinal) + ")",
                content: (
                    //Si es de tipo nomral (coorporativa, app normales)
                    x.tipoofi != TipoOficina.Venta ? (contenidoPortadaTipoNormal(x, literal)) :
                        //Sino, es que es de venta
                        (contenidoPortadaTipoVenta(x, literal))
                ),
                altText: "",
                caption: "",
                idofi: x.idofi
            }
        )
    });

    return items;

}

function contenidoPortadaTipoNormal(x, literal) {

    let textoliteral = "_bienvenidosa";

    if (x.idofi == 377)
        textoliteral = "_bienvenidoslesvalls";
    else if (x.idofi == 104)
        textoliteral = "_authabitattusapartamentos";
    else if (x.idofi == 53)
        textoliteral = "_elmolinoyayos";
    else if (x.idofi == 30)
        textoliteral = "";//"_santonateespera";
 


    if (x.idofi == 104 || x.idofi == 53) {
        return <Container>
            <Row>
                <Col className="ml-auto mr-auto text-center" md="10">
                    <h1 className="title"><b>{x.nombre}</b></h1>
                    <h5><b>{literal != null ? literal(textoliteral) : ""}</b></h5>
                    {/*<br />
                    <Button                        
                        color="info"
                        href="https://www.booking.com/hotel/es/apartamentos-auhabitatzaragoza.es.html"                       
                        size="lg"
                        target="_blank">                         
                        <i class="fa fa-suitcase" aria-hidden="true"></i> {literal != null ? literal("_reservar") : "Reservar"}
                        </Button>*/}
                </Col>
            </Row>
        </Container>
    }
    else if (x.idofi == 30)
    {
        return <Container>
            <Row>
                <Col className="ml-auto mr-auto text-center" md="10">
                    <h1 className="title"><b>{literal != null ? literal(textoliteral) : ""}</b></h1>
                    <h5>
                    </h5>
                    <br />
                </Col>
            </Row>
        </Container>
    } 
    else {
        return <Container>
            <Row>
                <Col className="ml-auto mr-auto text-center" md="10">
                    <h1 className="title"><b>{literal != null ? literal(textoliteral) : ""} {x.nombre}</b></h1>
                    <h5>
                    </h5>
                    <br />
                </Col>
            </Row>
        </Container>
    }
}

function contenidoPortadaTipoVenta(x, literal) {
    //Si es mojacar o vivecohaique
    if (x.idofi == 114 || x.idofi == 384) {
        return <Container>
            <Row>
                <Col className="text-left" md="8">
                    <h1 className="title" style={{ fontWeight: 'bold' }}>{literal("_compraonline")}</h1>
                    <h1><color style={{ fontWeight: 'bold', color: '#fcdc31' }}>{literal("_peroencomercios")}</color></h1>
                    <h1 style={{ fontWeight: 'bold' }}>{literal("_ytiendasciudad")}</h1>
                    {
                        //<br />
                        //<div className="buttons" style={{ marginTop: 20 }}>
                        //    <Button
                        //        className="btn-round"
                        //        color="danger"
                        //        href="/area"
                        //        onClick={(e) => e.preventDefault()}
                        //        size="lg"
                        //    >
                        //        Ver Restaurantes
                        //    </Button>
                        //</div>
                    }
                </Col>
            </Row>
        </Container>
    }
    //Si es muxamel
    else if (x.idofi == 376) {
        return <Container>
            <Row>
                <Col className="text-left" md="8">
                    <h1 className="title" style={{ fontWeight: 'bold', color: '#1e42e1', fontSize: 42 }}>{literal("_compraonline")} </h1>
                    <h1><color style={{ fontWeight: 'bold', color: '#fef103', fontSize: 42 }}>{literal("_peroencomercios")} </color></h1>
                    <h1 style={{ fontWeight: 'bold', color: '#ff2600', fontSize: 42 }}>{literal("_detuciudad")}</h1>
                </Col>
            </Row>
        </Container>
    }
    else if (x.idofi == 13) {
        return "";
    }
    else {
        return <Container>
            <Row>
                <Col className="text-left" md="8">
                    <h1 className="title" style={{ fontWeight: 'bold' }}>Hambriento?</h1>
                    <h1><color style={{ fontWeight: 'bold', color: '#fcdc31' }}>La Mejor Comida</color></h1>
                    <h1 style={{ fontWeight: 'bold' }}>a Domicilio en un clic</h1>
                    <br />
                    <div className="buttons" style={{ marginTop: 20 }}>
                        <Button
                            className="btn-round"
                            color="danger"
                            href="/area"
                            onClick={(e) => e.preventDefault()}
                            size="lg"
                        >
                            Ver Restaurantes
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    }

}


export function getTextoPortadaPuntosCorporativa(x, literal) {

    let textoliteral = "";

    if (x.id_Oficina == 104)
        textoliteral = "_nuestroapartamentos";
    else if (x.id_Oficina == 53)
        textoliteral = "_nuestracasa";

    if (textoliteral != "")
        return literal != null ? literal(textoliteral) : ""  
    else 
        return x.nombreOficina
    
}

export function getSubTextoPortadaPuntosCorporativa(x, literal) {

    let textoliteral = "";

    if (x.id_Oficina == 104)
        textoliteral = "_nuestroapartamentossubtitulo";
    else if (x.id_Oficina == 53)
        textoliteral = "_nuestracasasuttitulo";

    if (textoliteral != "")
        return literal != null ? literal(textoliteral) : ""
    else
        return x.nombreOficina

}


export function getTextoFotoPortadaPuntosCorporativa(x, literal) {

    let textoliteral = "";

    if (x.id_Oficina == 104)
        textoliteral = "_infofotoaparta";
    else if (x.id_Oficina == 53)
        textoliteral = "_infofotomolino";

    if (textoliteral != "")
        return literal != null ? literal(textoliteral) : ""
    else
        return "";

}

export function getSubTextoFotoPortadaPuntosCorporativa(x, literal) {

    let textoliteral = "";

    if (x.id_Oficina == 104)
        textoliteral = "_subinfofotoaparta";
    else if (x.id_Oficina == 53)
        textoliteral = "_subinfofotomolino";

    if (textoliteral != "")
        return literal != null ? literal(textoliteral) : ""
    else
        return "";

}


export function getTextoMapaCorporativa(x, literal) {

    if (x != null) {
        let textoliteral = "";

        if (x.id_Oficina == 104)
            textoliteral = "_centrozaragoza";
        else if (x.id_Oficina == 53)
            textoliteral = "_disrfutaubicacion";

        if (textoliteral != "")
            return literal != null ? literal(textoliteral) : ""
        else
            return ""
    }
    else
        return ""
}

export function getSubTextoMapaCorporativa(x, literal) {

    if (x != null) {
        let textoliteral = "";

        if (x.id_Oficina == 104)
            textoliteral = "_idelauthabitat";
        else if (x.id_Oficina == 53)
            textoliteral = "_idealmolino";

        if (textoliteral != "")
            return literal != null ? literal(textoliteral) : ""
        else
            return ""
    }
    else
        return ""
}

export function GetInfoAdicionalHeaderCorporativa(x, literal) {

    if (x != null) {
        if (x.idofi == 104) {
           return <div className="header-3" style={{ backgroundColor: '#eeeeee', height: 100 }}>
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" md="6">
                            <h4 className="description" style={{ marginTop: 35 }}>
                                <b>{literal != null ? literal("_reservafacil") : ""}</b>
                            </h4>
                        </Col>
                        <Col className="ml-auto mr-auto" md="3" style={{ marginTop: 25 }}>
                            <Button
                                color="info"
                                href={"/contacto"}
                                size="lg">
                                <i class="fa fa-suitcase" aria-hidden="true"></i> {"Reservar"}
                            </Button>
                        </Col>
                        <Col className="ml-auto mr-auto" md="3">
                            <h4><b>+34 976437907</b></h4>
                            <center><span className='text-info'><b> <b>contacto@auhabitatzaragoza.com</b></b></span></center>
                        </Col>
                    </Row>
                </Container>
            </div>
        }
        else if (x.idofi == 53) {
            return <div className="header-3" style={{ backgroundColor: '#eeeeee', height: 100 }}>
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" xs="6" sm="7" md="8" lg="9">
                            <h4 className="description" style={{ marginTop: 32 }}>
                                <b>{literal != null ? literal("_reservafacil") : ""}</b>
                            </h4>
                        </Col>                       
                        <Col className="ml-auto mr-auto" xs="6" sm="5" md="4" lg="3">
                            <h4><b>+34 667580893</b></h4>
                            <center><span className='text-info'><b> <b>info@elmolinodelosyayos.com </b></b></span></center>
                        </Col>
                    </Row>
                </Container>
            </div>
        }
        else
            return "";
    }
    else
        return "";       
}

export function getTextoFooterCorporativa(x, literal) {

    if (x != null) {
        let textoliteral = "";

        if (x.id_Oficina == 104)
            textoliteral = "_losapartamentos";
        else if (x.id_Oficina == 53)
            textoliteral = "_elmolino";

        if (textoliteral != "")
            return literal != null ? literal(textoliteral) : ""
        else
            return ""
    }
    else
        return ""
}

export function getSubTextoFooterCorporativa(x, literal) {

    if (x != null) {
        let textoliteral = "";

        if (x.id_Oficina == 104)
            textoliteral = "_losapartamentosinfo";
        else if (x.id_Oficina == 53)
            textoliteral = "_elmolinoinfo";

        if (textoliteral != "")
            return literal != null ? literal(textoliteral) : ""
        else
            return ""
    }
    else
        return ""
}

export function getContactoFooterCorporativa(x, literal) {

    if (x != null)
    {
        if (x.id_Oficina == 104)
            return <><br /><p style={{ fontWeight: 'bold' }}>Contacto</p><p>+34 976437907<br />contacto@auhabitatzaragoza.com<br />C/Ramón Pignatelli, 11 Zaragoza, Aragón<br />50004 España </p></>
        else if (x.id_Oficina == 53)
            return <><br /><p style={{ fontWeight: 'bold' }}>Contacto</p><p>+34 667580893<br />info@elmolinodelosyayos.com <br /></p></>
        else
            return "";
    }
    else
        return ""
}

export function GetBadgeOpcionesProducto(verstock, enpromocion, enviogratis, relativo, cantidad)
{

    let valor = relativo != null && !relativo ? { margin: 3 } : { position: "absolute", margin: 3 }

    return <div style={valor}>
        {enpromocion == SiNo.Si ? <Badge color="warning" style={{ marginRight: 2 }}>Promocion</Badge> : ""}
        {enviogratis == SiNo.Si ? <Badge color="success" style={{ marginRight: 2 }}>Envio Gratis</Badge> : ""}
        {verstock == SiNo.Si ? <Badge color="danger">Quedan {cantidad}</Badge> : ""}
        </div >  
}

export function GetEstadoString(estado) 
{

    if (estado == EstadoPedido.Procesando)
        return "Procesando";
    else if (estado == EstadoPedido.Tramitado)
        return "Tramitado";
    else if (estado == EstadoPedido.PendientePago)
        return "Pendiente de Pago";
    else if (estado == EstadoPedido.EnEspera)
        return "En Espera";
    else if (estado == EstadoPedido.Enviado)
        return "Enviado";
    else if (estado == EstadoPedido.Completado)
        return "Completado";
    else if (estado == EstadoPedido.Cancelado)
        return "Cancelado";
    else if (estado == EstadoPedido.Reembolsado)
        return "Reembolsado";
    else if (estado == EstadoPedido.Fallido)
        return "Fallido";
    else
        return "";

}

export function GetEstadoPedidoVisual(estado) {

    let state = "";

    if (estado == EstadoPedido.Procesando)
        state = "Procesando";
    else if (estado == EstadoPedido.Tramitado)
        return "Tramitado";
    else if (estado == EstadoPedido.PendientePago)
        state = "Pendiente de Pago";
    else if (estado == EstadoPedido.EnEspera)
        state = "En Espera";
    else if (estado == EstadoPedido.Enviado)
        state = "Enviado";
    else if (estado == EstadoPedido.Completado)
        state = "Completado";
    else if (estado == EstadoPedido.Cancelado)
        state = "Cancelado";
    else if (estado == EstadoPedido.Reembolsado)
        state = "Reembolsado";
    else if (estado == EstadoPedido.Fallido)
        state = "Fallido";
    else
        return "";

    if (estado == EstadoPedido.Cancelado || estado == EstadoPedido.Reembolsado || estado == EstadoPedido.Fallido)
        return <color className="text-danger">{state}</color>
    else
        return <color className="text-success">{state}</color>    
}


export function NombreContieneTextoBusqueda(nombre, textoBusqueda)
{
    let valor = nombre;
    try {
        valor = nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }
    catch{ }

    let filterfinal = textoBusqueda;

    try {
        filterfinal = textoBusqueda.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }
    catch{ }

    return (textoBusqueda == null || textoBusqueda == "" || (String(valor).toLowerCase().includes(filterfinal.toLowerCase()))) 
}

//export function GetItemCarrusel() {

//   // core components
//// carousel items
//const carouselItems = [
//    {
//        src: require("./assets/img/rihanna.jpg"),
//        altText: "Somewhere",
//        caption: "",
//    },
//    {
//        src: require("./assets/img/jacket-2.jpg"),
//        altText: "Somewhere else",
//        caption: "",
//    },
//    {
//        src: require("./assets/img/jacket-3.jpg"),
//        altText: "Here it is",
//        caption: "",
//    },
//    {
//        src: require("./assets/img/jacket-4.jpg"),
//        altText: "Here it is",
//        caption: "",
//    },
//    ];

//    return carouselItems;
//}


//// core components
//const items = [
//    {
//        src: "url(" + require("../../assets/img/sections/section-header-1.jpg") + ")",
//        content: (
//            <Container>
//                <Row>
//                    <Col className="ml-auto mr-auto text-center" md="10">
//                        <h1 className="title"><b>Bienvenidos a Becerril de la Sierra</b></h1>
//                        <h5>
//                        </h5>
//                        <br />
//                    </Col>
//                </Row>
//            </Container>
//        ),
//        altText: "",
//        caption: "",
//    },
//    {
//        src: "url(" + require("../../assets/img/sections/section-header-2.jpg") + ")",
//        content: (
//            <Container>
//                <Row>
//                    <Col className="ml-auto mr-auto text-center" md="10">
//                        <h1 className="title"><b>Bienvenidos a Becerril de la sierra</b></h1>
//                        <h5>
//                        </h5>
//                        <br />
//                    </Col>
//                </Row>
//            </Container>
//        ),
//        altText: "",
//        caption: "",
//    },
//    {
//        src: "url(" + require("../../assets/img/sections/section-header-3.jpg") + ")",
//        content: (
//            <Container>
//                <Row>
//                    <Col className="ml-auto mr-auto text-center" md="10">
//                        <h1 className="title"><b>Bienvenidos a Becerril de la sierra</b></h1>
//                        <h5>
//                        </h5>
//                        <br />
//                    </Col>
//                </Row>
//            </Container>
//        ),
//        altText: "",
//        caption: "",
//    },
//];

