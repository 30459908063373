import React from "react";

// reactstrap components
import {
    Button,
    Container, Row, Col,    
 } from "reactstrap";

import { TipoOficina } from "../othelloConstantes.jsx";

// core components
import ColorNavbarATMSimple from "../components/Navbars/ColorNavbarATMSimple.js";
import FooterATMVenta from "../components/Footers/FooterATMVenta.js";
import FooterATM from "../components/Footers/FooterATM.js";

import { useTranslation } from 'react-i18next';

function WebIFrame()
{
    const { t, i18n } = useTranslation();

    document.documentElement.classList.remove("nav-open");

    React.useEffect(() => {
        document.body.classList.add("index-page");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
        };
    });

    const [loadingIdioma, setLoadingIdioma] = React.useState(true);

    React.useEffect(() => {

        fetch('api/Data/GetIdiomaActual')
            .then(response => response.json())
            .then(data => {
                i18n.changeLanguage(data.idioma);
                setLoadingIdioma(false);
            }).catch(error => {
                i18n.changeLanguage('es');
                setLoadingIdioma(false);
            });
    }, [loadingIdioma]);

    const [item, setItem] = React.useState(null);    
    const [loading, setLoading] = React.useState(true);    

    React.useEffect(() => {
        fetch('api/Data/GetWebIFrame')
            .then(response => response.json())
            .then(data => {
                setItem(data);                
                setLoading(false);
            }).catch(error => {
                setItem(null);                
                setLoading(false);
            });

    }, [loading]);


  return (
      <>
          <ColorNavbarATMSimple color='danger' />
          <div className="projects-2 section section-dark" style={{ marginTop: 70 }}>
              <Container>
                  <Row>
                      <Col className="ml-auto mr-auto text-center" md="8">
                          <div className="space-top" />
                          <h2 className="title"><b>{!loading && item != null ? item.nombre : "-"}</b></h2>
                          <hr />
                          <h5 className="description">
                              <b>{!loading && item != null ? item.descripcion : "-"}</b>
                          </h5>                          
                      </Col>
                  </Row>
              </Container>
          </div>
          {/*<div className="section">
             <Container>                
                  <div>
                      <iframe title={!loading && item != null ? item.nombre : "-"} width="100%" height="800" src={!loading && item != null ? item.linkexterno : "-"}></iframe>
                  </div>
              </Container>
          </div>*/}
          <div>
              <iframe title={!loading && item != null ? item.nombre : "-"} width="100%" height="1080" src={!loading && item != null ? item.linkexterno : "-"}></iframe>
          </div>
          {!loading && item != null && item.tipoofi == TipoOficina.Venta ? <FooterATMVenta /> : <FooterATM />}
    </>
  );
}

export default WebIFrame;