import React from "react";
// reactstrap components
import {
    Button,   
    Modal,
    Container,   
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardFooter
} from "reactstrap";

import { useTranslation } from 'react-i18next';

function PromocionModal({...props })
{
    const { t } = useTranslation();

    //variables de las propiedas recibidas
    const { open, setModal, id, loadingpage} = props;

    const [promo, setPromocion] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {

        var jsonData = JSON.stringify(id);

        fetch('api/Data/GetPromocion', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: jsonData

        }).then(response => response.json()).then(data => {
            setPromocion(data);
            setLoading(false)
        }).catch(error => {
            setPromocion(null);
            setLoading(false)
        });
    }, [loading]);

    let imagen = require("../assets/img/image_placeholder.jpg");

    if (!loading && promo != null) {
        if (promo.imageUrlPromo != null && promo.imageUrlPromo != "")
            imagen = promo.imageUrlPromo;
        else if (promo.urlImgPoi != null && promo.urlImgPoi != "")
            imagen = promo.urlImgPoi;
    }

    return (
        <>           
            <Modal isOpen={open} toggle={() => setModal(false)}>    
                <div className="modal-header">
                    <span style={{ fontSize: 15, fontWeight: "bold" }}>
                        <b>{t("_promocion")} {!loading && promo != null ? promo.nombrePoi : ""}</b>
                    </span>
                </div>
               <br/>
                <Container>                    
                    <Row>
                        <Col>
                            <center>
                                <h3><b>{!loading && promo != null ? promo.promo : ""}</b></h3>
                            </center>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <center>
                            <div className="card-big-shadow">
                        <Card
                            className="card-just-text"
                            data-background="color"
                                    data-color="orange"
                            data-radius="none">
                            <CardBody>
                                            <h6 className="card-category">{t("_codpromo")}</h6>
                                            <hr/>
                                <CardTitle tag="h4">
                                        <b>{!loading && promo != null ? promo.codigoPromo : ""}</b>
                                            </CardTitle>
                                            <hr />
                                <p className="card-description">
                                                {t("_showpromoestable")}
                                 </p>
                            </CardBody>
                                </Card>
                        {/* end card */}
                                </div>
                            </center>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="pull-right">

                                <b>{t("_ofertavalidahasta")} {!loading && promo != null ? (new Date(promo.fechValido)).toLocaleDateString() : ""}</b>
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <Row style={{ height: 35, marginLeft:15 }}>
                        <Col className="description-section" md="12">
                            <span style={{ fontSize: 15, fontWeight: "bold" }}>
                                <b>{t("_infopromo")}</b>
                            </span>
                        </Col>
                    </Row>
                    <Row style={{marginLeft: 15 }}>
                        <Col>
                            <b>{!loading && promo != null ? promo.infoAdd : ""}</b>
                        </Col>                        
                    </Row>  
                    <br />
                    <Row>
                        <Col md="12">
                            <center>
                            {!loading && promo != null && promo.urlExterna != null && promo.urlExterna != "" ?
                                <Button  block color="success" href={promo.urlExterna} style={{ width: 250, marginTop: 5 }} target="_blank">
                                {t("_masinfo")}
                                    </Button> : ""}
                            </center>
                        </Col>
                    </Row>
                        <br />
                        <Row>
                            <Col md="12">
                            <div className="pull-right">
                                <Button className="btn-wd" color="danger" outline style={{ marginRight: 5 }} onClick={(e) => { e.preventDefault(); setModal(false) }}>{t("_cerrar")}</Button>                                  
                                </div>
                            </Col>
                        </Row>                    
                    <div outline style={{ marginRight: 5, height:25 }} />
                </Container>               
                
            </Modal>
        </>
    );
}

export default PromocionModal;