import React from "react";
import Select from "react-select";
import DatePicker from "reactstrap-date-picker";

// reactstrap components
import {
    Button,
    Container, Row, Col,
    Card,
    CardBody,   
    NavItem, NavLink, Nav,
    TabContent,
    TabPane,
    Badge,   
    FormGroup,
    Input,    
    Progress
} from "reactstrap";


// core components
import ColorNavbarATMSimple from "../components/Navbars/ColorNavbarATMSimple.js";
import { SiNo } from "../othelloConstantes.jsx";

import DireccionModal from "./DireccionModal.js";
import DireccionSeleccionModal from "./DireccionSeleccionModal.js";

import { useTranslation } from 'react-i18next';

function Cesta()
{
    const { t, i18n } = useTranslation();

    document.documentElement.classList.remove("nav-open");

    React.useEffect(() => {
        document.body.classList.add("index-page");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
        };
    });

    const [loadingIdioma, setLoadingIdioma] = React.useState(true);

    React.useEffect(() => {

        fetch('api/Data/GetIdiomaActual')
            .then(response => response.json())
            .then(data => {
                i18n.changeLanguage(data.idioma);
                setLoadingIdioma(false);
            }).catch(error => {
                i18n.changeLanguage('es');
                setLoadingIdioma(false);
            });
    }, [loadingIdioma]);

    const [activePill, setActivePill] = React.useState("-1");

    const [carrito, setCarrito] = React.useState(null);
    const [loading, setLoading] = React.useState(true);    

    const [direccionModal, setDireccionModal] = React.useState(-1);
    const [selecciondireccionModal, setSeleccionarDireccionModal] = React.useState(false);
    const [direccionSelected, setDireccionSelected] = React.useState(-1);

    const [errorTramitarPedido, setErrortramitarPedido] = React.useState(null);

    const [TramitandoPedido, setTramitandoPedido] = React.useState(false);


    React.useEffect(() =>
    {        
        var jsonData = JSON.stringify(activePill);

        fetch('api/Data/GetCarrito', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: jsonData

        }).then(response => response.json()).then(res => {
            setCarrito(res);
            setLoading(false)

            if (activePill == "-1")
            {
                if (res.envioenabled)
                    setActivePill("1");
                else if (res.recogidaenabled)
                    setActivePill("2");
                else if (res.comerenabled)
                    setActivePill("3");                    
            }

            if (dia.dia == "" && dia.diaft == "" && dia.hora == "")
                setDiaAccion({ dia: res.today, diaft: res.todayft, hora: "" })

            if (direccionSelected <= 0 && res != null && res.iddirecciondefault>0)
                setDireccionSelected(res.iddirecciondefault);

        }).catch(error => {
            setCarrito(null);
            setLoading(false);
            setDireccionSelected(-1);
        });

    }, [loading]);
  
    function CantidadArticulo(id, opc)
    {
        var jsonData = JSON.stringify({ idlinea: id, opera: opc });

        fetch('api/Data/CantidadArticulo', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: jsonData

        }).then(response => response.json()).then(res =>
        {
            setLoading(res>0);
            setErrortramitarPedido(null);
        }).catch(error => {

        });
    }

    function DeleteArticulo(id)
    {
        var jsonData = JSON.stringify(id);

        fetch('api/Data/DeleteArticulo', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: jsonData

        }).then(response => response.json()).then(res => {
            setLoading(res);
            setErrortramitarPedido(null);
        }).catch(error => {

        });
    }
    
    const pedidos = !loading && carrito != null && carrito.lineas != null && carrito.lineas.length > 0 ?
        carrito.lineas.map(x =>
        {
            let imagen = x.imagen != null && x.imagen != "" ? x.imagen : require("../assets/img/image_placeholder.jpg");

            let errorlinea = ""

            if (x.cantidad == 0)
                errorlinea = t("_sinexistencia");
            else if (errorTramitarPedido != null && !errorTramitarPedido.ok && errorTramitarPedido.lineas != null && errorTramitarPedido.lineas.length > 0)
            {
                let errorline = errorTramitarPedido.lineas.find(y => y.id_linea == x.idlinea);

                if (errorline != null)
                {
                    //Muestro el error
                    if (errorline.error != null && errorline.error != "")
                        errorlinea = errorline.error;

                    //ErrorLineaPedido.CantidadExcesivaSegunDispo
                    if (errorline.iderror == 3)
                        x.cantidad = errorline.nuevacantidad;
                }
            }
            
            return <div>
                    <li>
                    <Row>
                        <div style={{ width: 60, height: 60, marginLeft: 12}}>
                            <a href={x.link}> <img style={{ width: 60, height:60 }} alt="..." height className="img-rounded img-no-padding img-responsive" src={imagen} /></a>
                            </div>
                            <Col className="ml-auto mr-auto" lg="6" md="3" sm="3" xs="3">
                                <a href={x.link}><span style={{ fontSize: 14 }}><b>{x.nomarticulo}</b></span></a>
                            {x.enviogratis == SiNo.Si ? <p><Badge color="success" style={{ marginRight: 2 }}>{t("_enviogratis")} </Badge></p> : ""}  
                                {errorlinea != "" ? <p><span className='text-danger'><b>{errorlinea}</b></span></p> : "" }                           
                            </Col>
                            <div style={{ width: 68, fontSize: 14, textAlign: 'right', alignSelf: 'stretch', marginRight: 7  }}>                            
                                <b>{x.precio}</b>
                            </div>
                            <div style={{ width: 74}} >                           
                                <a style={{ float: 'left', background: '#eeeeee', borderRadius: '3px', width: 20, height: 40, marginRight: 1 }} onClick={(e) => { e.preventDefault(); CantidadArticulo(x.idlinea, -1); }}>
                                    <center style={{ marginTop: 10 }}><i className="nc-icon nc-simple-delete" /></center>
                                </a>
                                <Input
                                id="nombre2" name='nombre2'                                
                                style={{ float: 'left', width: 32, background: '#ffffff' }}
                                value={x.cantidad}
                                placeholder=""
                                disabled
                                    type="text"/>
                            <a style={{ float: 'left', background: '#eeeeee', borderRadius: '3px', width: 20, height: 40, marginLeft: 1 }} onClick={(e) => { e.preventDefault(); CantidadArticulo(x.idlinea, 1); }}>
                                    <center style={{ marginTop: 10, }}><i className="nc-icon nc-simple-add" /></center>
                                </a>                              
                            </div>
                            <div style={{ width: 68, fontSize: 14, textAlign: 'right', alignSelf: 'stretch', marginRight: 7}}>  
                                <b>{x.total}</b>
                            </div>
                        <div style={{ width: 20, height: 40, marginRight: 10}}>
                                <a onClick={(e) => { e.preventDefault(); DeleteArticulo(x.idlinea); }}> <i class="fa fa-times" aria-hidden="true"></i></a>
                            </div>
                        </Row>
                    </li>
                <hr />
            </div>
        }) : <div><Row><Col md="12"><div style={{ fontSize: 14 }}><b>{t("_carritovacio")}</b></div></Col></Row></div>;

    let direccion = !loading && carrito != null && carrito.direciones != null && carrito.direciones.length > 0 && direccionSelected > 0 ? carrito.direciones.find(x => x.id_Direccion == direccionSelected) : null;

    const [dia, setDiaAccion] = React.useState({ dia: "", diaft: "", hora:""});

    function cambiofecha(id, a, b) {
        if (id == "envio" || id == "entrega" || id == "comer")
            setDiaAccion({ dia: a, diaft: b, hora: dia.hora });
        else if (id == "enviohora" || id == "entregahora" || id == "comerhora")
            setDiaAccion({ dia: dia.dia, diaft: dia.diaft, hora: a.target.value});
    }     

    function TramitarPedido(e)
    {
       
        var jsonData = JSON.stringify({
            tipo: activePill,
            dia: dia.dia, diaft: dia.diaft, hora: dia.hora,
            nombre: activePill > 1 ?document.getElementById('nombre' + activePill).value : "",
            dni: activePill > 1 ? document.getElementById('dni' + activePill).value : "",
            telefono: activePill > 1 ? document.getElementById('telefono' + activePill).value : "",
            observ: document.getElementById('observ' + activePill).value,
            direccion: direccionSelected
        });

        fetch('api/Data/TramitarPedido', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: jsonData

        }).then(response => response.json()).then(res =>
        {
            //Si la tramitacion fue correcta
            if (res != null && res.ok)
            {
                window.location.assign('/pedidosuccess?' + res.urlsucess);
                setTramitandoPedido(false);
            }
            else
            {
                setErrortramitarPedido(res);
                setTramitandoPedido(false);
            }

        }).catch(error => {            
            setTramitandoPedido(false);
        });

    }

    let DisableDoPedidos = !loading && carrito != null && ((!carrito.envioenabled && !carrito.recogidaenabled && !carrito.comerenabled) || (carrito.totalrestantepedidominimo > 0) || (!carrito.userlogged));

  return (
      <>     
          {TramitandoPedido ?
          <div style={{
              position: "absolute", backgroundColor: '#000000', opacity: '0.5', width: '100%', height: '100%',
              top: 0,
              left: 0,
              zIndex: 1000000,
              color: '#FFFFFF',
              textAlign: 'center',
              fontSize: 25,           
          }}> 
              <Container style={{ marginTop:250 }}>
                  <Row>
                      <Col className="ml-auto mr-auto text-center" md="8">
                          <div className="space-top" />
                          <i className="nc-icon nc-paper" />
                          <p></p>
                              <b>{t("_tramitandopedido")}</b>
                          <div className="space-top" />
                          <Progress
                              animated 
                              barClassName="progress-bar-primary" 
                              value={100}                               
                          />
                      </Col>                      
                  </Row>
                  <div className="demo-footer text-center">

                  </div>
              </Container>              
              </div>
            : ""}
          <ColorNavbarATMSimple color='danger' items={!loading && carrito != null ? carrito.numarts : 0} />          
          <div className="main" style={{ marginTop: 30 }}>
              <div className="section section-lightgray">
                  <Container>
                      <h3><b>{t("_micarrito")}</b></h3>
                      <br />                   
                      <div>
                          <Row>
                              <Col lg='8' md="12" sm="12">                                                                                                         
                                    <Card className="card-with-shadow">
                                        <CardBody>
                                        <div >                                             
                                            <span style={{ fontSize: 17, fontWeight: "bold" }}>
                                                  {t("_detallepedido")}
                                            </span>
                                            <div style={{ backgroundColor: '#fc030f', height: 5, width: 50, marginTop: 5 }} />
                                        </div>                                 
                                        <br />
                                        <Row> 
                                            <Col className="ml-auto mr-auto" >
                                                <ul className="list-unstyled follows">
                                                      {pedidos}
                                                </ul>
                                            </Col>
                                          </Row>
                                          {errorTramitarPedido != null && !errorTramitarPedido.ok && errorTramitarPedido.errorglobal != null && errorTramitarPedido.errorglobal != "" ? <center><span className='text-danger'><b><b>{errorTramitarPedido.errorglobal}</b></b></span></center> : ""}
                                        </CardBody>
                                  </Card>
                                  {!loading && carrito != null && carrito.lineas != null && carrito.lineas.length > 0 ?
                                      <Card className="card-with-shadow">                                          
                                          <CardBody>
                                              <div >
                                                  <span style={{ fontSize: 17, fontWeight: "bold" }}>Seleccionar Opcion</span>
                                                  <div style={{ backgroundColor: '#fc030f', height: 5, width: 50, marginTop: 5 }} />
                                              </div>
                                              <br />
                                              {!carrito.envioenabled && !carrito.recogidaenabled && !carrito.comerenabled ? <center><span className='text-danger'><b> {t("_sintiposentrega")}</b></span></center> : ""}
                                              {carrito.userlogged ?
                                                  <div className="project-pills">
                                                      <Nav className="nav-pills-danger" pills>
                                                          {carrito.envioenabled ?
                                                              <NavItem>
                                                                  <NavLink
                                                                      className={activePill === "1" ? "active" : ""}
                                                                      href="#"
                                                                      onClick={(e) => {
                                                                          e.preventDefault();
                                                                          setActivePill("1");
                                                                          setLoading(true);
                                                                      }}
                                                                  >
                                                                      {t("_enviara")}
                    </NavLink>
                                                              </NavItem> : ""}
                                                          {
                                                              carrito.recogidaenabled ?
                                                                  <NavItem>
                                                                      <NavLink
                                                                          className={activePill === "2" ? "active" : ""}
                                                                          href="#"
                                                                          onClick={(e) => {
                                                                              e.preventDefault();
                                                                              setActivePill("2");
                                                                              setLoading(true);
                                                                          }}
                                                                      >
                                                                          {t("_recogerestable")}
                    </NavLink>
                                                                  </NavItem> : ""}
                                                          {
                                                              carrito.comerenabled ?
                                                                  <NavItem>
                                                                      <NavLink
                                                                          className={activePill === "3" ? "active" : ""}
                                                                          href="#"
                                                                          onClick={(e) => {
                                                                              e.preventDefault();
                                                                              setActivePill("3");
                                                                              setLoading(true);
                                                                          }}
                                                                      >
                                                                          {t("_comerestable")}
                    </NavLink>
                                                                  </NavItem>
                                                                  : ""}
                                                      </Nav>
                                                      {/* Pill panes */}
                                                      <TabContent activeTab={activePill}>
                                                          {carrito.envioenabled ?
                                                              <TabPane tabId="1" >
                                                                  <Container>
                                                                      <br />
                                                                      <Row>
                                                                          <Col className="description-section" md="8">
                                                                              <span style={{ fontSize: 15, fontWeight: "bold" }}>
                                                                                  <b>{t("_direccionentrega")}</b>
                                                                              </span>
                                                                          </Col>
                                                                          <Col className="description-section" md="4">
                                                                              <span style={{ fontSize: 14, color: '#3273a8' }}>
                                                                                  <a onClick={(e) => { e.preventDefault(); setSeleccionarDireccionModal(true); }}><b>{t("_selectdireccion")}</b></a>
                                                                              </span>
                                                                          </Col>
                                                                      </Row>
                                                                      <br />
                                                                      <Row>
                                                                          <Col md='12'>
                                                                              <div style={{ fontSize: 13, border: '1px solid #f5f2f2', padding: 10 }} >
                                                                                  {direccion != null ?
                                                                                      <div>
                                                                                          <span style={{ fontSize: 14, fontWeight: "bold" }}><b>{direccion.alias}</b></span>
                                                                                          <p><b>{direccion.nombre} {direccion.apellidos}</b></p>
                                                                                          <p><b>{direccion.direccion}</b></p>
                                                                                          <p><b>{direccion.codPostal} {direccion.poblacion} ({direccion.provincia}). {direccion.pais}</b></p>
                                                                                          <br />
                                                                                          <p><b>Tel {direccion.telefono}</b></p>
                                                                                          <hr />
                                                                                          <span style={{ fontSize: 14, color: '#3273a8' }}>
                                                                                              <a onClick={(e) => { e.preventDefault(); setDireccionModal(direccion.id_Direccion); }}><b>{t("_editar")}</b></a>
                                                                                          </span></div> : ""}
                                                                              </div>
                                                                              <br />
                                                                              <Button color="info" outline type="reset" onClick={(e) => { e.preventDefault(); setDireccionModal(0); }}><i class="fa fa-plus" aria-hidden="true"></i> {t("_adddireccion")}</Button>
                                                                          </Col>
                                                                      </Row>
                                                                      <br />
                                                                      <Row>
                                                                          <Col md="12">
                                                                              <span style={{ fontSize: 15, fontWeight: "bold" }}>
                                                                                  <b>{t("_prefeenvio")}<span className="icon-danger">{t("_confirmestable")}</span></b>
                                                                              </span>
                                                                          </Col>
                                                                      </Row>
                                                                      <br />
                                                                      <Row >
                                                                          <Col md="6">
                                                                              <h6>
                                                                                  {t("_dia")} <span className="icon-danger">*</span>
                                                                              </h6>
                                                                              <DatePicker id="envio" name='envio' weekStartsOn={1} minDate={carrito.today}
                                                                                  value={dia.dia}
                                                                                  onChange={(v, f) => cambiofecha("envio", v, f)}
                                                                                  dayLabels={['do', 'lu', 'ma', 'mi', 'ju', 'vi', 'sa']}
                                                                                  monthLabels={['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']}
                                                                              />
                                                                          </Col>
                                                                          <Col md="6">
                                                                              <h6> {t("_hora")} <span className="icon-danger">*</span></h6>
                                                                              <Input type="time" id="enviohora" name='enviohora' onChange={(e) => cambiofecha("enviohora", e)} value={dia.hora} />
                                                                          </Col>
                                                                      </Row>
                                                                      <br />
                                                                      <Row >
                                                                          <Col md="12">
                                                                              <FormGroup>
                                                                                  <h6>{t("_observaciones")}</h6>
                                                                                  <Input
                                                                                      id="observ1" name='observ1'
                                                                                      className="textarea-limited"
                                                                                      maxLength="250"
                                                                                      placeholder="Maximo 250 caracteres"
                                                                                      rows="7"
                                                                                      type="textarea"
                                                                                  />
                                                                                  <br />
                                                                                  <h5>
                                                                                      <small>
                                                                                          <span
                                                                                              className="pull-right"
                                                                                              id="textarea-limited-message"
                                                                                          >
                                                                                              <span className="icon-danger">*</span> {t("_confirmdatos")}</span>
                                                                                      </small>
                                                                                  </h5>
                                                                              </FormGroup>
                                                                          </Col>
                                                                      </Row>
                                                                  </Container>
                                                              </TabPane>
                                                              : ""}
                                                          {
                                                              carrito.recogidaenabled ?
                                                          <TabPane tabId="2" >
                                                              <Container>
                                                                  <br />
                                                                  <Row>
                                                                      <Col md="12">
                                                                          <span style={{ fontSize: 15, fontWeight: "bold" }}>
                                                                                <b>{t("_datosestable")}</b>
                                                                          </span>
                                                                      </Col>
                                                                  </Row>
                                                                  <br />
                                                                  <Row>
                                                                      <Col md='12'>
                                                                          <div style={{ fontSize: 12, border: '1px solid #f5f2f2', padding: 10 }} >
                                                                              <Row>
                                                                                  <Col md='6'>
                                                                                              <span style={{ fontSize: 14, fontWeight: "bold" }}><b>{t("_direccion")}</b></span>
                                                                                      <p><b>{carrito.direccion}</b></p>
                                                                                      {carrito.latstring != "0" && carrito.lonstring != "0" ?
                                                                                          <span style={{ fontSize: 14, color: '#3273a8' }}>
                                                                                                      <a target='_blank' href={"http://maps.google.com/maps?addr=" + carrito.latstring + "," + carrito.lonstring}><b>{t("_vermapa")}</b></a>
                                                                                          </span>: ""                                                                                          
                                                                                      }
                                                                                  </Col>
                                                                                  <Col md='6'>
                                                                                              <span style={{ fontSize: 14, fontWeight: "bold" }}><b>{t("_horario")}</b></span>
                                                                                              <p><b>{carrito.horario != null && carrito.horario != "" ? carrito.horario : t("_desconocido")}</b></p>
                                                                                  </Col>
                                                                              </Row>
                                                                          </div>
                                                                          <br />

                                                                      </Col>
                                                                  </Row>
                                                                  <br />
                                                                  <Row>
                                                                      <Col md="12">
                                                                          <span style={{ fontSize: 15, fontWeight: "bold" }}>
                                                                                <b>{t("_datosrecogida")}</b>
                                                                          </span>
                                                                      </Col>
                                                                  </Row>
                                                                  <br />
                                                                  <Row >
                                                                      <Col md="6">
                                                                          <h6>
                                                                            {t("_nombreape")} <span className="icon-danger">*</span>
                                                                          </h6>

                                                                          <Input
                                                                              id="nombre2" name='nombre2'
                                                                              className="border-input"
                                                                              defaultValue=""
                                                                              placeholder=""
                                                                              type="text"
                                                                          />
                                                                      </Col>
                                                                      <Col md="6">
                                                                                  <h6>{t("_dni")} <span className="icon-danger">*</span></h6>
                                                                          <Input
                                                                              id="dni2" name='dni2'
                                                                              className="border-input"
                                                                              defaultValue=""
                                                                              placeholder=""
                                                                              type="text"
                                                                          />
                                                                      </Col>
                                                                  </Row>
                                                                  <br />
                                                                  <Row >
                                                                      <Col md="6">
                                                                                  <h6> {t("_telefoncontact")} <span className="icon-danger">*</span></h6>
                                                                          <Input
                                                                              id="telefono2" name='telefono2'
                                                                              className="border-input"
                                                                              defaultValue=""
                                                                              placeholder=""
                                                                              type="text"
                                                                          />
                                                                      </Col>
                                                                  </Row>
                                                                  <br />
                                                                  <Row>
                                                                      <Col md="12">
                                                                          <span style={{ fontSize: 15, fontWeight: "bold" }}>
                                                                                      <b> {t("_preferecogida")} <span className="icon-danger"> {t("_confirmestable")}</span></b>
                                                                          </span>
                                                                      </Col>
                                                                  </Row>
                                                                  <br />

                                                                  <Row >
                                                                      <Col md="6">
                                                                          <h6>
                                                                            {t("_dia")} <span className="icon-danger">*</span>
                                                                          </h6>
                                                                          <DatePicker id="entrega" name='entrega' weekStartsOn={1} minDate={carrito.today}
                                                                              value={dia.dia} onChange={(v, f) => cambiofecha("entrega", v, f)}
                                                                              dayLabels={['do', 'lu', 'ma', 'mi', 'ju', 'vi', 'sa']}
                                                                              monthLabels={['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']}
                                                                          />                                                                          
                                                                      </Col>
                                                                      <Col md="6">
                                                                            <h6>{t("_hora")} <span className="icon-danger">*</span></h6>
                                                                          <Input type="time" id="entregahora" name='entregahora' onChange={(e) => cambiofecha("entregahora", e)} value={dia.hora} /> 
                                                                      </Col>
                                                                  </Row>
                                                                  <br />
                                                                  <Row >
                                                                      <Col md="12">
                                                                          <FormGroup>
                                                                                <h6>{t("_observaciones")}</h6>
                                                                              <Input
                                                                                  id="observ2" name='observ2'
                                                                                  className="textarea-limited"
                                                                                  maxLength="250"
                                                                                  placeholder="Maximo 250 caracteres"
                                                                                  rows="7"
                                                                                  type="textarea"
                                                                              />
                                                                              <br />
                                                                              <h5>
                                                                                  <small>
                                                                                      <span
                                                                                          className="pull-right"
                                                                                          id="textarea-limited-message"
                                                                                      >
                                                                                        <span className="icon-danger">*</span> {t("_confirmdatos")}
                                                                                    </span>
                                                                                  </small>
                                                                              </h5>

                                                                          </FormGroup>
                                                                      </Col>
                                                                  </Row>
                                                              </Container>
                                                                  </TabPane> : ""}
                                                          {
                                                              carrito.comerenabled ?
                                                                  <TabPane tabId="3" >
                                                                      <Container>
                                                                          <br />
                                                                          <Row>
                                                                              <Col md="12">
                                                                                  <span style={{ fontSize: 15, fontWeight: "bold" }}>
                                                                                      <b>{t("_datosestable")}</b>
                                                                                  </span>
                                                                              </Col>
                                                                          </Row>
                                                                          <br />
                                                                          <Row>
                                                                              <Col md='12'>
                                                                                  <div style={{ fontSize: 12, border: '1px solid #f5f2f2', padding: 10 }} >
                                                                                      <Row>
                                                                                          <Col md='6'>
                                                                                              <span style={{ fontSize: 14, fontWeight: "bold" }}><b>{t("_direccion")}</b></span>
                                                                                              <p><b>{carrito.direccion}</b></p>
                                                                                              {carrito.latstring != "0" && carrito.lonstring != "0" ?
                                                                                                  <span style={{ fontSize: 14, color: '#3273a8' }}>
                                                                                                      <a target='_blank' href={"http://maps.google.com/maps?addr=" + carrito.latstring + "," + carrito.lonstring}><b>Ver Mapa</b></a>
                                                                                                  </span> : ""
                                                                                              }
                                                                                          </Col>
                                                                                          <Col md='6'>
                                                                                              <span style={{ fontSize: 14, fontWeight: "bold" }}><b>{t("_horario")}</b></span>
                                                                                              <p><b>{carrito.horario != null && carrito.horario != "" ? carrito.horario : t("_desconocido")}</b></p>
                                                                                          </Col>
                                                                                      </Row>
                                                                                  </div>
                                                                                  <br />

                                                                              </Col>
                                                                          </Row>
                                                                          <br />
                                                                          <Row>
                                                                              <Col md="12">
                                                                                  <span style={{ fontSize: 15, fontWeight: "bold" }}>
                                                                                      <b> {t("_prefereserva")} <span className="icon-danger"> {t("_confirmestable")}</span></b>                                                                                      
                                                                                  </span>
                                                                              </Col>
                                                                          </Row>
                                                                          <br />
                                                                          <Row >
                                                                              <Col md="6">
                                                                                  <h6>
                                                                                      {t("_nombreape")} <span className="icon-danger">*</span>
                                                                                  </h6>

                                                                                  <Input
                                                                                      id="nombre3" name='nombre3'
                                                                                      className="border-input"
                                                                                      defaultValue=""
                                                                                      placeholder=""
                                                                                      type="text"
                                                                                  />
                                                                              </Col>
                                                                              <Col md="6">
                                                                                  <h6>{t("_dni")} <span className="icon-danger">*</span></h6>
                                                                                  <Input
                                                                                      id="dni3" name='dni3'
                                                                                      className="border-input"
                                                                                      defaultValue=""
                                                                                      placeholder=""
                                                                                      type="text"
                                                                                  />
                                                                              </Col>
                                                                          </Row>
                                                                          <br />
                                                                          <Row >
                                                                              <Col md="6">
                                                                                  <h6>{t("_telefoncontact")} <span className="icon-danger">*</span></h6>
                                                                                  <Input
                                                                                      id="telefono3" name='telefono3'
                                                                                      className="border-input"
                                                                                      defaultValue=""
                                                                                      placeholder=""
                                                                                      type="text"
                                                                                  />
                                                                              </Col>
                                                                          </Row>
                                                                          <br />
                                                                          <Row >
                                                                              <Col md="6">
                                                                                  <h6>
                                                                                      {t("_dia")} <span className="icon-danger">*</span>
                                                                                  </h6>
                                                                                  <DatePicker id="comer" name='comer' weekStartsOn={1} minDate={carrito.today}
                                                                                      value={dia.dia} onChange={(v, f) => cambiofecha("comer", v, f)}
                                                                                      dayLabels={['do', 'lu', 'ma', 'mi', 'ju', 'vi', 'sa']}
                                                                                      monthLabels={['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']}
                                                                                  />
                                                                              </Col>
                                                                              <Col md="6">
                                                                                  <h6>{t("_hora")} <span className="icon-danger">*</span></h6>
                                                                                  <Input type="time" id="comerhora" name='comerhora' onChange={(e) => cambiofecha("comerhora", e)} value={dia.hora} />
                                                                              </Col>
                                                                          </Row>
                                                                          <br />
                                                                          <Row >
                                                                              <Col md="12">
                                                                                  <FormGroup>
                                                                                      <h6>{t("_observaciones")}</h6>
                                                                                      <Input
                                                                                          id="observ3" name='observ3'
                                                                                          className="textarea-limited"
                                                                                          maxLength="250"
                                                                                          placeholder="Maximo 250 caracteres"
                                                                                          rows="7"
                                                                                          type="textarea"
                                                                                      />
                                                                                      <br />
                                                                                      <h5>
                                                                                          <small>
                                                                                              <span
                                                                                                  className="pull-right"
                                                                                                  id="textarea-limited-message"
                                                                                              >
                                                                                                  <span className="icon-danger">*</span> {t("_confirmdatos")}                                                                                                  
                                                                                                </span>
                                                                                          </small>
                                                                                      </h5>

                                                                                  </FormGroup>
                                                                              </Col>
                                                                          </Row>
                                                                      </Container>
                                                                  </TabPane>
                                                                  : ""}
                                                      </TabContent>
                                                  </div>
                                                  :
                                                  <div className="project-pills">
                                                      <Row>
                                                          <Col md="12">
                                                              <Button block outline className="btn-wd" color="info" href="/login">
                                                                  {t("_iniciarsesion")} 
                                                           </Button>
                                                          </Col>
                                                      </Row>
                                                  </div>
                                              }
                                          </CardBody>                                                                                            
                                      </Card>
                                      : ""
                                  }
                              </Col>                              
                              <Col lg='4' md="12" sm="12">
                                      <Card className="card-with-shadow">
                                          <CardBody>
                                            <div >
                                              <span style={{ fontSize: 17, fontWeight: "bold" }}>
                                                  {t("_resumenpedido")}
                                                  </span>
                                              <div style={{ backgroundColor: '#fc030f', height: 5, width: 50, marginTop: 5 }} />
                                            </div>
                                          <br />
                                          {
                                              !loading && carrito != null && carrito.totalrestantepedidominimo > 0 ?
                                                  <Row style={{ backgroundColor: '#f8d7da', margin:1, padding: 5 }}>
                                                      <Col lg="12">
                                                          <b>{t("_needadd")} {carrito.totalrestantepedidominimo.toFixed(2)} &euro; {t("_tollegaralminimo")}</b>
                                              </Col>
                                                  </Row> : ""
                                          }
                                          <div className="accounts-suggestion">
                                              <br />
                                              <Row>                                                  
                                                  <Col className="description-section" xs='8' sm='8' md="8" lg='8'>
                                                      
                                                      <span style={{ fontSize: 15}}>
                                                          <b>{!loading && carrito != null ? carrito.numarts : "0"} {t("_productos")}</b>
                                                        </span>
                                                  </Col>
                                                  <Col className="description-section" xs='4' sm='4' md="4" lg='4'>
                                                      <span style={{ fontSize: 15 }}>
                                                          <b>{!loading && carrito != null ? carrito.precioarts : "0.00"}</b>
                                                      </span>
                                                  </Col>
                                              </Row>
                                              <hr/>
                                              <Row>
                                                  <Col className="description-section" md="6">
                                                      <span style={{ fontSize: 15, fontWeight: '300' }}>
                                                          <b>{t("_envio")}:</b>
                                                      </span>
                                                  </Col>
                                              </Row>
                                              <Row><Col className="description-section" xs='8' sm='8' md="8" lg='8'>
                                                  <span style={{ fontSize: 15, marginLeft: 10, color:"#cccccc" }}>
                                                      <b>{t("_transporte")}</b>
                                                  </span>
                                                </Col>
                                                  <Col className="description-section" xs='4' sm='4' md="4" lg='4'>
                                                      <span style={{ fontSize: 15, color: "#cccccc"}}>
                                                          <b>{!loading && carrito != null ? carrito.precioenvio : "0.00"}</b>
                                                      </span>
                                                  </Col>
                                              </Row>
                                              <hr />    
                                              <Row><Col className="description-section" xs='8' sm='8' md="8" lg='8'>
                                                  <span style={{ fontSize: 18, color: "#cf0a2f", fontWeight: "bold" }}>
                                                      <b>{t("_total")}</b>
                                                  </span>
                                              </Col>
                                                  <Col className="description-section" xs='4' sm='4' md="4" lg='4'>
                                                      <span style={{ fontSize: 18, color: "#cf0a2f", fontWeight: "bold"}}>
                                                          <b>{!loading && carrito != null ? carrito.total : "0.00"}</b>
                                                      </span>
                                                  </Col>
                                              </Row>
                                          </div>
                                          <br/>
                                          <div className="text-center">                                             
                                              <Button
                                                  className="btn-wd"
                                                  color={DisableDoPedidos ? "default" : "info"}
                                                  onClick={(e) => {
                                                      e.preventDefault(); TramitarPedido(e); setTramitandoPedido(true);}}
                                                  block
                                                  disabled={DisableDoPedidos}
                                              >
                                                  {t("_dopedido")}
                                                </Button>
                                          </div>                                 
                                          </CardBody>
                                      </Card>
                              </Col>
                          </Row>
                      </div>
                      {direccionModal >= 0 ? <DireccionModal open={direccionModal >= 0} setModal={setDireccionModal} id={direccionModal} loadingpage={setLoading} /> : ""}
                      {selecciondireccionModal ? <DireccionSeleccionModal open={selecciondireccionModal} setModal={setSeleccionarDireccionModal}  setselected={setDireccionSelected} /> : ""}

                      
                  </Container>
              </div> 
          </div>                                      
    </>
  );
}

//{ !loading && item != null && item.tipoofi == TipoOficina.Venta ? <FooterATMVenta /> : <FooterATM /> }   

export default Cesta;
