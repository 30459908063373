import React from "react";
// reactstrap components
import {
    Button,
    Modal,
    Container,   
    Row,
    Col
} from "reactstrap";

import { useTranslation } from 'react-i18next';

function ProductoAddedModal({...props })
{
    const { t } = useTranslation();

    //variables de las propiedas recibidas
    const { open, setModal, id_linea } = props;

    const [carrito, setCarrito] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {

        var jsonData = JSON.stringify(id_linea);

        fetch('api/Data/GetCarritoAddedProducto', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: jsonData

        }).then(response => response.json()).then(data => {
            setCarrito(data);
            setLoading(false)
        }).catch(error => {
            setCarrito(null);
            setLoading(false)
        });
    }, [loading]);

    let imagen = !loading && carrito != null && carrito.producto != null && carrito.producto.imagen != null && carrito.producto.imagen != "" ? carrito.producto.imagen : require("../assets/img/image_placeholder.jpg");
   
    return (
        <>           
            <Modal isOpen={open} toggle={() => setModal(false)}>                
                <Container>
                    <div className="modal-header">
                        <span style={{ fontSize: 15, fontWeight: "bold" }}>
                            <color className="text-danger">{t("_productoaddecarrito")}</color>
                        </span>
                    </div>
                    <br />
                    <Row>
                        <div style={{ width: 120, height: 85, marginLeft: 20 }}>
                            <img style={{ width: 100, height: 80 }} alt="..." height className="img-rounded img-no-padding img-responsive" src={imagen} />
                        </div>                        
                        <Col className="mr-auto" style={{ fontSize: 16 }}>
                            <Row>
                                <Col className="mr-auto" >
                                    <span ><b>{!loading && carrito != null && carrito.producto != null ? carrito.producto.nomArticulo : ""}</b></span>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mr-auto" >
                                    <b>{t("_cantidad")}: {!loading && carrito != null && carrito.producto != null ? carrito.producto.cantidad : ""}</b>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mr-auto" >
                                    <b>{t("_cantidad")}:  <color className="text-danger">{!loading && carrito != null && carrito.producto != null ? carrito.producto.precio : ""} &euro;</color></b>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col className="mr-auto" style={{ fontSize: 16, fontWeight: 'bold' }}>
                                    {t("_totalproductos")}: <color className="text-danger">{!loading && carrito != null ? carrito.numarts : ""}</color>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mr-auto" style={{ fontSize: 16, fontWeight: 'bold'}}>
                                    <b>{t("_subtotalpedido")}: <color className="text-danger">{!loading && carrito != null ? carrito.precioarts : ""}</color></b>
                                </Col>
                            </Row>                         
                        </Col>
                    </Row>
                    <hr />
                    <div className="pull-right" style={{ marginBottom: 15 }}>                        
                        <Button className="btn-wd" size="sm" color="danger" outline style={{ marginRight: 5 }} onClick={(e) => { e.preventDefault(); setModal(-1) }}>{t("_continuarcomprando")}</Button>
                        <Button className="btn-wd" size="sm" color="danger" href="/cart" >{t("_iracarrito")}</Button>                        
                    </div>
                </Container>
            </Modal>
        </>
    );
}

export default ProductoAddedModal;