import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";

// reactstrap components
import {
    Button,
    Collapse,    
    NavbarBrand,
    Navbar,
    NavItem,
    Nav,
    NavLink,
    Container,
    UncontrolledTooltip,  
} from "reactstrap";
import CestaModal from "../../view/CestaModal";

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom'

import * as analytics from '../../ga4'

import { Helmet } from 'react-helmet'
import OthelloComboBoxIdiomas from "../../componentsothello/OthelloComboBoxIdiomas";

// core components

function ColorNavbarATM({ ...props })
{
    const { t } = useTranslation();
    const location = useLocation();

    const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
    const [bodyClick, setBodyClick] = React.useState(false);
    const [collapseOpen, setCollapseOpen] = React.useState(false);

    //gestiona la obtencion de regiones
    const [menu, setMenuOficina] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const [carrito, setCarrito] = React.useState(null);
    const [loadingcarro, setLoadingCarro] = React.useState(true);

    const [userlogged, setUserLogged] = React.useState(null);
    const [loadinguserlogged, setLoadingUserLogged] = React.useState(true);

    const [carritoresumen, setCarritoResumen] = React.useState(null);         

    //variables de las propiedas recibidas
    const { visibleidiomas, onIdiomaChanged } = props;
   
    //Hook Efectos de navegaciones
    React.useEffect(() =>
    {
        let headroom = new Headroom(document.getElementById("navbar-main"));
        // initialise
        headroom.init();
        const updateNavbarColor = () => {
          if (
            document.documentElement.scrollTop > 499 ||
            document.body.scrollTop > 499
          ) {
            setNavbarColor("");
          } else if (
            document.documentElement.scrollTop < 500 ||
            document.body.scrollTop < 500
          ) {
            setNavbarColor("navbar-transparent");
          }
        };
        window.addEventListener("scroll", updateNavbarColor);
        return function cleanup() {
          window.removeEventListener("scroll", updateNavbarColor);
        };
    });

    //Hook de efectos para la obtencion de las regiones que forman el menu
    React.useEffect(() =>
    {
        fetch('api/Data/GetMenuOficinaSelected')
            .then(response => response.json())
            .then(data => {
                setMenuOficina(data);
                setLoading(false)    

                const favicon = document.getElementById("favicon");
                const iconoweb = data != null ? "/imagenes/favicon_" + data.idoficina + "_.ico" : "favicon_.ico";
                favicon.href = iconoweb;

                if (data != null && data.codigo_analitycs != null && data.codigo_analitycs != "") {

                    analytics.init(data.codigo_analitycs);

                    const path = location.pathname + location.search
                    analytics.sendPageview(path)
                }

            }).catch(error => {
                setMenuOficina([]);
                setLoading(false)
            });

    }, [loading]);

    //Hook de efectos para la obtencion de las regiones que forman el menu
    React.useEffect(() => {
        fetch('api/Data/GetNumCarrito')
            .then(response => response.json())
            .then(data => {
                setCarrito(data);
                setLoadingCarro(false)
            }).catch(error => {
                setCarrito(null);
                setLoadingCarro(false)
            });

    }, [loadingcarro]);

    function GoToMiCuenta() {
             
        fetch('api/Data/UsuarioLogged')
            .then(response => response.json())
            .then(res => {
                //Si hubo error
                if (!res)
                    window.location.assign('/login');
                else {
                    window.location.assign('/usuario');
                }
            }).catch(error => {
                setMenuOficina([]);
                setLoading(false)
            });                           
    }

    React.useEffect(() => {
        fetch('api/Data/UsuarioLoggedData')
            .then(response => response.json())
            .then(data => {
                setUserLogged(data);
                setLoadingUserLogged(false)
            }).catch(error => {
                setUserLogged(null);
                setLoadingUserLogged(false)
            });

    }, [loadinguserlogged]);

    const datamenu = !loading && menu != null && menu.itemsmenu != null && menu.itemsmenu.length > 0 ?
        menu.itemsmenu.map(x =>
        {
            //como en esta opcion solo puede darse si hablamos de subareas
            //if (x.itemssubmenu != null && x.itemssubmenu.length > 0)
            //{
            //    let opcionesmenu = x.itemssubmenu.map(y =>
            //    {
            //        return <DropdownItem to={"/area/" + y.nombre.toLowerCase().replace(/\s/g, "-")} tag={Link}>{y.nombre}</DropdownItem>
            //    });

            //    return <UncontrolledDropdown nav inNavbar>
            //        <DropdownToggle className="mr-2" color="default" caret nav>{x.nombre}</DropdownToggle>
            //        <DropdownMenu className="dropdown-danger" right>
            //            {opcionesmenu}
            //        </DropdownMenu>
            //    </UncontrolledDropdown>;
            //}
            //else
            //{
            //Si tiene link externo debo llevarle a dicho link, sino, montaremos nuestra url
            let link = x.linkexterno != null && x.linkexterno != "" ? x.linkexterno : (x.region == "1" ? x.linkregion : x.linkarea);

            let nombreitem = menu.idoficina == 13 ? <span style={{ textTransform: 'capitalize', fontSize: 13 }}>{x.nombre}</span> : x.nombre;

                return <NavItem className="active">
                    <NavLink href={link} target={x.linkexterno != null && x.linkexterno != "" ? "_blank" : "_self"}>
                        {nombreitem} <span className="sr-only" > (current)</span>
                    </NavLink>
                </NavItem>
            //}
        }) : "";

    const iconoweb = !loading && menu != null ? "/imagenes/logo_" + menu.idoficina + "_.png" : "/imagenes/logo_0.png";

    const onTimerHandle = () =>
    {
        if (carritoresumen == null)
        {
            var jsonData = JSON.stringify(-1);

            fetch('api/Data/GetCarritoAddedProducto', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: jsonData

            }).then(response => response.json()).then(data => {
                if (data != null && data.numarts > 0)
                    setCarritoResumen(data);
         
            }).catch(error => {
                setCarritoResumen(null);
            });

        }
    }

    const onBlur = (event) => {

        if (event != null)
        {
            if (carritoresumen == null)
                setTimeout(onTimerHandle, 180);
        }
    }

    const onTimerCloseHandle = () =>
    {
        if (carritoresumen != null)
            setCarritoResumen(null);
    }

    const onMouseOut = (event) => {

        if (event != null) {
            if (carritoresumen != null)
                setTimeout(onTimerCloseHandle, 180);
        }
    }

    const OnIdiomaChangedNavBar = () =>
    {
        setLoading(true);

        if (onIdiomaChanged != null)
            onIdiomaChanged(true);
    }


    let textoCarrito = menu != null && menu.idoficina != 13 ? t("_micarrito") + " (" + carrito + ")" : "";
    let nombreuserlogged = userlogged != null && userlogged.nombre != "" ? userlogged.nombre : (menu != null && menu.idoficina != 13 ? t("_micuenta") : "");
    let colorcarrito = menu != null && menu.idoficina != 13 ? "primary" : "dribbble";

    return(
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
            ) : null}
            <Helmet>
                <title>{!loading && menu != null ? (menu.titleseo != null && menu.titleseo != "" ? menu.titleseo : menu.nombre ): t('_bienvenidos')}</title>
                <meta name="description" content={!loading && menu != null ? (menu.descripcionseo != null && menu.descripcionseo != "" ? menu.descripcionseo : menu.nombre) : t('_bienvenidos')} />
                <meta property="og:locale" content="es_ES" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={!loading && menu != null ? (menu.titleseo != null && menu.titleseo != "" ? menu.titleseo : menu.nombre) : t('_bienvenidos')} />
                <meta property="og:description" content={!loading && menu != null ? (menu.descripcionseo != null && menu.descripcionseo != "" ? menu.descripcionseo : menu.nombre) : t('_bienvenidos')} />
                <meta property="og:url" content={!loading && menu != null ? menu.urlseo : ""} />
                <meta property="og:site_name" content={!loading && menu != null ? menu.sitenameseo : ""} />
                <meta name="keywords" content={!loading && menu != null ? menu.keywordsseo : ""} />
                <meta name="robots" content="index,follow" />
                <link rel="canonical" href={window.location.href} />
                <meta property="og:type" content="website" />     
                {
                    //<meta property="og:image" content="http://f...foto-37-1-1030x773.png" />
                    //<meta property="og:image:width" content="1030" />
                    //<meta property="og:image:height" content="773" />
                }                
        </Helmet>
      <Navbar className={classnames("fixed-top", navbarColor)} expand="lg" id="navbar-main">
        {visibleidiomas != null && visibleidiomas ? <OthelloComboBoxIdiomas onIdiomaChanged={() => OnIdiomaChangedNavBar()} /> : ""}                                                              
        <Container>
          <div className="navbar-translate">
            <NavbarBrand id="navbar-brand" to="/index" tag={Link}>                           
                <img src={iconoweb} height="56" alt="Logo" />
            </NavbarBrand>
            <UncontrolledTooltip placement="bottom" target="navbar-brand">
                {!loading && menu != null ? menu.nombre : ""}
            </UncontrolledTooltip>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
                <Nav className="ml-auto" navbar>
                    {datamenu}                                                                        
                            <NavItem>{/*boton redondeado className="btn-round"*/}
                                {!loading && menu != null && menu.botonesventa ? <Button href="/login" color="#000000" onClick={(e) => { e.preventDefault(); GoToMiCuenta(); }}><i className="nc-icon nc-single-02" /><b> {nombreuserlogged}</b></Button> : ""}
                            </NavItem>
                            <NavItem>
                                {!loading && menu != null && menu.botonesventa ? <Button color={colorcarrito} href="/cart" onMouseOver={(e) => { e.preventDefault(); onBlur(e); }} onMouseOut={(e) => { e.preventDefault(); onMouseOut(e); }}> <i className="nc-icon nc-cart-simple" /> {textoCarrito}</Button> : ""}
                                {carritoresumen != null ? <div style={{ position: 'absolute', zIndex: 1000001,  }}><CestaModal carrito={carritoresumen} /></div> : ""}
                            </NavItem>
                            
                </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ColorNavbarATM; 