import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardTitle,
    Form,
    Input,
    Container,
    Row,
    Col,
    FormGroup,
    Label
} from "reactstrap";

import ColorNavbarATMVacio from "../components/Navbars/ColorNavbarATMVacio.js";

import { useTranslation } from 'react-i18next';

function LoginRegistrarPage() {

    const { t, i18n } = useTranslation();

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("register-page");
        document.body.classList.add("full-screen");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("register-page");
            document.body.classList.remove("full-screen");
        };
    });

    const [loadingIdioma, setLoadingIdioma] = React.useState(true);

    React.useEffect(() => {

        fetch('api/Data/GetIdiomaActual')
            .then(response => response.json())
            .then(data => {
                i18n.changeLanguage(data.idioma);
                setLoadingIdioma(false);
            }).catch(error => {
                i18n.changeLanguage('es');
                setLoadingIdioma(false);
            });
    }, [loadingIdioma]);

    //Gestiona si estamos en modo logino en modo crear cuenta
    const [statepage, setLoginRegister] = React.useState(0);

    const [loginerror, setLoginError] = React.useState(false);

    const [aceptapriva, setAceptaPrivacidad] = React.useState(false);

    const [registroerror, setRegistroError] = React.useState(false);

    function Login(event)
    {
        event.preventDefault();
        //this.setState({ loading: true });
        //let formulario = document.getElementById('formlogin');
        let formData = new FormData(event.target);

        fetch('api/Data/LoginUsuario', {
            method: 'POST',
            body: formData,

        }).then(response => response.json()).then(res =>
        {
            //Si hubo error
            if (!res)
                setLoginError(true);
            else {
               // setLoginError(false);
                //this.props.history.push('admin/dashboard')
                window.location.assign('/index');
            }
        }).catch(error => {
            this.setState({ submitted: true, error: 2 });
        });      
    }

    function Registro(event)
    {
        //Si acepto la politica de privacidad
        if (aceptapriva)
        {
            event.preventDefault();
            //this.setState({ loading: true });
            //let formulario = document.getElementById('formlogin');
            let formData = new FormData(event.target);

            fetch('api/Data/RegistroUsuario', {
                method: 'POST',
                body: formData,

            }).then(response => response.json()).then(res => {
                //Si hubo error
                if (!res)
                    setRegistroError(true);
                else {
                    // setLoginError(false);
                    //this.props.history.push('admin/dashboard')
                    window.location.assign('/index');
                }
            }).catch(error => {
                this.setState({ submitted: true, error: 2 });
            });
        }
        else
            setRegistroError(true);
    }

    return (
        <>
            <ColorNavbarATMVacio />
            <div className="wrapper">
                <div
                    className="page-header"
                    style={{
                        backgroundImage:
                            "url(" + require("../assets/img/sections/bruno-abatti.jpg") + ")",
                    }}
                >
                    <div className="filter" />
                    <Container>
                        <Row>
                            <Col className="ml-auto" lg="6" md="6" sm="7" xs="12">
                                <div className="info info-horizontal">
                                    <div className="icon">
                                        <i className="fa fa-umbrella" />
                                    </div>
                                    <div className="description">
                                        <h3>We've got you covered</h3>
                                        <p>
                                            Larger, yet dramatically thinner. More powerful, but
                                            remarkably power efficient. Everything you need in a
                                            single case.
                    </p>
                                    </div>
                                </div>
                                <div className="info info-horizontal">
                                    <div className="icon">
                                        <i className="fa fa-map-signs" />
                                    </div>
                                    <div className="description">
                                        <h3>Clear Directions</h3>
                                        <p>
                                            Efficiently unleash cross-media information without
                                            cross-media value. Quickly maximize timely deliverables
                                            for real-time schemas.
                    </p>
                                    </div>
                                </div>
                                <div className="info info-horizontal">
                                    <div className="icon">
                                        <i className="fa fa-user-secret" />
                                    </div>
                                    <div className="description">
                                        <h3>We value your privacy</h3>
                                        <p>
                                            Completely synergize resource taxing relationships via
                                            premier niche markets.
                    </p>
                                    </div>
                                </div>
                            </Col>
                            <Col className="mr-auto" lg="6" md="6" sm="5" xs="12">
                                <Card className="card-register">                                    
                                    <CardTitle className="text-center" tag="h3">
                                        <span style={{ fontSize: 20, fontWeight: "bold" }}>
                                            <b>{statepage == 0 ? t("_iniciarsesion") : t("_crearcuenta")}</b>
                                        </span>
                                    </CardTitle>    
                                    <br />

                                    {statepage == 0 ?
                                        <div>
                                            <Form className="formlogin" onSubmit={(e) => { e.preventDefault(); Login(e) }}>
                                                <Input placeholder={t("_email")} type="text" id="email" name="email" />
                                                <Input placeholder={t("_pass")}  type="password" id="pass" name="pass" />
                                                {loginerror ? <center><span className='text-danger'><b>{t("_userpassinvalida")}</b></span></center> : ""}
                                                <br />
                                                <a style={{ textDecoration: "underline" }} href="/recovery-pass"><b>{t("_forgetpass")}</b></a>
                                                <Button block className="btn-wd" color="info" type="submit">
                                                    {t("_iniciarsesion")}
                                            </Button>
                                            </Form>
                                            <br/>
                                            <Row>
                                                <Col md="4">
                                                    <hr />
                                                </Col>                                                
                                                <Col md="5">
                                                    <span style={{ fontSize: 14, fontWeight: "bold", color: '#706b6b' }}>
                                                        <b>{t("_newclient")}</b>
                                                    </span>
                                                </Col>
                                                <Col md="3">
                                                    <hr />
                                                </Col>
                                            </Row>
                                            <Button block outline className="btn-wd" color="default" onClick={(e) => { e.preventDefault(); setLoginRegister(1); setLoginError(false) }}>
                                                {t("_crearcuenta")}
                                            </Button>
                                        </div>
                                        :
                                        <div>
                                            <Form className="formregistro" onSubmit={(e) => { e.preventDefault(); Registro(e) }}>     
                                                <Input placeholder={t("_nombre")} type="text" id="nombre" name="nombre" />
                                                <Input placeholder={t("_apellidos")} type="text" id="apellidos" name="apellidos" />
                                                <Input placeholder={t("_email")} type="text" id="email" name="email" />
                                                <Input placeholder={t("_pass")} type="password" id="pass" name="pass" />
                                                <Input placeholder={t("_confirmpass")} type="password" id="pass2" name="pass2" />                                                                                                    
                                                <b>
                                                <FormGroup check>
                                                        <Label check style={{ color: '#706b6b' }}>
                                                            <Input defaultValue="" type="checkbox" id="privacidad" name="privacidad" onClick={(e) => { setAceptaPrivacidad(!aceptapriva);}} />
                                                            {t("_aceptopolitica")} 
                                                        <span className="form-check-sign" />
                                                    </Label>
                                                    </FormGroup>
                                                </b>                                                
                                                {registroerror ? <center><span className='text-danger'><b> {t("_datosintrodunovalidos")}</b></span></center> : ""}
                                                <Button block className="btn-wd" color="info" type="submit">
                                                    {t("_crearcuenta")}
                                                </Button>
                                            </Form>
                                            <br/>
                                            <Row>
                                                <Col md="3">
                                                    <hr />
                                                </Col>
                                                <Col md="6">
                                                    <span style={{ fontSize: 14, fontWeight: "bold", color: '#706b6b'}}>
                                                        <b>{ t("_yatengocuenta")}</b>
                                                    </span>                                                
                                                </Col>
                                                <Col md="3">
                                                    <hr />
                                                </Col>
                                            </Row>
                                            <Button block outline className="btn-wd" color="default" onClick={(e) => { e.preventDefault(); setLoginRegister(0); setLoginRegister(false); setAceptaPrivacidad(false) }}>
                                                {t("_iniciarsesion")}
                                            </Button>
                                        </div>
                                        }
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                    
                    <div className="demo-footer text-center">
                        <h6>
                            <center><b>  &#xa9; Copyright {new Date().getFullYear()} Aplicaciones Turisticas en Movilidad</b></center>
                        </h6>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LoginRegistrarPage;

