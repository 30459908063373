import React, { Component } from 'react';
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// styles
import "./assets/css/bootstrap.min.css";
import "./assets/scss/paper-kit.scss";
import "./assets/demo/demo.css";
import "./assets/demo/react-demo.css";

import Home from "./view/Home.js";
import Listado from "./view/Listado.js";
import Ficha from "./view/Ficha.js";
import Producto from "./view/Producto.js";
import ListadoProductos from "./view/ListadoProductos.js";
import LoginRegistrarPage from './view/LoginRegistrar';
import RecoveryPass from './view/RecoveryPass';
import Condiciones from './view/Condiciones';
import Contacto from './view/Contacto';
import Cesta from './view/Cesta';
import Usuario from './view/Usuario';
import PedidoSuccess from './view/PedidoSuccess';
import RedireccionesQR from './view/RedireccionesQR';
import ListadoBusqueda from "./view/ListadoBusqueda.js";
import WebIFrame from "./view/WebIFrame.js";

export default class App extends Component {
  static displayName = App.name;
    
    render() {

        const hist = createBrowserHistory();
      
        return (
            <Router history={hist}>                
                <Switch>
                    <Route path="/index" render={(props) => <Home {...props} />} />
                    <Route path="/segmento" render={(props) => <Listado {...props} />} />                  
                    <Route path="/area" render={(props) => <Listado {...props} />} />
                    <Route path="/web" render={(props) => <WebIFrame {...props} />} />
                    <Route path="/promociones" render={(props) => <Listado {...props} />} />   
                    <Route path="/ficha" render={(props) => <Ficha {...props} />} />
                    <Route path="/productos" render={(props) => <ListadoProductos {...props} />} />
                    <Route path="/producto" render={(props) => <Producto {...props} />} />
                    <Route path="/login" render={(props) => <LoginRegistrarPage {...props} />} />                    
                    <Route path="/recovery-pass" render={(props) => <RecoveryPass {...props} />} />
                    <Route path="/aviso-legal" render={(props) => <Condiciones {...props} />} />
                    <Route path="/privacidad" render={(props) => <Condiciones {...props} />} />
                    <Route path="/contacto" render={(props) => <Contacto {...props} />} />
                    <Route path="/cart" render={(props) => <Cesta {...props} />} />
                    <Route path="/usuario" render={(props) => <Usuario {...props} />} />
                    <Route path="/pedidosuccess" render={(props) => <PedidoSuccess {...props} />} />
                    <Route path="/buscando" render={(props) => <ListadoBusqueda {...props} />} />
                    <Route path="/index.php" render={(props) => <RedireccionesQR {...props} />} />                    
                    <Redirect from="/" to="/index" />                   
                </Switch>
            </Router>
        );
    }    
}
//https://localhost:5001/index.php/masinfoatm/?ruta-ecuestre-lozoyuelanavassieteiglesias-ie058&id=50303
