import React from "react";
// reactstrap components
import {
  
} from "reactstrap";

import OthelloModalBase from "../componentsothello/OthelloModalBase";

function OthelloMensaje({...props })
{
    //variables de las propiedas recibidas
    const { open, titulo, mensaje, setModal} = props;
  
    return (
        <>     
            <OthelloModalBase open={open} titulo={titulo} setModal={setModal}>
                <span style={{ fontSize: 15 }}>
                    <b>{mensaje != null && mensaje != "" ? mensaje : ""}</b>
                </span> 
            </OthelloModalBase>                
        </>
    );
}

export default OthelloMensaje;