import ga4 from 'react-ga4';

//const TRACKING_ID = 'G-XXXXXXXXX-X'
const isProduction = true;//process.env.NODE_ENV === 'production'

export const init = (IDTRACK) => ga4.initialize(IDTRACK, {
    testMode: !isProduction
})

export const sendEvent = (name: string) => ga4.event('screen_view', {
    app_name: "myApp",
    screen_name: name,
})

export const sendPageview = (path: string) => ga4.send({
    hitType: 'pageview',
    page: path
})