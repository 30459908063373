import React from "react";


// reactstrap components
import {
    Card,
    Carousel,
    CarouselItem,
    CarouselIndicators,
    CarouselCaption
} from "reactstrap";

import { TipoCarrusel } from "../othelloConstantes.jsx";

import { GetCarouselItemsPortada } from "../othelloFunciones.jsx";

import { useTranslation } from 'react-i18next';


function OthelloCarrusel({ ...props })
{
    const { t } = useTranslation();

    //variables de las propiedas recibidas
    const { carouselItems, tipo } = props;   

    // carousel states and functions
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const onExiting = () => {
        setAnimating(true);
    };
    const onExited = () => {
        setAnimating(false);
    };
    const next = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === carouselItems.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === 0 ? carouselItems.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    //const resizeListener = () => {
    //    // prevent execution of previous setTimeout
    //    clearTimeout(timeoutId);
    //    // change width from the state object after 150 milliseconds
    //    timeoutId = setTimeout(() => setWidth(getWidth()), 150);
    //};

    //// set resize listener
    //window.addEventListener('resize', resizeListener);

    return(
    <> 
            <div className="ml-auto mr-auto" id={tipo != null && tipo == TipoCarrusel.Portada ? "_" : "carousel"}>                
                <div className="filter" />
                {/* ESTO EN PRINCIIO SOLO CCOULTA<Card className="page-carousel"> */}
                <div className="page-carousel">
                    <Carousel
                        activeIndex={activeIndex}
                        next={next}
                        previous={previous}                        
                    >
                        {carouselItems != null && carouselItems.length > 1 ?
                            <CarouselIndicators
                                items={tipo != null && tipo == TipoCarrusel.Portada ? GetCarouselItemsPortada(carouselItems, t) : carouselItems}
                                activeIndex={activeIndex}
                                onClickHandler={goToIndex}
                            /> : <></>}
                        {
                            tipo != null && tipo == TipoCarrusel.Portada ?
                                GetCarouselItemsPortada(carouselItems, t).map((item) =>
                                {
                                    return (
                                        <CarouselItem
                                            onExiting={onExiting}
                                            onExited={onExited}
                                            key={item.src}                                            
                                        >
                                            <div
                                                className="page-header"
                                                style={{ backgroundImage: item.src }}
                                            >
                                                {//Si es la ofi de mutxamel quitamos el oscurecido las fotos
                                                    item.idofi == 376 ? "" : <div className="filter" />}
                                                {/*<div className="filter" />*/}
                                                <div className="content-center">{item.content}</div>
                                            </div>
                                        </CarouselItem>
                                    );
                                })
                                :                                
                                carouselItems.map((item) => {
                                    return (
                                        <CarouselItem
                                            onExiting={onExiting}
                                            onExited={onExited}
                                            key={item.src}
                                        >
                                            <img src={item.src} alt={item.altText}/>
                                            <CarouselCaption
                                                captionText={item.caption}
                                                captionHeader=""
                                            />
                                        </CarouselItem>
                                    );
                                })                                
                        }
                        {carouselItems != null && carouselItems.length > 1 ?
                            <>
                                <a
                                    className="left carousel-control carousel-control-prev"
                                    data-slide="prev"
                                    href="#pablo"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        previous();
                                    }}
                                    role="button"
                                >
                                    <span className="fa fa-angle-left" />
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a
                                    className="right carousel-control carousel-control-next"
                                    data-slide="next"
                                    href="#pablo"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        next();
                                    }}
                                    role="button"
                                >
                                    <span className="fa fa-angle-right" />
                                    <span className="sr-only">Next</span>
                                </a></> : ""
                        }
                    </Carousel>
                    {/* </Card>*/}
                </div>
            </div>
    </>
  );
}

export default OthelloCarrusel;
