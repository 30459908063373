import React from "react";

import { useTranslation } from 'react-i18next';

import CookieConsent from "react-cookie-consent";


export default function OthelloCookieConsent({ ...props } )
{
    const { t } = useTranslation();

    const { oficina } = props;

    let cookiename = oficina != null ? oficina.id_Oficina + "_consent" : "0_consent";
    let forecolor = oficina != null && oficina.foreColor != null && oficina.foreColor != "" ? oficina.foreColor : "#ffffff";
    let backcolor = oficina != null && oficina.backColor != null && oficina.backColor != "" ? oficina.backColor : "#2983eb";

    return (<CookieConsent location="bottom" cookieName={cookiename} expires={999} overlay style={{ background: "#2B373B" }}
        buttonStyle={{ color: forecolor, fontSize: "13px", background: backcolor}} buttonText="Aceptar">
        {t("_consentimientocookies")}
    </CookieConsent>
    );
}
