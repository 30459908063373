import React from "react";

// reactstrap components
import {
    Button,
    Container, Row, Col,
    Card,
    CardBody,    
    CardTitle,
    CardImg,      
} from "reactstrap";

import { TipoOficina, SiNo, TipoCarrusel } from "../othelloConstantes.jsx";
import { GetBotonAccion, GetGoogleMaps, GetBadgeOpcionesProducto } from "../othelloFunciones.jsx";

// core components
import ColorNavbarATM from "../components/Navbars/ColorNavbarATM.js";
import FooterATMVenta from "../components/Footers/FooterATMVenta.js";
import FooterATM from "../components/Footers/FooterATM.js";
import OthelloCarrusel from "../componentsothello/OthelloCarrusel.js";
import ItemPromocion from "./ItemPromocion.js";

import { useTranslation } from 'react-i18next';


function Ficha()
{
    const { t, i18n} = useTranslation();

    document.documentElement.classList.remove("nav-open");

    React.useEffect(() => {
        document.body.classList.add("index-page");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
        };
    });

    const [loadingIdioma, setLoadingIdioma] = React.useState(true);

    React.useEffect(() => {

        fetch('api/Data/GetIdiomaActual')
            .then(response => response.json())
            .then(data => {
                i18n.changeLanguage(data.idioma);
                setLoadingIdioma(false);
            }).catch(error => {
                i18n.changeLanguage('es');
                setLoadingIdioma(false);
            });
    }, [loadingIdioma]);



    const [item, setItem] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        fetch('api/Data/GetFichaItem')
            .then(response => response.json())
            .then(data => {
                setItem(data);
                setLoading(false)

                //HASTA QUE NO PUEDA DARLE AL BOTON ME GUSTA SE COMENTA
                //GetMegusta();
            }).catch(error => {
                setItem(null);
                setLoading(false)
            });

    }, [loading]);

    const [megusta, setMegusta] = React.useState(null);

    function GetMegusta()
    {
        if (!loading && item != null)
        {
            var jsonData = JSON.stringify(item.id);

            fetch('api/Data/GetMeGustaItem', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: jsonData

            }).then(response => response.json()).then(data =>
            {
                if (data != null)
                    setMegusta(data);
                else
                    setMegusta(null);

            }).catch(error => {
                setMegusta(null);
            });
        }
    }

    //let imagen = !loading && item != null && item.imagen != null ? item.imagen : require("../assets/img/image_placeholder.jpg");

    const categorias = !loading && item != null && item.categorias != null && item.categorias.length > 0 ?
        item.categorias.map(x =>
        {
            let imagenfam = x.imagen != null ? x.imagen : require("../assets/img/image_placeholder.jpg");
            //let urlclick = "/productos/" + item.nombre.replace(/\s/g, "-").toLowerCase() + "/" + x.nombre.replace(/\s/g, "-").toLowerCase() + "/" + item.id;

            return <Col md="3">
                <Card
                    data-background="image"
                    style={{ backgroundImage: "url(" + imagenfam + ")", }}>
                    <a href={x.linkpoicategoria} >
                    <CardBody style={{ textAlign: "center" }}>
                        <p style={{ marginTop: 80 }}><h5><b>{x.nombre.toUpperCase()}</b></h5></p>
                    </CardBody>
                    </a>
                </Card>
            </Col>
        }) : "";

    const destacados = !loading && item != null && item.categorias != null && item.categorias.length > 0 ?
        item.categorias.map(x =>
        {
            if (x.destacados != null && x.destacados.length > 0)
            {
                let itemsdestacdos = x.destacados.map(y =>
                {
                    let imagenfam = y.imagen != null && y.imagen != "" ? y.imagen : require("../assets/img/image_placeholder.jpg");
                    //let urlclick = "/producto/" + item.nombre.replace(/\s/g, "-").toLowerCase() + "/" + y.nombre.replace(/\s/g, "-").toLowerCase() + "/" + y.id;
                   
                    return <Col md="4">
                        <Card className="card-profile card-plain">
                            <a href={y.linkproducto}>                                
                                <CardImg top tag="div">
                                    {GetBadgeOpcionesProducto(y.verstock, y.enpromocion, y.enviogratis, true, y.cantidad)}                                   
                                    <img alt="..." className="img" src={imagenfam}/>
                                </CardImg>
                                <CardBody>
                                    <CardTitle tag="h4"><b>{y.nombre}</b></CardTitle>                                    
                                    <p className="card-description2">
                                        <strong><color style={{ color: '#fc030f' }}>{y.precio}</color></strong>   <color style={{ color: '#5c5e61', textDecoration: "line-through" }}>{y.precioold}</color>                                     
                                    </p>
                                </CardBody>
                            </a>
                        </Card>
                    </Col>
                });
                
               // let urlclickfam = "/productos/" + item.nombre.replace(/\s/g, "-").toLowerCase() + "/" + x.nombre.replace(/\s/g, "-").toLowerCase() + "/" + item.id;
                
                return <Row>
                        <Col md="3">
                            <Card className="card-profile card-plain">
                                <div >
                                    <div style={{ backgroundColor: '#fc030f', height: 6, width: 50 }} />
                                    <div className="space-top" />
                                <CardTitle tag="h3" style={{ textAlign: "left" }}><a href={x.linkpoicategoria}><b>{x.nombre}</b></a></CardTitle>
                                </div>
                            </Card>
                    </Col>
                    <Col md="9">
                        <Row>
                            {itemsdestacdos}
                        </Row>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="6">
                                <center>                                    
                                    <Button color="danger" size="lg" href={x.linkpoicategoria}>
                                        {t("_vermas")} {x.nombre}
                                    </Button> 
                                </center>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }
            else
                return "";           
        }) : "";

    const accionespoi = !loading && item != null && item.acciones != null && item.acciones.length > 0 ?
        item.acciones.map(x =>
        {
            return GetBotonAccion(item.tipoofi, x.activityType, x.label, x.uri);
        }) : "";


    const promocionespoi = !loading && item != null && item.promociones != null && item.promociones.length > 0 ?
        item.promociones.map(x =>
        {            
            return <Col className="ml-auto mr-auto" md="4"><ItemPromocion promo={x} desdepoi={true} /></Col>       
        }) : "";

    //<img
    //    alt="..."
    //    className="img"
    //    src={imagen}
    ///>
    const descripfinal = <p className="card-description2">{!loading && item != null && item.descripcionlarga != null && item.descripcionlarga != "" ? item.descripcionlarga.split("\n").map((i, key) => { return <div key={key}><b>{i}</b></div>; }) : ""}</p>;

    const dataficha = <Row>
        <Col className="ml-auto" md="6">
            <div style={{ marginTop: 10 }} />            
            <OthelloCarrusel tipo={TipoCarrusel.Simple} carouselItems={!loading && item != null && item.carruselitems != null ? item.carruselitems : []} />
            <Card className="card-plain" style={{ marginLeft:10 }}>               
                <CardBody>
                    {!loading && item != null && item.direccion != null && item.direccion != "" ?
                        <p style={{ color: /*item.tipoofi == TipoOficina.Venta &&*/ item.ventaactiva  == SiNo.Si? '#FFFFFF' : '#000000', fontSize: 16 }}><i className="fa fa-map-marker"></i><b>   {item.direccion}</b></p> : ""
                    }
                    {!loading && item != null && ((item.tipoentrega != null && item.tipoentrega != "") || (item.franjaprecio != null && item.franjaprecio != "")) ?
                        <Row>
                            <Col className="ml-auto" md="7">
                                {item.tipoentrega != null && item.tipoentrega != "" ? <p style={{ color: item != null && /*item.tipoofi == TipoOficina.Venta &&*/ item.ventaactiva == SiNo.Si? '#FFFFFF' : '#000000', fontSize: 16 }}><i className="nc-icon nc-bag-16" /><b>  {item.tipoentrega}</b> </p> : ""}
                            </Col>
                            <Col className="ml-auto" md="5">
                                {item.franjaprecio != null && item.franjaprecio ? <p style={{ color: item != null && /*item.tipoofi == TipoOficina.Venta &&*/ item.ventaactiva == SiNo.Si? '#FFFFFF' : '#000000', fontSize: 16 }}><i class="fa fa-eur" aria-hidden="true"></i><b> {item.franjaprecio}</b></p> : ""}
                            </Col>
                        </Row> : ""
                    }
                </CardBody>
            </Card>
        </Col>
        <Col className="mr-auto" md="6">
            <Card className="card-plain">
                <CardBody>
                    <h6 className="card-category">{!loading && item != null && /*item.tipoofi == TipoOficina.Venta*/ item.ventaactiva == SiNo.Si? item.nombrearea : ""}</h6>                   
                    <CardTitle tag="h3">
                        {
                            !loading && item != null ? (<b>{/*item.tipoofi == TipoOficina.Venta*/ item.ventaactiva == SiNo.Si? "Bienvenidos a " : ""}{item.nombre}</b>) : ""
                        }
                    </CardTitle>
                    <div className="pull-right">
                        {!loading && item != null && megusta != null ? <color className="text-danger"><i class="fa fa-heart-o" aria-hidden="true" /> <b>{megusta.total} me gusta</b></color> : ""}
                    </div>  
                    {!loading && item != null && megusta != null ? <br /> : ""}
                    {!loading && item != null &&/* item.tipoofi != TipoOficina.Venta*/ item.ventaactiva == SiNo.Si ? descripfinal : ""}
                    
                    {!loading && item != null &&/* item.tipoofi != TipoOficina.Venta*/ item.ventaactiva == SiNo.Si? <br /> : ""}
                    {!loading && item != null && /*item.tipoofi != TipoOficina.Venta*/ item.ventaactiva == SiNo.Si? <hr /> : ""}
                    {!loading && item != null && /*item.tipoofi != TipoOficina.Venta*/ item.ventaactiva == SiNo.Si? <div className="space-top" /> : ""}
                    <br />
                    {!loading && item.lat != null && item.lon != null && item.lat != 0 && item.lon != 0 ?
                        <Button style={{ margin: 5 }} className="btn-round" color={"default"} href={"https://maps.google.com?saddr=Current+Location&daddr=" + item.lat + "," + item.lon} target='_blank'><i class="fa fa-map-marker" aria-hidden="true" /> {t("_visitar")}</Button>
                        : ""}                    
                    {accionespoi}
                    {!loading && item != null &&/* item.tipoofi != TipoOficina.Venta*/ item.ventaactiva == SiNo.No ? descripfinal : ""}
                </CardBody>
            </Card>
        </Col>
    </Row>   
        
    return (
      <>
          <ColorNavbarATM />
          <div className="project-2 section section-dark">
              <Container>                  
                  <Row>
                        <Col className="ml-auto mr-auto text-center" md="10" style={{ paddingTop: 100 }}>
                            {/*<h1 className="title"><b>{!loading && item != null ? item.nombre.split("\n").map((i, key) => { return <div key={key}>{i}</div>; }) : "-"}</b></h1>*/}
                            <h1 className="title"><b>{!loading && item != null ?  item.nombre : "-"}</b></h1>
                            <h5 className="description">
                                <b>
                                    {//SI TENEMOS DE UN TIPO DISTINTO A VENTA PONEMOS AL AREA AL QUE PERTENECE ARRIBA
                                     !loading && item != null && item.tipoofi != TipoOficina.Venta ? item.nombrearea : ""}
                                </b>
                            </h5> 
                      </Col>
                  </Row>
                    <div className="space-top" />                    
                    {   //SI TENEMOS DE TIPO VENTA PONEMOS LA INFORMACION DENTRO DE LA ZONA OSCURA
                        !loading && item != null && /*item.tipoofi == TipoOficina.Venta &&*/ item.ventaactiva == SiNo.Si? dataficha : ""
                    }
                    {!loading && categorias != "" ? <hr /> : ""}
                    <div className="space-top" />    
                    {!loading && categorias != "" ? <Row>{categorias}</Row> : ""}                                                                                                                                      
              </Container>  
            </div>        
            {   //SI TENEMOS DE DISTINTO DE TIPO VENTA PONEMOS LA INFORMACION EN LA ZONA BALNCA
                !loading && item != null && (/*item.tipoofi != TipoOficina.Venta ||*/ item.ventaactiva == SiNo.No)?
                    <div className="section">
                        <Container>
                            {dataficha}
                        </Container>
                    </div>  : ""
            }
            {
                destacados != "" ?
                <div className="section">
                    <Container>
                        {destacados}
                    </Container>
                </div> : ""
            }
            {promocionespoi != "" ?
                <div className="pricing-5 section-dark">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="8">
                                <h2 className="title"><b>{t("_promociones")}</b></h2>
                                <hr />
                                <h5 className="description"><b>{t("_nuetraspromos")}</b></h5>
                            </Col>                            
                    </Row>
                    <div className="space-top" />                                        
                    <Row>{promocionespoi}</Row>                    
                </Container>
            </div> : ""
            }
            {!loading && item.lat != null && item.lon != null && item.lat != 0 && item.lon != 0 ?
                <div className="pricing-5 section-gray">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="12">
                                <h2 className="title"><b>{t("_venaconcernos")}</b></h2>
                                <br />
                            </Col>
                        </Row>
                    </Container>
                    <div className="big-map" id="contactUsMap2" style={{ backgroundColor: '#cccccc', height: 500 }}>
                        {GetGoogleMaps(item.lat, item.lon)}
                    </div>
                </div>  : ""
            }
            
            {!loading && item != null && item.tipoofi == TipoOficina.Venta ? <FooterATMVenta /> : <FooterATM />}                 
    </>
  );
}

export default Ficha;
