import React from "react";

//import Select from "react-select";

// reactstrap components
import {
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown

} from "reactstrap";

import { useTranslation } from 'react-i18next';

function OthelloComboBoxIdiomas({...props })
{
    const { t } = useTranslation();

    //variables de las propiedas recibidas
    const { onIdiomaChanged } = props;

    const [loading, setLoading] = React.useState(true);
    const [defSelect, setDefSelect] = React.useState(null);
    const [idiomas, setIdiomas] = React.useState(null);


    //Hook de efectos para la obtencion de las regiones que forman el menu
    React.useEffect(() => {
        fetch('api/Data/GetSeleccionIdiomas')
            .then(response => response.json())
            .then(data =>
            {
                setIdiomas(data.idiomas);
                setDefSelect(data.isoselected)
                setLoading(false)            
            }).catch(error => {                
                setLoading(false)
            });

    }, [loading]);
   

    const definiciones = idiomas != null && idiomas.length > 1 ? idiomas.map(x => { return { value: x.idioma } }) : "";

    let flagselected = !loading && defSelect != null ? require("../assets/img/flags/" + defSelect.toUpperCase() + ".png") : "";

    function ChangeIdioma(value) {
        var jsonData = JSON.stringify(value.value);

        fetch('api/Data/CambioIdioma', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: jsonData

        }).then(response => response.json()).then(res => {
            if (res) {
               

                if (onIdiomaChanged != null)
                    onIdiomaChanged();

                setDefSelect(value.value);
            }
        }).catch(error => {

        });
    }

    return (
        <>
            {definiciones != "" && !loading ?
                <div style={{
                    //position: "absolute", backgroundColor: '#ffffff', width: 150, height: 40,
                    //zIndex: 1000000,
                    //right: 0,
                    //top: 0,
                    //marginTop: 5,
                    //marginRight: 10,
                    //fontSize: 12,
                    //fontWeight: 'bold'
                }}>
                    <UncontrolledDropdown size="sm" style={{
                        position: "absolute", zIndex: 121000000, right: 0, top: 0,
                        marginTop: -10,
                        marginRight: 15,                                                  
                    }}>

                        <DropdownToggle caret>
                            {/*<img src={flagselected} />*/}  {t(defSelect)}
                        </DropdownToggle>
                        <DropdownMenu style={{ backgroundColor: '#ffffff' }}>
                            {

                                definiciones.map(x => {

                                    let back = x.value == defSelect ? 'grey' : 'darkgrey';
                                    let flag = require("../assets/img/flags/" + x.value.toUpperCase() + ".png")
                                    return <DropdownItem style={{ backgroundColor: back, color: 'white' }} onClick={() => ChangeIdioma(x)}>{/*<img src={flag} />*/}  {t(x.value)}</DropdownItem>;
                                })
                            }                            
                        </DropdownMenu>
                    </UncontrolledDropdown>


                   
                </div> : ""}
        </>
    );
}

export default OthelloComboBoxIdiomas;



//OTRA VERSION CON REACT SELECT
//<FormGroup>
//    <Select
//        style={{ height: 30 }}
//        classNamePrefix="react-select"
//        name="defSelect"
//        value={defSelect}
//        options={definiciones}
//        onChange={(value) => ChangeIdioma(value)}
//        placeholder="idioma"
//        isSearchable={false}
//    />
//</FormGroup>