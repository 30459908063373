import React from "react";
// reactstrap components
import {
    Button,
    Container, Row, Col,
    NavItem, NavLink, Nav,
    TabContent,
    TabPane,
    Input,
    Form
} from "reactstrap";

import OthelloModalBase from "../componentsothello/OthelloModalBase";
import { GetEstadoString } from "../othelloFunciones";

import { useTranslation } from 'react-i18next';

function PedidoModal({...props })
{

    const { t } = useTranslation();

    //variables de las propiedas recibidas
    const { open, setModal, id} = props;

    const [pedido, setPedido] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {

        var jsonData = JSON.stringify(id);

        fetch('api/Data/GetPedido', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: jsonData

        }).then(response => response.json()).then(data => {
            setPedido(data);
            setLoading(false)
        }).catch(error => {
            setPedido(null);
            setLoading(false)
        });
    }, [loading]);


    let lineas = !loading && pedido != null ? pedido.lineas.map(x => {

        return <div>
            <Row>
                <Col >
                    <span style={{ fontSize: 13 }}><b>{x.nomArticulo}</b></span>
                </Col>
                <div style={{ width: 15, fontSize: 13, textAlign: 'right', alignSelf: 'stretch', marginRight: 2 }}>
                    <b>{x.cantidad}</b>
                </div>
                <div style={{ width: 65, fontSize: 13, textAlign: 'right', alignSelf: 'stretch', marginRight: 2 }}>
                    <b>{x.precio.toFixed(2)} &euro;</b>
                </div>  
                <div style={{ width: 65, fontSize: 13, textAlign: 'right', alignSelf: 'stretch', marginRight: 10 }}>
                    <b>{(x.cantidad * x.precio).toFixed(2)} &euro;</b>
                </div>
            </Row>                  
        </div>
    }) : "";

    return (
        <>
            <OthelloModalBase open={open} titulo="Informacion del pedido" setModal={setModal}>
                <span style={{ fontSize: 15 }}>
                    {!loading && pedido != null ?
                        <div>
                            <Container>
                                <div>
                                    <Row style={{ backgroundColor: '#eeeeee', height: 40, paddingTop: 10 }}>
                                        <Col md="12">
                                            <span style={{ fontSize: 15 }}><b> {t("_numpedido")}: {pedido.id_Pedido} | {GetEstadoString(pedido.estado)}</b></span>
                                        </Col>                                
                                    </Row>
                                    <br/>
                                    <Row style={{ height: 30 }} >
                                        <Col md="12">
                                            <span style={{ fontSize: 14, fontWeight: "bold" }}>
                                                <b> {pedido.nombreApellidos}</b>
                                            </span>                                            
                                        </Col>
                                    </Row>                                   
                                    {lineas}                                        
                                    <hr />
                                    <Row>
                                        <Col md="4">
                                            <span style={{ fontSize: 15 }}><b>{t("_total")}: </b></span>
                                        </Col>
                                        <Col md="8">
                                            <span style={{ fontSize: 15, fontWeight: "bold" }} className="pull-right" ><b>{pedido.total.toFixed(2)} &euro;</b></span>
                                        </Col>
                                    </Row>                        
                                </div><br />
                            </Container></div> : ""}                   
                </span>
            </OthelloModalBase>                  
        </>
    );
}

export default PedidoModal;