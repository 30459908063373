import React from "react";
// reactstrap components
import {
    Button,
    Form,
    Input,
    Modal,
    Container,   
    Row,
    Col
} from "reactstrap";

import { useTranslation } from 'react-i18next';

function DireccionSeleccionModal({...props })
{
    const { t } = useTranslation();

    //variables de las propiedas recibidas
    const { open, setModal, setselected} = props;

    const [direcciones, setDirecciones] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {

        fetch('api/Data/GetDirecciones')
            .then(response => response.json())
            .then(data => {
                
                setDirecciones(data);
                setLoading(false)
            }).catch(error => {
                setDirecciones(null);
                setLoading(false)
            });
       
    }, [loading]);

    
    function SeleccionDireccion(id_direccion)
    {       
        setselected(id_direccion);
        setModal(false);
    }

    const infodirec = direcciones != null && direcciones.length > 0 ?

        direcciones.map(x => {
            return <div >
                <Row>                    
                    <Col md="9">                  
                            <span style={{ fontSize: 13, fontWeight: "bold" }}><b>{x.alias}</b></span>
                            <p><b>{x.nombre} {x.apellidos}</b></p>
                            <p><b>{x.direccion}</b></p>
                            <p><b>{x.codPostal} {x.poblacion} ({x.provincia}). {x.pais}</b></p>                                
                    </Col>
                    <Col md="3">
                        <div className="pull-right">
                            <Button className="btn-wd" color="danger" size='sm' style={{ marginRight: 5 }} onClick={(e) => { e.preventDefault(); SeleccionDireccion(x.id_Direccion); }}>{t("_seleccionar")}</Button>                        
                        </div>
                    </Col>
                </Row>
                <hr />
            </div>

        }) : <div><Row><Col md="12"><div style={{ fontSize: 14 }}><b>{t("_sindirecciones")}</b></div></Col></Row><hr /></div>;


    return (
        <>           
            <Modal isOpen={open} toggle={() => setModal(false)}>    
                <div className="modal-header">
                    <span style={{ fontSize: 15, fontWeight: "bold" }}>
                        <b>{t("_seleccionardirecc")}</b>
                    </span>
                </div>
               <br/>
                <Container>                        
                        {infodirec}                        
                        <br />                       
                        <Row>
                            <Col md="12">
                                <div className="pull-right">
                                <Button className="btn-wd" color="danger" outline size='sm' style={{ marginRight: 5 }} onClick={(e) => { e.preventDefault(); setModal(false) }}>{t("_cancelar")}</Button>                                                                      
                                </div>
                            </Col>
                        </Row>
                    
                    <div outline style={{ marginRight: 5, height:25 }} />
                </Container>               
                
            </Modal>
        </>
    );
}

export default DireccionSeleccionModal;