import React from "react";
import Select from "react-select";


// reactstrap components
import {
    Button,
    Container, Row, Col,
    Card,
    CardBody,    
    CardHeader,    
    Collapse,
    FormGroup,   
    Input
 } from "reactstrap";

// core components
import ColorNavbarATMSimple from "../components/Navbars/ColorNavbarATMSimple.js";
import FooterATMVenta from "../components/Footers/FooterATMVenta.js";
import OthelloCarrusel from "../componentsothello/OthelloCarrusel.js";
import ProductoAddedModal from "./ProductoAddedModal.js";


import { GetBadgeOpcionesProducto } from "../othelloFunciones.jsx";
import { TipoCarrusel, EstadoArticulo } from "../othelloConstantes.jsx";

import { useTranslation } from 'react-i18next';

function Listado()
{
    const { t, i18n } = useTranslation();

    document.documentElement.classList.remove("nav-open");

    React.useEffect(() => {
        document.body.classList.add("index-page");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
        };
    });

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("product-page");
        return function cleanup() {
            document.body.classList.remove("product-page");
        };
    });

    const [loadingIdioma, setLoadingIdioma] = React.useState(true);

    React.useEffect(() => {

        fetch('api/Data/GetIdiomaActual')
            .then(response => response.json())
            .then(data => {
                i18n.changeLanguage(data.idioma);
                setLoadingIdioma(false);
            }).catch(error => {
                i18n.changeLanguage('es');
                setLoadingIdioma(false);
            });
    }, [loadingIdioma]);


    const [item, setItem] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        fetch('api/Data/GetArticuloItem')
            .then(response => response.json())
            .then(data => {

                //Si me indica que debo redirigir
                if (data.redireccion != null && data.redireccion != "")                    
                    window.location.assign(data.redireccion);
                else {

                    setItem(data);
                    setLoading(false)

                    if (data != null && data.definiciones != null && data.definiciones.length > 0)
                        setDefSelect({ value: data.definiciones[0].id, label: data.definiciones[0].descripcion, precio: data.definiciones[0].precio, cantidad: data.definiciones[0].cantidad })
                }

            }).catch(error => {
                setItem(null);
                setLoading(false)
            });

    }, [loading]);


    // collapse states and functions
    const [collapseOne, setCollapseOne] = React.useState(true);
    const [collapseTwo, setCollapseTwo] = React.useState(false);


    // react-select states
    const [defSelect, setDefSelect] = React.useState({
        value: "-1",
        label: "",
        precio: "",
        cantidad : ""
    });

    const [added, setAdded] = React.useState(0);
    
    const [errorAddProducto, setErrorAddProducto] = React.useState(0);

    const [cantidadprod, setCantidadProd] = React.useState(1);

    const [addedProductoModal, setAddedProductoModal] = React.useState(-1);

    function AddProducto(valor)        
    {        
        if (valor != null)
        {
            var jsonData = JSON.stringify({ idoficina: valor.idoficina, idarticulo: valor.id, idartdef: defSelect.value == -1 ? 0 : defSelect.value, cantidad: cantidadprod });

            fetch('api/Data/AddArticulo', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: jsonData

            }).then(response => response.json()).then(data =>
            {
                //si es mayor que cero, es que me devolvio el id_line
                if (data>0)
                {
                    setAdded(added + 1);
                    setErrorAddProducto(0);
                    setAddedProductoModal(data)
                }
                else
                    setErrorAddProducto(data);

            }).catch(error => {
                setErrorAddProducto(-1);
            });
        }
    }

    function CantidadArticulo(opc)
    {
        if (opc < 0) {
            if (cantidadprod > 1)
                setCantidadProd(cantidadprod+ opc)
        }
        else
            setCantidadProd(cantidadprod + opc)
    }

    const definiciones = !loading && item != null && item.definiciones != null && item.definiciones.length > 0 ? item.definiciones.map(x => { return { value: x.id, label: x.descripcion, precio: x.precio, cantidad: x.cantidad }; }) : "";
    
    return (
        <>
            <ColorNavbarATMSimple color='danger' items={added} />
          <div className="projects-3" id="projects-3">
                {addedProductoModal > 0 ? <ProductoAddedModal open={addedProductoModal > 0} setModal={setAddedProductoModal} id_linea={addedProductoModal} /> : ""}
          <div className="main">
              <div className="section">
                    <Container>
                      <Row className="title-row">
                                <Col md="10">
                                    <h5>{!loading && item != null ? <span><a href={item.linkpoi}><b style={{ color: '#000000' }}>{item.nombrepoi}</b></a> - <a href={item.linkpoicategoria}><b style={{ color: '#000000' }}>{item.nombrecategoria}</b></a></span> : ""}</h5>
                                </Col>                          
                      </Row>
                      <Row>
                          <Col md="5" sm="6">
                                <OthelloCarrusel tipo={TipoCarrusel.Simple} carouselItems={!loading && item != null ? item.carruselitems : []} />                              
                          </Col>
                          <Col md="7" sm="6">
                                  <h2><b>{!loading && item != null ? item.nombre : ""}</b></h2>
                                    <h5 className="price">
                                        {!loading && definiciones != null && definiciones != "" && defSelect.value != -1 ?
                                            <strong>
                                                <color style={{ color: '#fc030f' }}>{defSelect.precio}</color>
                                            </strong>
                                            :
                                            <div>
                                                <strong>
                                                    <color style={{ color: '#fc030f' }}>{!loading && item != null ? item.precio : ""} </color>
                                                </strong>
                                                <color style={{ color: '#5c5e61', textDecoration: "line-through" }}>{!loading && item != null ? item.precioold : ""}</color>
                                            </div>
                                        }
                                  </h5>
                                    <hr />
                                    {!loading && item != null ? GetBadgeOpcionesProducto(item.verstock, item.enpromocion, item.enviogratis, false, !loading && definiciones != null && definiciones != "" && defSelect.value != -1 ? defSelect.cantidad : item.cantidad) : ""}
                                 
                              <p><b>
                                        {!loading && item != null && item.descripcion != null && item.descripcion != "" ? item.descripcion.split("\n").map((i, key) => { return <div key={key}>{i}</div>; })  : ""}
                                </b>
                             </p>
                                    {/*  <span className="label label-default shipping">
                                     <b> Free shipping to Europe</b>
                                    </span>*/}
                                    {!loading && definiciones != null && definiciones != "" ? <br /> : ""}
                                    {
                                        !loading && item != null ?
                                            <Row style={{ backgroundColor: item.estado == EstadoArticulo.EnStock ? '#d6eed1' : '#f8d7da', margin: 1, padding: 3, fontWeight: "bold" }}>
                                                <Col lg="12">
                                                    <b>{item.estado == EstadoArticulo.EnStock ? t("_enstock") : t("_agotado")}</b>
                                                </Col>
                                            </Row> : ""
                                    }    
                                    <Row>                                            
                                        {!loading && definiciones != null && definiciones != "" ?
                                            <Col md="6" sm="6">
                                                <br />
                                                <label><b>{t("_selecciona")} </b></label>
                                              <FormGroup>
                                                  <Select
                                                        className="react-select react-select-default"
                                                        classNamePrefix="react-select"
                                                        name="defSelect"
                                                        value={defSelect}
                                                        onChange={(value) => setDefSelect(value)}
                                                        options={definiciones}
                                                        placeholder="COLOR"
                                                        isSearchable={false}
                                                  />
                                              </FormGroup>
                                            </Col> : ""}
                                        <Col md="6" sm="6" style={{ marginTop: (!loading && definiciones != null && definiciones != "" ? 22 : 15) }}>
                                            <Row><Col><b>{t("_cantidad")}</b></Col></Row>
                                            <Row style={{ marginTop: 5 }} >
                                                <Col>
                                                    <a style={{ float: 'left', background: '#eeeeee', borderRadius: '3px', width: 20, height: 40, marginRight: 1 }} onClick={(e) => { e.preventDefault(); CantidadArticulo(-1); }}>
                                                        <center style={{ marginTop: 10 }}><i className="nc-icon nc-simple-delete" /></center>
                                                    </a>
                                                    <Input
                                                        id="nombre2" name='nombre2'
                                                        style={{ float: 'left', width: 32, background: '#ffffff' }}
                                                        value={cantidadprod}
                                                        placeholder=""
                                                        disabled
                                                        type="text" />
                                                    <a style={{ float: 'left', background: '#eeeeee', borderRadius: '3px', width: 20, height: 40, marginLeft: 1 }} onClick={(e) => { e.preventDefault(); CantidadArticulo(1); }}>
                                                        <center style={{ marginTop: 10, }}><i className="nc-icon nc-simple-add" /></center>
                                                    </a>
                                                </Col>
                                            </Row>
                                        </Col>
                                      </Row>                                   
                                    <hr />                                    
                                    {
                                        !loading && errorAddProducto<0?
                                            <Row style={{ backgroundColor: '#f8d7da', margin: 1, padding: 5, marginBottom : 5 }}>
                                                <Col lg="12">
                                                    <b>{errorAddProducto == -2 ? t("_noaddcarocombinada") : t("_noaddcaro")}</b>
                                                </Col>
                                            </Row> : ""
                                    }
                                    <Row>
                                        <Col className="offset-md-5" md="7" sm="8">
                                                <Button block className="btn-round" color={!loading && item != null && item.estado != EstadoArticulo.EnStock ? "default" : "danger"}
                                                    disabled={!loading && item != null && item.estado != EstadoArticulo.EnStock ? true : false}
                                                    onClick={(e) => { e.preventDefault(); AddProducto(item); }}
                                                >
                                                {t("_addcarro")} 
                                            </Button>
                                        </Col>
                                    </Row>
                                    
                          </Col>
                      </Row>
                    </Container>
                    <Container>
                    <div className="faq">
                        <div id="acordeon">
                            <div aria-multiselectable={true} id="accordion" role="tablist">
                                <Card className="no-transition">
                                    <CardHeader
                                        className="card-collapse"
                                        id="headingOne"
                                        role="tab"
                                    >
                                        <h5 className="mb-0 panel-title">
                                            <a
                                                aria-expanded={collapseOne}
                                                href="#pablo"
                                                id="collapseOne"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setCollapseOne(!collapseOne);
                                                }}
                                            >
                                                <b>{t("_descripcion")} </b>{" "}
                                                <i className="nc-icon nc-minimal-down" />
                                            </a>
                                        </h5>
                                    </CardHeader>
                                    <Collapse 
                                        isOpen={collapseOne}
                                        aria-labelledby="headingOne"
                                        role="tabpanel"
                                        toggler="#collapseOne"
                                    >
                                        <CardBody>
                                                    <b>{!loading && item != null && item.descripcionlarga != null && item.descripcionlarga != "" ? item.descripcionlarga.split("\n").map((i, key) => { return <div key={key}>{i}</div>; }) : ""}</b>
                                        </CardBody>
                                            </Collapse>
                                    <CardHeader
                                        className="card-collapse"
                                        id="headingTwo"
                                        role="tab"
                                    >
                                        <h5 className="mb-0 panel-title">
                                            <a
                                                aria-expanded={collapseTwo}
                                                href="#pablo"
                                                id="collapseTwo"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setCollapseTwo(!collapseTwo);
                                                }}>
                                                <b>{t("_valoraciones")}</b>{" "}
                                                <i className="nc-icon nc-minimal-down" />
                                            </a>
                                        </h5>
                                    </CardHeader>
                                    <Collapse
                                                isOpen={collapseTwo}
                                        aria-labelledby="headingTwo"
                                        role="tabpanel"
                                        toggler="#collapseTwo"
                                    >
                                        <CardBody>
                                                    <div><Row><Col md="12"><div style={{ fontSize: 14 }}><b>{t("_opcnodispo")}</b></div></Col></Row><hr /></div> 
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </div>
                            {/* end acordeon */}
                        </div>
                    </div>
                            {/*
                            <Row className="add-row">
                              <Col md="4" sm="4">
                                  <h4><b>Comprados juntos</b></h4>
                        </Col>
                              <Col md="4" sm="4">
                                  <h5><b>Patatas Fritas</b></h5>
                            <p className="price">
                                      <strong><color style={{ color: '#fc030f' }}>5.99 Euros</color></strong>
                            </p>
                            <p>
                                Constructed from robust full-grain leather, it's finished with
                                the label's 'city webbing' - a rich take on the brand's
                                signature stripes.
                            </p>
                            <br />
                            <Button className="btn-round" color="danger">
                               A�adir al Carrito
                            </Button>
                        </Col>
                        <Col className="ml-auto" md="3" sm="4">
                            <img
                                alt="..."
                                className="img-rounded img-responsive"
                                src={require("../assets/img/gallery/paul-smith.jpg")}
                            />
                        </Col>
                        </Row>*/}
                        </Container>
                    <Container>
                        <br />
                            <Row style={{  height: 35 }}>
                                <Col className="description-section" md="12">
                                    <span style={{ fontSize: 15, fontWeight: "bold" }}>
                                        <b>{t("_infoaddproducto")}</b>
                                </span>
                            </Col>
                        </Row>
                            <span style={{ fontSize: 15 }}>
                                <b>{!loading && item != null && item.infoadicional != null && item.infoadicional != "" ? item.infoadicional.split("\n").map((i, key) => { return <div key={key}>{i}</div>; }) :  t("_sininfoaddproducto") }</b>
                        </span>
                        </Container>
                    </div>
            {/*
              <div className="section section-dark">
                  <Container>
                      <Row>
                              <Col md="12">
                                  <h4><b>Products Relacionados</b></h4>
                              <br />
                          </Col>
                          <Col md="4" sm="4">
                              <Card className="card-product card-plain">
                                  <div className="card-image">
                                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                          <img
                                              alt="..."
                                              className="img-rounded img-responsive"
                                              src={require("../assets/img/balmain-1.jpg")}
                                          />
                                      </a>
                                      <CardBody>
                                          <div className="card-description">
                                              <CardTitle tag="h5">
                                                  Double Breasted Mini Dress
                        </CardTitle>
                                              <p className="card-description">Dresses &amp; Skirts</p>
                                          </div>
                                          <div className="actions">
                                              <h5 className="text-white">1.300 �</h5>
                                          </div>
                                      </CardBody>
                                  </div>
                              </Card>
                          </Col>
                          <Col md="4" sm="4">
                              <Card className="card-product card-plain">
                                  <div className="card-image">
                                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                          <img
                                              alt="..."
                                              className="img-rounded img-responsive"
                                              src={require("../assets/img/balmain-2.jpg")}
                                          />
                                      </a>
                                      <CardBody>
                                          <div className="card-description">
                                              <CardTitle tag="h5">Chrystal Dress</CardTitle>
                                              <p className="card-description">Dresses &amp; Skirts</p>
                                          </div>
                                          <div className="actions">
                                              <h5 className="text-white">1.500 �</h5>
                                          </div>
                                      </CardBody>
                                  </div>
                              </Card>
                          </Col>
                          <Col md="4" sm="4">
                              <Card className="card-product card-plain">
                                  <div className="card-image">
                                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                          <img
                                              alt="..."
                                              className="img-rounded img-responsive"
                                              src={require("../assets/img/balmain-3.jpg")}
                                          />
                                      </a>
                                      <CardBody>
                                          <div className="card-description">
                                              <CardTitle tag="h5">Chrystal Skirt</CardTitle>
                                              <p className="card-description">Only on demand</p>
                                          </div>
                                          <div className="actions">
                                              <h5 className="text-white">1.200 �</h5>
                                          </div>
                                      </CardBody>
                                  </div>
                              </Card>
                          </Col>
                      </Row>
                  </Container>
                    </div>
                    */}
                  </div>          
            </div>
          <FooterATMVenta />
    </>
  );
}

export default Listado;
