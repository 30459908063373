import React from "react";

// reactstrap components
import {
    Button,
    Container, Row, Col,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    CardImg
} from "reactstrap";



function BodyATMConstruccion()
{
   
  return (
      <>
          <div className="wrapper">
              <div
                  className="page-header"
                  style={{
                      backgroundImage:
                          "url(" + require("../../assets/img/sections/ilya-yakover.jpg") + ")",
                  }}
              >
                  <div className="filter" />
                  <div>
                      <Container>
                      <Row>
                          <Col className="ml-auto mr-auto text-center" md="10" style={{ paddingTop:400 }}>
                              <h1 className="title"><b>En Construccion</b></h1>
                              <h5 className="description">
                                  <b>
                                      Pagina en contruccion, disculpen las molestias
                                  </b>
                              </h5>
                          </Col>
                      </Row>
                      <div className="space-top" />
                     
                      </Container>
                  </div>
              </div>                          
          </div>                
    </>
  );
}

export default BodyATMConstruccion;
