import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";

// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";

// reactstrap components
import {
    NavbarBrand,
    Navbar,   
    Container,
    UncontrolledTooltip,
} from "reactstrap";

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom'

import * as analytics from '../../ga4'

import { Helmet } from 'react-helmet'

function ColorNavbarATMVacio({ ...props })
{
    const { t } = useTranslation();
    const location = useLocation();

    const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
    const [bodyClick, setBodyClick] = React.useState(false);
    const [collapseOpen, setCollapseOpen] = React.useState(false);

    //gestiona la obtencion de regiones
    const [menu, setMenuOficina] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    //variables de las propiedas recibidas
    const { } = props;   

    //Hook de efectos para la obtencion de las regiones que forman el menu
    React.useEffect(() => {
        fetch('api/Data/GetMenuOficinaSelected')
            .then(response => response.json())
            .then(data => {
                setMenuOficina(data);
                setLoading(false);

                const favicon = document.getElementById("favicon");
                const iconoweb = data != null ? "/imagenes/favicon_" + data.idoficina + "_.ico" : "favicon_.ico";
                favicon.href = iconoweb;

                if (data != null && data.codigo_analitycs != null && data.codigo_analitycs != "") {

                    analytics.init(data.codigo_analitycs);

                    const path = location.pathname + location.search
                    analytics.sendPageview(path)
                }

            }).catch(error => {
                setMenuOficina([]);
                setLoading(false)
            });

    }, [loading]);


    const iconoweb = !loading && menu != null ? "/imagenes/logo_" + menu.idoficina + "_.png" : "/imagenes/logo_0.png";
    
    return(
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
            ) : null}
            <Helmet>
                <title>{!loading && menu != null ? (menu.titleseo != null && menu.titleseo != "" ? menu.titleseo : menu.nombre) : t('_bienvenidos')}</title>
                <meta name="description" content={!loading && menu != null ? (menu.descripcionseo != null && menu.descripcionseo != "" ? menu.descripcionseo : menu.nombre) : t('_bienvenidos')} />
                <meta property="og:locale" content="es_ES" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={!loading && menu != null ? (menu.titleseo != null && menu.titleseo != "" ? menu.titleseo : menu.nombre) : t('_bienvenidos')} />
                <meta property="og:description" content={!loading && menu != null ? (menu.descripcionseo != null && menu.descripcionseo != "" ? menu.descripcionseo : menu.nombre) : t('_bienvenidos')} />
                <meta property="og:url" content={!loading && menu != null ? menu.urlseo : ""} />
                <meta property="og:site_name" content={!loading && menu != null ? menu.sitenameseo : ""} />
                <meta name="keywords" content={!loading && menu != null ? menu.keywordsseo : ""} />
                <meta name="robots" content="index,follow" />
                <link rel="canonical" href={window.location.href} />
                <meta property="og:type" content="website" />  
                {
                    //<meta property="og:image" content="http://f...foto-37-1-1030x773.png" />
                    //<meta property="og:image:width" content="1030" />
                    //<meta property="og:image:height" content="773" />
                }          
            </Helmet>
      <Navbar
        className={classnames("fixed-top", navbarColor)}
        expand="lg"
        id="navbar-main"
      >
        <Container>
            <div className="navbar-translate">                        
            <NavbarBrand id="navbar-brand" to="/index" tag={Link}>                           
                <img src={iconoweb} height="56" alt="Logo" />
            </NavbarBrand>
            <UncontrolledTooltip placement="bottom" target="navbar-brand">
                            {!loading && menu != null ? menu.nombre : ""}
            </UncontrolledTooltip>                        
          </div>         
        </Container>
      </Navbar>
    </>
  );
}

export default ColorNavbarATMVacio;
