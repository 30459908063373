import React from "react";

// reactstrap components
import { Button,  Container, Row, Col } from "reactstrap";
import iconoandroid from "../../assets/img/google500.png"
import iconoios from "../../assets/img/apple500.png"
import PeticionAltaEstablecimiento from "../../view/PeticionAltaEstablecimiento";

import OthelloVersionWeb from "../../componentsothello/OthelloVersionWeb.jsx";

import { useTranslation } from 'react-i18next';
import OthelloCookieConsent from "../../componentsothello/OthelloCookieConsent";

function FooterVenta() {

    const { t } = useTranslation();

    //gestiona la obtencion de regiones
    const [menu, setMenuOficina] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const [oficina, setOficina] = React.useState(null);
    const [loadingofi, setLoadingOfi] = React.useState(true);

    
    const [AltaEstablecimientoModal, setAltaEstablecimientoModal] = React.useState(false);

    React.useEffect(() => {
        fetch('api/Data/GetOficinaVirtual')
            .then(response => response.json())
            .then(data => {
                setOficina(data);
                setLoadingOfi(false)
            }).catch(error => {
                setOficina(null);
                setLoadingOfi(false)
            });

    }, [loading]);


    //Hook de efectos para la obtencion de las regiones que forman el menu
    React.useEffect(() => {
        fetch('api/Data/GetMenuOficinaSelected')
            .then(response => response.json())
            .then(data => {
                setMenuOficina(data);
                setLoading(false)
            }).catch(error => {
                setMenuOficina([]);
                setLoading(false)
            });

    }, [loading]);

    const datamenu = !loading && menu != null && menu.itemsmenu != null && menu.itemsmenu.length > 0 ?
        menu.itemsmenu.map(x =>
        {
            let link = x.region == "1" ? x.linkregion : x.linkarea;

            return <li>
                <a href={link}>
                    <b> {x.nombre}</b>
                </a>
            </li>               
        }) : "";

    var tieneredes = !loadingofi && oficina != null && ((oficina.linkFacebook != null && oficina.linkFacebook != "") ||
        (oficina.linkTwitter != null && oficina.linkTwitter != "") || (oficina.linkInstagram != null && oficina.linkInstagram != ""));

    const iconoweb = !loadingofi && oficina != null ? "/imagenes/logo_" + oficina.id_Oficina + "_.png" : "/imagenes/logo_0.png";    
    const iconofondo = !loadingofi && oficina != null ? "/imagenes/footer_" + oficina.id_Oficina + "_.jpg" : "/imagenes/footerventa.jpg";


  return (
      <>
          <footer className="footer footer-big footer-black" style={{              
              backgroundImage:
                  "url(" + iconofondo + ")",
              marginTop: 80,
              paddingTop: 130
               
          }}>
              <Container>                
              <Row>
                <Col md="4" sm="4" xs="6">
                  <div className="links" style={{ fontWeight: 'bold' }}>
                              <p style={{ fontWeight: 'bold' }}> {t("_navega")}</p>
                    <ul className="stacked-links" >
                      <li>
                        <a href="/index" onClick={(e) => e.preventDefault()}>
                            <b>{t("_inicio")}</b>
                        </a>
                      </li>
                       {datamenu}                
                    </ul>
                  </div>
                    </Col>
                    {oficina != null && oficina.id_Oficina != 13 ?
                          <Col md="4" sm="4" xs="6">
                              <div className="links">
                                  <p style={{ fontWeight: 'bold' }}>{t("_informacion")}</p>
                                  <ul className="stacked-links">
                                      <li>
                                          <a href="/login"><b>{t("_micuenta")}</b></a>
                                      </li>
                                      <li>
                                          <a href="/aviso-legal" ><b>{t("_avisolgeal")}</b></a>
                                      </li>
                                      <li>
                                          <a href="/privacidad"><b>{t("_privacidad")}</b></a>
                                      </li>
                                      <li>
                                          <a href="/contacto"><b>{t("_contacto")}</b></a>
                                      </li>
                                      <li>
                                          <a href="https://gestor.ovtspain.es" target="_blank"><b>{t("_gestionarestaurante")}</b></a>
                                      </li>
                                      <li>
                                          <a onClick={(e) => { e.preventDefault(); setAltaEstablecimientoModal(true); }}><b>{t("_unete")}</b></a>
                                      </li>

                                  </ul>
                              </div>
                          </Col> : ""}
                  <Col md="4" sm="4" xs="12">
                      <center>                           
                        <div className="links">                           
                                  {!loading && oficina != null && (oficina.googleplay != null && oficina.googleplay != "" || oficina.applestore != null && oficina.applestore) ? <p style={{ fontWeight: 'bold' }}>{t("_descaragaapp")}</p> : ""}
                            {!loading && oficina != null && oficina.googleplay != null && oficina.googleplay != "" ? <a target="_blank" href={oficina.googleplay}> <img src={iconoandroid} width="200" height="65" alt="Logo" style={{ marginTop: 10 }} /> </a> : ""}
                            {!loading && oficina != null && oficina.applestore != null && oficina.applestore != "" ? <a target="_blank" href={oficina.applestore}><img src={iconoios} width="200" height="65" alt="Logo" style={{ marginTop: 10 }} /></a> : ""}
                        </div>
                      </center>
                    </Col>
                  </Row>
                  {tieneredes && oficina != null && oficina.id_Oficina != 13 ? <>
                      <Row>
                          <Col md="12">
                              <center style={{ marginTop: 70 }}>
                                  <img src={iconoweb} width="276" alt="Logo" />
                              </center>
                          </Col>
                      </Row>                      
                        <Row>
                            <Col md="12">
                                <center>
                                    <h2 style={{ color: '#fcdc31', fontWeight: 'bold', marginTop: 50 }}>{t("_siguenos")}</h2>
                                </center>
                            </Col>
                        </Row> 
                  </> : ""}
                <Row>
                <Col md="12" >
                  <center style={{ marginTop: 20 }}> 
                    <div className="social-area">
                        {!loadingofi && oficina != null && oficina.linkFacebook != null && oficina.linkFacebook != "" ? <Button href={oficina.linkFacebook} target="_blank" className="btn-just-icon btn-link" color="facebook" ><i className="fa fa-facebook" /></Button> : ""}
                        {!loadingofi && oficina != null && oficina.linkTwitter != null && oficina.linkTwitter != "" ? <Button href={oficina.linkTwitter} target="_blank" className="btn-just-icon btn-link" color="twitter" ><i className="fa fa-twitter" /></Button> : ""}
                        {!loadingofi && oficina != null && oficina.linkInstagram != null && oficina.linkInstagram != "" ? <Button href={oficina.linkInstagram} target="_blank" className="btn-just-icon btn-link" color="reddit" ><i className="fa fa-instagram" /></Button> : ""}  
                        {!loadingofi && oficina != null && oficina.linkYoutube != null && oficina.linkYoutube != "" ? <Button href={oficina.linkYoutube} target="_blank" className="btn-just-icon btn-link" color="reddit" ><i className="fa fa-youtube-play" /></Button> : ""}                   
                    </div>
                  </center>
                </Col>
              </Row>
              <div className="copyright" style={{ fontWeight: 'bold', marginTop: 10}}>
                      <center>
                          © Copyright {new Date().getFullYear()} {oficina != null ?  (oficina.id_Oficina != 13 ? "Aplicaciones Turisticas en Movilidad" : oficina.nombreOficina) : ""}
                </center>                                
                  </div>
                  {AltaEstablecimientoModal ? <PeticionAltaEstablecimiento open={AltaEstablecimientoModal} setModal={setAltaEstablecimientoModal} /> : ""}                 
                <OthelloVersionWeb />                 
              </Container>
              {!loadingofi && oficina ? <OthelloCookieConsent oficina={oficina} /> : ""}
      </footer>
    </>
  );
}

export default FooterVenta;
