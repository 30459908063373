import React from "react";
import Select from "react-select";

// reactstrap components
import {
    Button,
    Container, Row, Col,   
    NavItem, NavLink, Nav,
    TabContent,
    TabPane,    
    Input,      
    Form
} from "reactstrap";

import { TipoOficina } from "../othelloConstantes.jsx";
import { GetEstadoPedidoVisual } from "../othelloFunciones.jsx";

// core components
import ColorNavbarATMSimple from "../components/Navbars/ColorNavbarATMSimple.js";
import FooterATMVenta from "../components/Footers/FooterATMVenta.js";
import FooterATM from "../components/Footers/FooterATM.js";
import DireccionModal from "./DireccionModal.js";
import OthelloMensaje from "../componentsothello/OthelloMensaje.js";
import PedidoModal from "./PedidoModal.js";

import { useTranslation } from 'react-i18next';

function Usuario()
{
    const { t, i18n } = useTranslation();

    document.documentElement.classList.remove("nav-open");

    React.useEffect(() => {
        document.body.classList.add("index-page");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
        };
    });

    const [loadingIdioma, setLoadingIdioma] = React.useState(true);

    React.useEffect(() => {

        fetch('api/Data/GetIdiomaActual')
            .then(response => response.json())
            .then(data => {
                i18n.changeLanguage(data.idioma);
                setLoadingIdioma(false);
            }).catch(error => {
                i18n.changeLanguage('es');
                setLoadingIdioma(false);
            });
    }, [loadingIdioma]);

    const [user, setUser] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        fetch('api/Data/GetUsuario')
            .then(response => response.json())
            .then(data => {
                setUser(data);
                setLoading(false)

            }).catch(error => {
                setUser(null);
                setLoading(false) 
            });

    }, [loading]);

    function CerrarSesion() {

        fetch('api/Data/EndUsuarioLogged')
            .then(response => response.json())
            .then(res => {                
                if(res)
                    window.location.assign('/index');                
            }).catch(error => {               
            });
    }

    function GuardarMisDatos(event) {
        event.preventDefault();
        //this.setState({ loading: true });
        //let formulario = document.getElementById('formlogin');
        let formData = new FormData(event.target);

        fetch('api/Data/GuardarMisDatos', {
            method: 'POST',
            body: formData,

        }).then(response => response.json()).then(res => {
            //Si hubo error
            if (!res)
                setDataError(true);
            else {
                setDataError(false);                
            }
        }).catch(error => {
            setDataError(true);
        });
    }

    const [savedataerror, setDataError] = React.useState(false);

    function GuardarNuevaPass(event) {
        event.preventDefault();
        //this.setState({ loading: true });
        //let formulario = document.getElementById('formlogin');
        let formData = new FormData(event.target);

        fetch('api/Data/GuardarNuevaPass', {
            method: 'POST',
            body: formData,

        }).then(response => response.json()).then(res => {
            //Si hubo error
            if (!res)
                setPassError(true);
            else {
                setPassError(false);
            }
        }).catch(error => {
            setPassError(true);
        });
    }

    const [savepassrror, setPassError] = React.useState(false);

    function EliminarDireccion(id) {      

        var jsonData = JSON.stringify(id);

        fetch('api/Data/EliminarDireccion', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: jsonData

        }).then(response => response.json()).then(res => {            
            setLoading(res)
        }).catch(error => {
            
        });
    }

    const [dialogcancelarModal, setDialogCancelaModal] = React.useState(false);

    function infoCancelarPedido() {
        setDialogCancelaModal(true);        
    }

    const [dialogPedidoModal, setDialogPedidoModal] = React.useState(-1);

    function infoAmpliarPedido(id) {
        setDialogPedidoModal(id);
    }
  
    const [activePill, setActivePill] = React.useState("1");

    const [direccionModal, setDireccionModal] = React.useState(-1);

    const direcciones = !loading && user != null && user.direcciones != null && user.direcciones.length> 0? 
        user.direcciones.map(x => {
            return <div><Row>
                <div style={{ width: 20, height: 40, paddingLeft: 10, paddingTop: 3 }}>
                    <i className="nc-icon nc-single-02" />
                </div>
                <Col md="9">
                    <p><span style={{ fontSize: 15, fontWeight: "bold" }}><b>{x.alias}</b></span></p>
                    <div style={{ fontSize: 14 }}><b>{x.direccion}. {x.codPostal} {x.poblacion} ({x.provincia}). {x.pais}</b></div>
                </Col>
                <Col md="2">
                    <div className="pull-right">
                        <Button className="btn-just-icon btn-border" color="instagram" style={{ marginRight: 5 }} onClick={(e) => { e.preventDefault(); setDireccionModal(x.id_Direccion); }}><i class="fa fa-pencil" aria-hidden="true"></i></Button>
                        <Button className="btn-just-icon btn-border" color="instagram" onClick={(e) => { e.preventDefault(); EliminarDireccion(x.id_Direccion); }}><i class="fa fa-trash" aria-hidden="true"></i></Button>
                    </div>
                </Col>                
            </Row>
                <hr />
            </div>

        }) : <div><Row><Col md="12"><div style={{ fontSize: 14 }}><b>{t("_sindirecciones")}</b></div></Col></Row><hr /></div>;

    const pedidos = !loading && user != null && user.pedidos != null && user.pedidos.length > 0 ?       
        user.pedidos.map(x => {
            return <div> <div style={{ border: '1px solid #f5f2f2', padding: 15 }} >
                <Row>
                    <Col md="4">
                        <span style={{ fontSize: 15 }}><b> {t("_realizado")}: {x.fechaPedidoString} | {x.numArts} {t("_articulo")}(s)</b></span>
                    </Col>
                    <Col md="7">
                        <span style={{ fontSize: 15 }}><b>{t("_preciototal")}:  <span style={{ fontWeight: "bold" }}>{x.total.toFixed(2)} &euro;</span></b></span>
                    </Col>
                    <Col md="1">
                        <Button className="btn-just-icon" size='sm' color="info" onClick={(e) => { e.preventDefault(); infoAmpliarPedido(x.id_Pedido); }} ><i class="fa fa-arrow-circle-right" aria-hidden="true"></i></Button>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col md="4">
                        <span style={{ fontSize: 15 }}><b>{t("_numpedido")}: {x.id_Pedido}</b></span>
                    </Col>
                    <Col md="4">
                        <span style={{ fontSize: 15 }}><b>{t("_estado")}: {GetEstadoPedidoVisual(x.estado)}</b></span>
                    </Col>
                    <Col md="4">
                        <div className="pull-right">
                            <Button className="btn-wd" size='sm' color="default" outline href="#" onClick={(e) => { e.preventDefault(); infoCancelarPedido(); }} >{t("_cancelar")}</Button>
                        </div>
                    </Col>
                </Row>
            </div><br /> </div>

        }) : <div><Row><Col md="12"><div style={{ fontSize: 14 }}><b>{t("_sinpedidos")}</b></div></Col></Row><hr /></div>;

  return (
      <>          
          <ColorNavbarATMSimple color='danger' />          
          <div className="section section-project cd-section" id="projects">              
              <div className="projects-1">
                
                  <Container>
                      <Row>
                          <Col md="9">
                              <h3><b>{t("_micuenta")}</b></h3>
                          </Col>
                          <Col md="3">
                              <div className="pull-right">
                                  <Button color="info" className="btn-round" type="reset" onClick={(e) => { e.preventDefault(); CerrarSesion(); }}><i class="fa fa-power-off" aria-hidden="true"></i> {t("_cerrarsesion")}</Button>                              
                              </div>
                          </Col>
                      </Row>
                      <Row>                          
                          <div className="project-pills">
                              <Nav className="nav-pills-danger" pills>
                                  <NavItem>
                                      <NavLink
                                          className={activePill === "1" ? "active" : ""}
                                          href="#"
                                          onClick={(e) => {
                                              e.preventDefault();
                                              setActivePill("1");
                                          }}
                                      >
                                          {t("_misdatos")}
                                       </NavLink>
                                  </NavItem>
                                  <NavItem>
                                      <NavLink
                                          className={activePill === "2" ? "active" : ""}
                                          href="#"
                                          onClick={(e) => {
                                              e.preventDefault();
                                              setActivePill("2");
                                          }}
                                      >
                                          {t("_misdirecciones")}
                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                      <NavLink
                                          className={activePill === "3" ? "active" : ""}
                                          href="#"
                                          onClick={(e) => {
                                              e.preventDefault();
                                              setActivePill("3");
                                          }}
                                      >
                                          {t("_mispedidos")}
                    </NavLink>
                                  </NavItem>
                              </Nav>
                          </div>
                       </Row>
                      <Row>
                          <Col md="12">
                              <TabContent activeTab={activePill}>
                                  <TabPane tabId="1" >
                                      <Container>
                                          <br />
                                          <Row>
                                              <Col className="description-section" md="12">
                                                  <span style={{ fontSize: 15, fontWeight: "bold" }}>
                                                      <b>{t("_datosmicuenta")}</b>
                                                  </span>
                                              </Col>                                              
                                          </Row>
                                          <br />
                                          <div style={{ fontSize: 13, border: '1px solid #f5f2f2', padding: 25 }} >
                                              <Form className="formmidatos" onSubmit={(e) => { e.preventDefault(); GuardarMisDatos(e) }}>
                                            <Row>
                                                <Col md="4">
                                                          <h6>{t("_nombre")} <span className="icon-danger">*</span></h6>
                                                          <Input className="border-input" defaultValue={!loading && user != null ? user.nombre : ""} placeholder="" type="text" id="nombre" name="nombre" />
                                                </Col>
                                                <Col md="4">
                                                          <h6>{t("_apellidos")} <span className="icon-danger">*</span></h6>
                                                        <Input
                                                            className="border-input"
                                                          defaultValue={!loading && user != null ? user.apellido : ""}
                                                            placeholder=""
                                                              type="text"
                                                              id="apellido" name="apellido" 
                                                        />
                                                  </Col>
                                                <Col md="4">
                                                          <h6>{t("_telefono")} <span className="icon-danger">*</span></h6>
                                                      <Input
                                                          className="border-input"
                                                          defaultValue={!loading && user != null ? user.telefono : ""}
                                                          placeholder=""
                                                              type="text"
                                                              id="telefono" name="Telefono" 
                                                      />
                                                  </Col>
                                              </Row>  
                                                  <br />
                                                  {savedataerror ? <center><span className='text-danger'><b>{t("_nosehizooperacion")}</b></span></center> : ""}
                                              <Row>
                                                  <Col md="12">
                                                      <div className="pull-right">
                                                              <Button className="btn-wd" color="danger" outline type="submit" >{t("_guardar")}</Button>                                                          
                                                      </div>
                                                  </Col>
                                                  </Row>
                                              </Form>
                                            </div>                                                 
                                          <br />
                                          <br />
                                          <Row>
                                              <Col className="description-section" md="12">
                                                  <span style={{ fontSize: 15, fontWeight: "bold" }}>
                                                      <b>{t("_contrasena")}</b>
                                                  </span>
                                              </Col>
                                          </Row>
                                          <br />
                                          <div style={{ fontSize: 13, border: '1px solid #f5f2f2', padding: 25 }} >
                                              <Form className="formmidatos" onSubmit={(e) => { e.preventDefault(); GuardarNuevaPass(e) }}>
                                              <Row>
                                                  <Col md="4">
                                                          <h6>{t("_oldcontrasena")} <span className="icon-danger">*</span></h6>
                                                          <Input className="border-input" type="password" id="oldpass" name="oldpass" />                                                                                                          
                                                  </Col>
                                                  <Col md="4">
                                                          <h6>{t("_newcontrasena")} <span className="icon-danger">*</span></h6>
                                                          <Input className="border-input" type="password" id="pass" name="pass" />
                                                  </Col>
                                                  <Col md="4">
                                                          <h6>{t("_repetircontrasena")} <span className="icon-danger">*</span></h6>
                                                          <Input className="border-input" type="password" id="pass2" name="pass2"  />
                                                  </Col>
                                              </Row>
                                                  <br />
                                                  {savepassrror ? <center><span className='text-danger'><b>{t("_nosehizooperacion")}</b></span></center> : ""}
                                              <Row>
                                                  <Col md="12">
                                                      <div className="pull-right">
                                                              <Button className="btn-wd" color="danger" outline type="submit" >{t("_guardar")}</Button>
                                                      </div>
                                                  </Col>
                                                  </Row>
                                              </Form>
                                          </div>
                                      </Container>
                                  </TabPane>
                                  <TabPane tabId="2" >
                                      <Container>
                                          <br />
                                          <Row>
                                              <Col className="description-section" md="12">
                                                  <span style={{ fontSize: 15, fontWeight: "bold" }}>
                                                      <b>{t("_misdirecciones")}</b>
                                                  </span>
                                              </Col>
                                          </Row>
                                          <br />
                                          <div style={{ fontSize: 13, border: '1px solid #f5f2f2', padding: 25 }} >
                                              {direcciones}                                             
                                              <Button color="info" outline type="reset" onClick={(e) => { e.preventDefault(); setDireccionModal(0); }}><i class="fa fa-plus" aria-hidden="true"></i> {t("_adddireccion")}</Button>
                                          </div>
                                          <br />                                          
                                      </Container>
                                  </TabPane>                               
                                  <TabPane tabId="3" >
                                      <Container>
                                          <br />
                                          <Row>
                                              <Col className="description-section" md="12">
                                                  <span style={{ fontSize: 15, fontWeight: "bold" }}>
                                                      <b>{t("_mispedidos")}</b>
                                                  </span>
                                              </Col>
                                          </Row>
                                          <br />
                                          {pedidos}                                                                                  
                                      </Container>
                                  </TabPane>
                              </TabContent>
                          </Col>
                          </Row>
                     
                      <div className="space-top" />
                      {direccionModal >= 0 ? <DireccionModal open={direccionModal >= 0} setModal={setDireccionModal} id={direccionModal} loadingpage={setLoading} /> : ""}
                      {dialogcancelarModal ? <OthelloMensaje open={dialogcancelarModal} titulo={t("_infocance")} mensaje={t("_infodocance")} setModal={setDialogCancelaModal} /> : ""}
                      {dialogPedidoModal > 0 ? <PedidoModal open={dialogPedidoModal > 0} id={dialogPedidoModal}  setModal={setDialogPedidoModal} /> : ""}
                  </Container>
              </div>
          </div>    
          {!loading && user != null && user.tipoOfi == TipoOficina.Venta ? <FooterATMVenta /> : <FooterATM /> }   
    </>
  );
}

export default Usuario;