import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationES from './assets/translations/es.json';
import translationEN from './assets/translations/en.json';
import translationFR from './assets/translations/fr.json';
import translationIT from './assets/translations/it.json';
import translationDE from './assets/translations/de.json';
import translationCA from './assets/translations/ca.json';

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    es: {
        translation: translationES
    },
    fr: {
        translation: translationFR
    },
    it: {
        translation: translationIT
    },
    de: {
        translation: translationDE
    },
    ca: {
        translation: translationCA
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: 'es',
        lng: "es",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;

//---------------------------------
//    i18n
//        .use(initReactI18next) // passes i18n down to react-i18next
//        .init({
//            resources,
//            lng: "en",

//            keySeparator: false, // we do not use keys in form messages.welcome

//            interpolation: {
//                escapeValue: false // react already safes from xss
//            }
//        });


//-----------------------------------
//i18n
//    .use(initReactI18next)
//    .init({
//        resources: {
//            en,
//            fr,
//            it,
//            de,
//            es,
//        },
//        fallbackLng: 'en',
//        debug: process.env.NODE_ENV !== 'production',
//        ns: ['translations'],
//        defaultNS: 'translations',
//        keySeparator: false,
//        interpolation: {
//            escapeValue: false,
//            formatSeparator: ',',
//        },
//        react: {
//            wait: true,
//        },
//    });


