import React from "react";

// reactstrap components
import {
    Button,
    Container, Row, Col,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    CardImg,
    Badge,
    NavItem, NavLink, Nav,
    Media,
    Input
} from "reactstrap";

import { TipoOficina } from "../othelloConstantes.jsx";
import { GetGoogleMaps, GetBadgeOpcionesProducto, NombreContieneTextoBusqueda } from "../othelloFunciones.jsx";

// core components
import ColorNavbarATMSimple from "../components/Navbars/ColorNavbarATMSimple.js";
import FooterATMVenta from "../components/Footers/FooterATMVenta.js";
import FooterATM from "../components/Footers/FooterATM.js";
import HorarioModal from "./HorarioModal.js";

import { useTranslation } from 'react-i18next';

function Listado()
{
    const { t, i18n } = useTranslation();

    document.documentElement.classList.remove("nav-open");

    React.useEffect(() => {
        document.body.classList.add("index-page");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
        };
    });

    const [loadingIdioma, setLoadingIdioma] = React.useState(true);

    React.useEffect(() => {

        fetch('api/Data/GetIdiomaActual')
            .then(response => response.json())
            .then(data => {
                i18n.changeLanguage(data.idioma);
                setLoadingIdioma(false);
            }).catch(error => {
                i18n.changeLanguage('es');
                setLoadingIdioma(false);
            });
    }, [loadingIdioma]);

    const [item, setItem] = React.useState(null);
    const [selected, setSelected] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
    const [noticeModal, setNoticeModal] = React.useState(false);
    const [textoBusqueda, setTextoBusqueda] = React.useState("");


    React.useEffect(() => {
        fetch('api/Data/GetProductosItem')
            .then(response => response.json())
            .then(data => {

                //Si me indica que debo redirigir
                if (data.redireccion != null && data.redireccion != "")
                    window.location.assign(data.redireccion);
                else {
                    setItem(data);
                    setSelected(data != null && data.id_categoria_selected != null ? data.id_categoria_selected : 0)
                    setLoading(false)
                }
            }).catch(error => {
                setItem(null);
                setSelected(0)
                setLoading(false)
            });

    }, [loading]);


    const categorias = !loading && item != null && item.categorias != null && item.categorias.length > 0 ?
        item.categorias.map(x =>
        {
            return <CardHeader className="card-collapse" id={x.id} role="tab" >
                <h5 className="mb-0 panel-title">
                    <a href="#" onClick={(e) => { e.preventDefault(); setSelected(x.id) }}><b>{x.nombre}</b></a>
                        </h5>
                    </CardHeader>
        }) : "";


    const articulosfamilia = !loading && item != null && item.categorias != null && item.categorias.length > 0 ?
        item.categorias.map(x =>
        {
            if (selected > 0 && x.id == selected && x.articulos != null && x.articulos.length > 0)
            {                
                return x.articulos.map(y =>
                {
                    //Si el nombre del item contiene el texto de busqueda  
                    if (NombreContieneTextoBusqueda(y.nombre, textoBusqueda))
                    {
                        let imagenfam = y.imagen != null && y.imagen != "" ? y.imagen : require("../assets/img/image_placeholder.jpg");
                        // let urlclick = "/producto/" + item.nombre.replace(/\s/g, "-").toLowerCase() + "/" + y.nombre.replace(/\s/g, "-").toLowerCase() + "/" + y.id;

                        return <Col md="4">
                            <Card className="card-product card-plain">
                                <a href={y.linkproducto}>
                                    <CardImg top tag="div">
                                        <center> {GetBadgeOpcionesProducto(y.verstock, y.enpromocion, y.enviogratis, true, y.cantidad)}</center>
                                        <img alt="..." className="img" src={imagenfam} />
                                    </CardImg>
                                    <CardBody>
                                        <CardTitle tag="h4"><center><b>{y.nombre}</b></center></CardTitle>
                                        <p className="card-description">
                                            <center> <strong><color style={{ color: '#fc030f' }}>{y.precio}</color></strong>   <color style={{ color: '#5c5e61', textDecoration: "line-through" }}>{y.precioold}</color> </center>
                                        </p>
                                    </CardBody>
                                </a>
                            </Card>
                        </Col>
                    }
                    else
                        return "";
                });
                
            }
        }) : "";

    const TextOnChange = (event) => {

        if (event.target.value != textoBusqueda)
            setTextoBusqueda(event.target.value);
    }
   
  return (
      <>          
          <ColorNavbarATMSimple color='danger' />
          <div className="section" style={{ marginTop: 50 }}>          
              <Container>                                                 
                      <Media>                           
                      <div className="avatar big-avatar">
                          <Media alt="..." object src={!loading && item != null && item.imagen != null && item.image != "" ? item.imagen : require("../assets/img/image_placeholder.jpg")} />
                        </div>                  
                      <Media body>
                          <Media heading>{!loading && item != null && item.nombre != null ? <a href={item.linkpoi}><b>{item.nombre}</b></a> : ""} </Media>
                          <Row>
                              <Col md="7"> 
                                  {!loading && item != null && item.nombrearea != null ? <Badge color="danger">{item.nombrearea}</Badge> : ""}                                
                                   <p><b>{!loading && item != null && item.descripcion != null ? item.descripcion : ""}</b></p>
                              </Col>
                              <Col md="5">                             
                                  {!loading && item != null && item.direccion != null && item.direccion != "" ? <p><i className="fa fa-map-marker"></i><b>   {item.direccion}</b></p> : ""}    
                                  {!loading && item != null && item.tipoentrega != null && item.tipoentrega != "" ? <p><i className="nc-icon nc-bag-16" /><b>   {item.tipoentrega}</b> </p> : ""}
                                  {!loading && item != null && item.horario != null ? <p><i className="nc-icon nc-time-alarm" />    <a href="#" onClick={(e) => { e.preventDefault(); setNoticeModal(true) }}><Badge color="info">{t('_verhorario')}</Badge></a></p> : ""}    
                              </Col>
                          </Row>
                      </Media>
                      
                    </Media>                   
              </Container>
          </div>
          <div>
              <Container>
                  <h3 className="section-title"><b>{t("_categorias")}</b></h3>
                  <br/>
                  <Row>
                      <Col md="3">
                          <Card className="card-refine">
                              <div
                                  aria-expanded={true}
                                  aria-multiselectable={true}
                                  className="panel-group"
                                  id="accordion"
                              >
                                  {categorias}
                              </div>
                          </Card>
                      </Col>
                      <Col md="9">
                          <div className="products">
                              <div class="input-group" style={{ marginBottom: 35 }}>
                                  <Input type="search" name="text" id="exampleText" placeholder={t('_buscar')} onChange={TextOnChange} />
                                  <span class="input-group-append">
                                      <button class="btn" type="button">
                                          <i class="fa fa-search"></i>
                                      </button>
                                  </span>
                              </div>
                              <Row>
                                  {articulosfamilia}
                              </Row>
                          </div>
                      </Col>
                  </Row>
              </Container>
          </div>
          {!loading && item.lat != null && item.lon != null && item.lat != 0 && item.lon != 0 ?
              <div className="pricing-5 section-gray">
                  <Container>
                      <Row>
                          <Col className="ml-auto mr-auto text-center" md="12">
                              <h2 className="title"><b>{t("_venaconcernos")}</b></h2>
                              <br />                              
                          </Col>
                      </Row>
                  </Container>
                  <div className="big-map" id="contactUsMap2" style={{ backgroundColor: '#cccccc', height: 500 }}>
                      <HorarioModal open={noticeModal} setModal={setNoticeModal} horario={item.horario} titulo={item.nombre}/> 
                      {GetGoogleMaps(item.lat, item.lon)}
                  </div>
              </div> : ""
          }            
          {!loading && item != null && item.tipoofi == TipoOficina.Venta ? <FooterATMVenta /> : <FooterATM />}            
    </>
  );
}

export default Listado;
