import React from "react";
// reactstrap components
import {
    Container,
    Card,
    CardBody,   
    Row,
    Col,
    CardFooter
} from "reactstrap";

import { useTranslation } from 'react-i18next';

function CestaModal({...props })
{
    const { t } = useTranslation();

    //variables de las propiedas recibidas
    const { carrito } = props;


    let imagen = carrito != null && carrito.producto != null && carrito.producto.imagen != null && carrito.producto.imagen != "" ? carrito.producto.imagen : require("../assets/img/image_placeholder.jpg");
   
    return (
        <>           
            <div style={{ width: 425 }}>
                <Card>
                    <CardBody>
                        <Container>
                            <Row style={{ marginBottom: 15 }}>
                                <span style={{ fontSize: 15, fontWeight: "bold" }}><color className="text-danger">{t("_lastproductadd")}</color></span>
                            </Row>
                            <Row>
                                <div style={{ width: 90, height: 75, marginLeft: 10 }}>
                            <img style={{ width: 80, height: 60 }} alt="..." height className="img-rounded img-no-padding img-responsive" src={imagen} />
                        </div>                        
                        <Col className="mr-auto" style={{ fontSize: 14 }}>
                            <Row>
                                <Col className="mr-auto" >
                                    <span ><b>{ carrito != null && carrito.producto != null ? carrito.producto.nomArticulo : ""}</b></span>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mr-auto" >
                                <b>Cantidad: {carrito != null && carrito.producto != null ? carrito.producto.cantidad : ""}</b>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mr-auto" >
                                            <b>{t("_precio")}:  <color className="text-danger">{ carrito != null && carrito.producto != null ? carrito.producto.precio : ""} &euro;</color></b>
                                </Col>
                            </Row>
                                </Col>
                            </Row>
                        </Container>
                    </CardBody>
                    <CardFooter style={{ backgroundColor: '#d2d2d2' }}>
                        <div>
                            <Col className="mr-auto" style={{ fontSize: 15, fontWeight: 'bold' }}>
                                {t("_totalproductos")}: <color className="text-danger">{carrito != null ? carrito.numarts : ""}</color>
                            </Col>
                        </div>
                        <div>
                            <Col className="mr-auto" style={{ fontSize: 15, fontWeight: 'bold' }}>
                                <b>{t("_subtotalpedido")}: <color className="text-danger">{carrito != null ? carrito.precioarts : ""}</color></b>
                            </Col>
                        </div>    
                       
                    </CardFooter>
                </Card>
                </div>
           
        </>
    );
}

export default CestaModal;

//<div className="pull-right" >
//    <Button className="btn-wd" size="sm" color="danger" href="/cart" >Ir a Carrito</Button>
//</div>