import React from "react";

import { useClearCache } from "react-clear-cache";

export default function OthelloVersionWeb()
{
    const { latestVersion  } = useClearCache();

    return (
        <div>
            {latestVersion}      
        </div>
  );
}
