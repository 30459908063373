import React from "react";
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    CardFooter
} from "reactstrap";

import PromocionModal from "./PromocionModal.js";

import { useTranslation } from 'react-i18next';

function ItemPromocion({...props })
{
    const { t } = useTranslation();

    //variables de las propiedas recibidas
    const { promo, desdepoi } = props;

    const [promocionModal, setPromocionModal] = React.useState(false);

    let imagen = require("../assets/img/image_placeholder.jpg");

    if (promo != null) {
        if (promo.imageUrlPromo != null && promo.imageUrlPromo != "")
            imagen = promo.imageUrlPromo;
        else if (!desdepoi && promo.urlImgPoi != null && promo.urlImgPoi != "")
            imagen = promo.urlImgPoi;
    }

    
    let urlclick = promo != null ? promo.linkpoi : "";

    let promomodal = promocionModal ? <PromocionModal open={promocionModal} setModal={setPromocionModal} id={promo != null ? promo.id_Promocion : -1}/> : ""

    return (
        <>
            {promo != null ?
                <Card className="card-pricing">
                    {promomodal}
                    <CardBody>
                        <a href={urlclick}><h4 className="card-category"><b>{!desdepoi ? promo.nombrePoi : ""}</b></h4></a>
                        <a href={urlclick}><img alt="..." className="img" src={imagen} style={{ width: '100%' }} /></a>
                        <a href={urlclick}><CardTitle tag="h3"><b>{promo.promo}</b></CardTitle></a>
                        <p className="card-description">
                            <b>{t("_ofertavalidahasta")} {(new Date(promo.fechValido)).toLocaleDateString()}</b>
                        </p>
                        <CardFooter>
                            <Button style={{ width: 250 }} color="primary" onClick={(e) => { e.preventDefault(); setPromocionModal(true); }}>
                                {t("_verpromocion")}
                            </Button>
                            {promo.urlExterna != null && promo.urlExterna != "" ?
                                <Button color="success" href={promo.urlExterna} style={{ width: 250, marginTop: 5 }} target="_blank">
                                    {t("_masinfo")}
                                </Button> : ""}
                        </CardFooter>
                    </CardBody>
                </Card> : "" }
        </>
    );
}

export default ItemPromocion;