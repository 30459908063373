import React from "react";
// reactstrap components
import {
    Button, 
    Modal,
    Container,   
    Row,
    Col
} from "reactstrap";

function OthelloModalBase({...props })
{
    //variables de las propiedas recibidas
    const { open, titulo, setModal} = props;
  
    return (
        <>           
            <Modal isOpen={open} toggle={() => setModal(false)}>    
                <div className="modal-header">
                    <span style={{ fontSize: 15, fontWeight: "bold" }}>
                        <b>{titulo != null && titulo != "" ? titulo : ""}</b>
                    </span>
                </div>
               <br/>
                <Container>
                    <div>
                        {props.children}
                    </div>
                    <Row>
                        <Col md="12">
                            <div className="pull-right">
                                <Button className="btn-wd" color="danger" outline style={{ marginRight: 5 }} onClick={(e) => { e.preventDefault(); setModal(false) }}>Aceptar</Button>                                                                
                            </div>
                        </Col>
                    </Row>                   
                    <div outline style={{ marginRight: 5, height:25 }} />
                </Container>               
                
            </Modal>
        </>
    );
}

export default OthelloModalBase;