import React from "react";

// reactstrap components
import { } from "reactstrap";

import OthelloCarrusel from "../../componentsothello/OthelloCarrusel.js";

import { TipoCarrusel } from "../../othelloConstantes.jsx";

// core components
//const items = [
//    {
//        src: "url(" + require("../../assets/img/fondocompras.jpg") + ")",
//        content: (
//            <Container>
//                <Row>
//                    <Col className="text-left" md="8">
//                        <h1 className="title" style={{ fontWeight: 'bold' }}>Hambriento?</h1>
//                        <h1><color style={{ fontWeight: 'bold', color: '#fcdc31' }}>La Mejor Comida</color></h1>
//                        <h1 style={{ fontWeight: 'bold' }}>a Domicilio en un clic</h1>                        
//                        <br />
//                        <div className="buttons" style={{ marginTop: 20 }}>
//                            <Button
//                                className="btn-round"
//                                color="danger"
//                                href="/area"
//                                onClick={(e) => e.preventDefault()}
//                                size="lg"
//                            >
//                                Ver Restaurantes
//                        </Button>                           
//                        </div>
//                    </Col>
//                </Row>
//            </Container>
//        ),
//        altText: "",
//        caption: "",
//    }  
//];
// core components

function IndexHeader({ ...props }) {

    const {  } = props;

    const [items, setItems] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        fetch('api/Data/GetImagenesPortadaOficina')
            .then(response => response.json())
            .then(data => {
                setItems(data);
                setLoading(false)
            }).catch(error => {
                setItems(null);
                setLoading(false)
            });
    }, [loading]);

 
  return (
    <>
          <div className="header-3">
              
              <div className="page-carousel">
                  <div className="filter" />
                  {!loading && items != null && items.length > 0 ? <OthelloCarrusel tipo={TipoCarrusel.Portada} carouselItems={items} /> : ""}
              </div>
          </div>
    </>
  );
}

export default IndexHeader;
