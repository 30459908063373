import React from "react";

// reactstrap components
import {
    Button,
    Container, Row, Col,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    CardImg,
    Input
} from "reactstrap";

import { GetGoogleMaps, getTextoPortadaPuntosCorporativa, getSubTextoPortadaPuntosCorporativa, getTextoMapaCorporativa, getSubTextoMapaCorporativa, getTextoFotoPortadaPuntosCorporativa, getSubTextoFotoPortadaPuntosCorporativa } from "../../othelloFunciones.jsx";

import { useTranslation } from 'react-i18next';
import ItemPromocion from "../../view/ItemPromocion.js";
import { TipoOficina } from "../../othelloConstantes.jsx";


import caminologo from "../../assets/img/caminologo.png"
import cantabrialogo from "../../assets/img/cantabrialogo.png"
import gobiernocantabrialogo from "../../assets/img/gobiernocantabrialogo.png"
import geoparquelogo from "../../assets/img/geoparquelogo.png"




function BodyATM({ ...props })
{
    //variables de las propiedas recibidas
    const { oficina } = props;

    const { t } = useTranslation();

    const [agenda, setAreaAgenda] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    const [corporativa, setCorporativa] = React.useState(null);
    const [loadingCorporativa, setLoadingCorporativas] = React.useState(true);

    const [destacados, setDestacados] = React.useState(null);
    const [loadingDestacados, setLoadingDestacados] = React.useState(true);

    const [promociones, setPromociones] = React.useState(null);
    const [loadingPromociones, setLoadingPromociones] = React.useState(true);

    const [itemsbusqueda, setItemsBusqueda] = React.useState(null);

    React.useEffect(() => {
        fetch('api/Data/GetAreaAgenda')
            .then(response => response.json())
            .then(data => {
                setAreaAgenda(data);
                setLoading(false)
            }).catch(error => {
                setAreaAgenda(null);
                setLoading(false)
            });

    }, [loading]);

    React.useEffect(() => {
        fetch('api/Data/GetPuntosCorporativos')
            .then(response => response.json())
            .then(data => {
                setCorporativa(data);
                setLoadingCorporativas(false)
            }).catch(error => {
                setCorporativa(null);
                setLoadingCorporativas(false)
            });

    }, [loadingCorporativa]);

    React.useEffect(() => {
        fetch('api/Data/GetDestacados')
            .then(response => response.json())
            .then(data => {
                setDestacados(data);
                setLoadingDestacados(false)
            }).catch(error => {
                setDestacados(null);
                setLoadingDestacados(false)
            });

    }, [loadingDestacados]);

    React.useEffect(() => {
        fetch('api/Data/GetPromociones')
            .then(response => response.json())
            .then(data => {
                setPromociones(data);
                setLoadingPromociones(false)
            }).catch(error => {
                setPromociones(null);
                setLoadingPromociones(false)
            });

    }, [loadingPromociones]);

    const noticias = !loading && agenda != null && agenda.area != null && agenda.pois.length > 0 ?
        agenda.pois.map(x =>
        {
            let imagen = x.imageUrlBig != null ? x.imageUrlBig : require("../../assets/img/image_placeholder.jpg");
           // let urlclick = "/ficha/" + agenda.area.cod_Area.replace(/\s/g, "-").toLowerCase() + "/" + x.title.replace(/\s/g, "-").toLowerCase();

            return <Col md="3">
                <Card className="card-blog">
                    <div className="card-image">
                        <a href={x.linkpoi} >
                            <img
                                alt="..."
                                className="img img-raised"
                                src={imagen}
                            />
                        </a>
                    </div>
                    <CardBody>
                        <CardTitle tag="h6" text-danger>
                            <a href={x.linkpoi}>
                                <b>{x.title}</b>
                            </a>
                        </CardTitle>
                        <p className="card-description">
                            <b>{x.description} <br /></b>
                        </p>
                        <hr />
                        <CardFooter>
                            <div className="stats">
                                
                          </div>
                        </CardFooter>
                    </CardBody>
                </Card>
            </Col>
        }) : "";

    //por si quiero poner fecha a las noticias -><i className="fa fa-clock-o" /> <b>{x.fNotificacion}</b> en el cardfooter

    const poisdestacados = !loadingDestacados && destacados != null && destacados.area != null && destacados.pois.length > 0 ?
        destacados.pois.map(x =>
        {
            let imagen = x.imageUrlBig != null ? x.imageUrlBig : require("../../assets/img/image_placeholder.jpg");
            //let urlclick = "/ficha/" + destacados.area.cod_Area.replace(/\s/g, "-").toLowerCase() + "/" + x.title.replace(/\s/g, "-").toLowerCase();

            return <Col md="4">
                <Card className="card-plain">
                    <CardImg top tag="div">
                        <a href={x.linkpoi} >
                            {!loading && oficina.idoficina != 207 ?
                                <img style={{ aspectRatio: "4/3", width: "100%", objectFit: "cover" }}
                                    alt="..."
                                    className="img"
                                    src={imagen}
                                /> : <img alt="..." className="img" src={imagen} />
                            }
                        </a>
                    </CardImg>
                    <CardBody>
                        <a href={x.linkpoi} ><CardTitle tag="h4"><b>{x.title}</b></CardTitle><br /></a>
                        <h6 className="card-category text-muted">{destacados.area.cod_Area}</h6>
                        <p className="card-description">
                            <b>{x.description} </b>
                        </p>
                    </CardBody>
                    <CardFooter>
                        <center>
                            <Button color="danger" href={x.linkpoi}>{t("_vermas")}</Button>
                        </center>
                    </CardFooter>
                </Card>
            </Col>
        }) : "";

    const promocionespoi = !loadingPromociones && promociones != null && promociones.length > 0 ?
        promociones.map(x =>
        {
            return <Col className="ml-auto mr-auto" md="4"><ItemPromocion promo={x} desdepoi={false} /></Col>
        }) : "";

    const poiscorporativos = !loadingCorporativa && corporativa != null && corporativa.length > 0 ?
        corporativa.map(x =>
        {
            let imagen = x.imageUrlBig != null ? x.imageUrlBig : require("../../assets/img/image_placeholder.jpg");
            //let urlclick = "/ficha/" + destacados.area.cod_Area.replace(/\s/g, "-").toLowerCase() + "/" + x.title.replace(/\s/g, "-").toLowerCase();

            return <Col md="4">
                <Card className="card-plain">
                    <CardImg top tag="div">
                        <a href={x.linkpoi} >
                            {!loading && oficina.idoficina != 207 ?
                            <img style={{ aspectRatio: "4/3", width: "100%", objectFit: "cover" }}
                                alt="..."
                                className="img"
                                src={imagen}
                            /> : <img alt="..." className="img" src={imagen} />
                        }
                        </a>
                    </CardImg>
                    <CardBody>
                        <a href={x.linkpoi} ><CardTitle tag="h4"><b>{x.title}</b></CardTitle><br /></a>
                        <h6 className="card-category text-muted">{/*destacados.area.cod_Area*/}</h6>
                        <p className="card-description">
                            <b>{x.description} </b>
                        </p>
                    </CardBody>
                    <CardFooter>
                        <center>
                            <Button color="danger" href={x.linkpoi}>{t("_vermas")}</Button>
                        </center>
                    </CardFooter>
                </Card>
            </Col>
        }) : "";

    const TextOnChange = (event) => {

        if (event.target.value != "" )
        {
            if (event.target.value.length >= 3) {
                var jsonData = JSON.stringify(event.target.value);

                fetch('api/Data/GetBusquedaGlobal', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: jsonData

                }).then(response => response.json()).then(data => {
                    setItemsBusqueda(data);

                }).catch(error => {
                    setItemsBusqueda(null)
                });
            }
            else if (itemsbusqueda != null && itemsbusqueda.length > 0)
                setItemsBusqueda(null)
        }        
        else if (itemsbusqueda != null && itemsbusqueda.length > 0)
            setItemsBusqueda(null)
    }

    function getItemsBusqueda(items)
    {
        let itemsfinal = items.map(x => {
            let imagen = x.imageUrl != null ? x.imageUrl : require("../../assets/img/image_placeholder.jpg");
            //let urlclick = "/ficha/" + destacados.area.cod_Area.replace(/\s/g, "-").toLowerCase() + "/" + x.title.replace(/\s/g, "-").toLowerCase();

            return <Col md="4" style={{ minWidth: 300 }}>
                <Card className="card-plain" >
                <Row>
                    <div>  
                        <CardImg top tag="div">
                                <a href={x.linkpoi} > <img alt="..." className="img" src={imagen} style={{ marginLeft: 10, height: 65, width:80}} /> </a>
                        </CardImg>
                    </div>
                        <Col xs='7' md="8" >
                            <a href={x.linkpoi} ><p style={{ marginTop: 5, fontSize: 12 }}><b>{x.title.length > 55 ? x.title.substring(0, 55) + "..." : x.title}</b></p></a>
                            <p className="card-category text-muted"><b>{x.cod_Area}</b></p>                        
                        </Col>
                </Row>
                </Card>
            </Col>
        });

        return itemsfinal;
    }

    function renderPuntosDestacados() {
        return <div className="features-1" >
            <Container>
                <Row>
                    <div class="input-group" style={{ marginBottom: 35 }}>
                        <Input type="search" name="text" id="exampleText" placeholder={t('_buscaen') + " " + oficina.nombreOficina} onChange={TextOnChange} />
                        <span class="input-group-append">
                            <button class="btn" type="button">
                                <i class="fa fa-search"></i>
                            </button>                           
                        </span>
                        <span class="input-group-append" style={{ marginLeft: 2 }}>
                            <Button class="btn" type="button" href={"/buscando/"}>
                                <i class="fa fa-search"></i> {t("_avanzada")}
                            </Button>                        
                        </span>
                    </div>
                    {itemsbusqueda != null && itemsbusqueda.length > 0 ?
                        <div style={{
                            position: "absolute", backgroundColor: '#ffffff', width: 'auto', height: 'auto', maxHeight: 800,
                            overflow: 'auto',
                            zIndex: 1000000,
                            marginTop: 44,
                            border: '0.5px solid grey',
                            borderRadius: 5
                        }}>
                            <Container style={{ marginTop: 25 }}>
                                <Row>
                                    {getItemsBusqueda(itemsbusqueda)}
                                </Row>
                                <div className="demo-footer text-center">

                                </div>
                            </Container>
                        </div>
                        : ""}
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto text-center" md="12">
                        <h2 className="title"><b>{oficina != null && oficina.tipoOfi != TipoOficina.Corporativa ? t("_descubre") : ""} {oficina != null ? oficina.nombreOficina : ""} {t("_yentorno")}</b></h2>
                        <hr />
                        <h5 className="description">
                            <b>{t("_noperderte")}</b>
                        </h5>
                    </Col>
                </Row>
                <br />
                <div className="space-top" />
                <Row>
                    {poisdestacados}
                </Row>
                <hr />
                <Row>
                    <Col className="ml-auto mr-auto text-center" md="4">
                        <center>
                            {destacados != null && destacados.area != null ?
                                <Button color="default" size="lg" href={"/area/" + destacados.area.cod_Area.toLowerCase().replace(/\s/g, "-")}>
                                    {t("_masitios")}
                                </Button> : ""}
                        </center>
                    </Col>
                </Row>
            </Container>
        </div>      
    }

    function renderPuntosCorporativa()
    {
        let imgback = oficina != null ? "imagenes/fotoback_" + oficina.id_Oficina + "_.jpg" : "";

        return oficina != null && oficina.tipoOfi == TipoOficina.Corporativa && poiscorporativos != "" ?
            <><div className="features-1">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="12">
                            <h2 className="title"><b>{oficina != null ? getTextoPortadaPuntosCorporativa(oficina, t) : ""}</b></h2>
                            <hr />
                            <h5 className="description">
                                <b>{ oficina != null ? getSubTextoPortadaPuntosCorporativa(oficina, t) : ""}</b>
                            </h5>
                        </Col>
                    </Row>
                    <br />
                    <div className="space-top" />
                    <Row>
                        {poiscorporativos}
                    </Row>
                    <br />
                </Container>
            </div>
             <div
              className="features-5 section-image"
              style={{
                  backgroundImage: "url(" + (imgback) + ")", 
              }}
          >
              <Container>
                  <Row>
                      <div className="ml-auto mr-auto">
                          <h2 className="title text-center">
                                    <b>{oficina != null ? getTextoFotoPortadaPuntosCorporativa(oficina, t) : ""}</b>
                             
                            </h2>
                                <h5 className="title text-center"><b>{oficina != null ? getSubTextoFotoPortadaPuntosCorporativa(oficina, t) : "" }</b></h5>
                      </div>
                  </Row>                 
              </Container>
          </div></>: "";
    }
   
    function renderNoticias() {

        return <div className="projects-2 section section-dark">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto text-center" md="8">
                        <h2 className="title"><b>{!loading && agenda != null && agenda.area != null? agenda.area.cod_Area : "-"}</b></h2>
                        <hr />
                        <h5 className="description">
                            <b>{t("_queestapasando")}</b>
                        </h5>
                    </Col>
                </Row>
                <br />
                <div className="space-top" />
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Row>
                            {noticias}
                        </Row>
                    </Col>
                </Row>
                <hr />
                <br />
                <Row>
                    <Col className="ml-auto mr-auto text-center" md="4">
                        <center>
                            {!loading && agenda != null && agenda.area != null ?
                                <Button color="danger" size="lg" href={"/area/" + agenda.area.cod_Area.toLowerCase().replace(/\s/g, "-")}>
                                    {t("_masinfo")}
                                </Button> : ""}
                        </center>
                    </Col>
                </Row>
            </Container></div>;
    }

    function renderPromociones() {

        return promocionespoi != null && promocionespoi != "" ?
            <div className="projects-2 section section-gray">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="8">
                            <h2 className="title"><b>{t("_promociones")}</b></h2>
                            <h5 className="description">
                                <b>{t("_nuetraspromos")}</b>
                            </h5>
                        </Col>
                    </Row>
                    <br />
                    <div className="space-top" />
                    <Row>
                        <Col className="ml-auto mr-auto" md="12">
                            <Row>
                                {promocionespoi}
                            </Row>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="4">
                            <center>
                                <Button color="danger" size="lg" href={"/promociones"}>
                                    {t("_maspromociones")}
                                </Button>
                            </center>
                        </Col>
                    </Row>
                </Container>
            </div> : "";
    }

    function renderOpcionEspecial() {

        if (oficina != null && oficina.id_Oficina == 30) {

            return <Container>
                <Row>
                    <Col className="ml-auto mr-auto text-center" md="4">
                        <a target="_blank" href="https://www.cantabria.es/web/consejeria-de-innovacion-industria-turismo-y-comercio"> <img src={gobiernocantabrialogo} width="75%"  alt="Logo" style={{ marginTop: 60 }} /> </a>
                    </Col>
                    <Col className="ml-auto mr-auto text-center" md="4">
                        <a target="_blank" href="https://www.turismodecantabria.com/inicio"> <img src={cantabrialogo} width="75%" alt="Logo" style={{ marginTop: 60 }} /> </a>
                        <a target="_blank" href="https://geoparquevallesdecantabria.es/"> <img src={geoparquelogo} width="75%" alt="Logo" style={{ marginTop: 10 }} /> </a>
                    </Col>
                    <Col className="ml-auto mr-auto text-center" md="4">
                        <a target="_blank" href="https://caminolebaniego.com/camino-lebaniego/ano-jubilar-lebaniego"> <img src={caminologo} width="75%" alt="Logo" style={{ marginTop: 60, marginBottom:10 }} /> </a>
                    </Col>
                </Row>               
            </Container>;
        }
        else
            return "";
    }

  return (
      <>                                                       
          {renderPuntosCorporativa()}                                      
          {renderPuntosDestacados()}        
          {renderNoticias()}
          {renderPromociones()}          
          <div className="projects-2 section">
              <Container>
                  <Row>
                      <Col className="ml-auto mr-auto text-center" md="8">
                          <h2 className="title"><b>{oficina != null && oficina.tipoOfi != TipoOficina.Corporativa ? t("_visita") : getTextoMapaCorporativa(oficina, t)}</b></h2>                          
                          <hr />
                          <h5 className="description">
                              <b>{oficina != null && oficina.tipoOfi != TipoOficina.Corporativa ? t("_disfrutafamilia") : getSubTextoMapaCorporativa(oficina, t)}</b>
                          </h5>
                      </Col>
                  </Row>                       
              </Container>
              <div className="space-top" />    
              <div className="big-map" id="contactUsMap2" style={{ backgroundColor: '#cccccc', height: 500 }}>
                  {GetGoogleMaps(oficina != null ? oficina.lat : 0, oficina != null ? oficina.lon : 0)}
              </div>    
              {renderOpcionEspecial()}   
          </div>                  
    </>
  );
}

export default BodyATM;
