import React from "react";

// reactstrap components
import {    
    Container, Row, Col,   
    Progress
 } from "reactstrap";

import { TipoOficina } from "../othelloConstantes.jsx";

// core components
import ColorNavbarATM from "../components/Navbars/ColorNavbarATM.js";
import ColorNavbarATMSimple from "../components/Navbars/ColorNavbarATMSimple.js";

import HomeHeaderATM from "../components/Headers/HomeHeaderATM.js";
import HomeHeaderATMVenta from "../components/Headers/HomeHeaderATMVenta.js";

import BodyATM from "../components/Bodys/BodyATM.js";
import BodyATMVenta from "../components/Bodys/BodyATMVenta.js";
import BodyATMConstruccion from "../components/Bodys/BodyATMConstruccion.js";


import FooterATMVenta from "../components/Footers/FooterATMVenta.js";
import FooterATM from "../components/Footers/FooterATM.js";

import { useTranslation } from 'react-i18next';

function Home()
{
    const { t, i18n } = useTranslation();

    document.getElementById("favicon");

    document.documentElement.classList.remove("nav-open");

    React.useEffect(() => {
        document.body.classList.add("index-page");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
        };
    });

    const [oficina, setOficina] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    const [loadingIdioma, setLoadingIdioma] = React.useState(true);

    React.useEffect(() => {
        fetch('api/Data/GetOficinaVirtual')
            .then(response => response.json())
            .then(data => {
                setOficina(data);
                setLoading(false)                
            }).catch(error => {
                setOficina(null);
                setLoading(false)
            });

    }, [loading]);

    React.useEffect(() => {

        fetch('api/Data/GetIdiomaActual')
            .then(response => response.json())
            .then(data => {
                i18n.changeLanguage(data.idioma);
                setLoadingIdioma(false);
            }).catch(error => {
                i18n.changeLanguage('es');
                setLoadingIdioma(false);
            });
    }, [loadingIdioma]);

    const OnIdiomaChanged = () =>
    {
        setLoadingIdioma(true);
       
    }
    
  return (
      <>          
          {loading ?
              <div style={{
                  position: "absolute", backgroundColor: '#000000', opacity: '0.5', width: '100%', height: '100%',
                  top: 0,
                  left: 0,
                  zIndex: 1000000,
                  color: '#FFFFFF',
                  textAlign: 'center',
                  fontSize: 25,
              }}>
                  <Container style={{ marginTop: 250 }}>
                      <Row>
                          <Col className="ml-auto mr-auto text-center" md="8">
                              <div className="space-top" />                              
                              <b>{t("_cargandoweb")}</b>
                              <div className="space-top" />
                              <Progress
                                  animated
                                  barClassName="progress-bar-primary"
                                  value={100}
                              />
                          </Col>
                      </Row>
                      <div className="demo-footer text-center">

                      </div>
                  </Container>
              </div>
              : ""}
          {!loading && oficina != null ? (oficina.tipoOfi == TipoOficina.Venta ? <ColorNavbarATM visibleidiomas={true} onIdiomaChanged={() => OnIdiomaChanged()} /> : <ColorNavbarATMSimple color="danger" visibleidiomas={true} onIdiomaChanged={() => OnIdiomaChanged()}/> ) : ""}
          {!loading && oficina != null ? (oficina.tipoOfi == TipoOficina.Venta ? <HomeHeaderATMVenta /> : <HomeHeaderATM oficina={oficina}/>) : ""  }
          <div className="main">
              {!loadingIdioma && !loading && oficina != null ? (oficina.tipoOfi == TipoOficina.Venta ? <BodyATMVenta /> : <BodyATM oficina={oficina}/>) : ""}                                
          </div>
          {!loadingIdioma && !loading && oficina != null ? (oficina.tipoOfi == TipoOficina.Venta ? <FooterATMVenta /> : <FooterATM />) : ""}      

          {!loading && oficina == null ? <BodyATMConstruccion/> : ""}
    </>
  );
}

export default Home;
