import React from "react";
import Select from "react-select";

// reactstrap components
import {
    Button,
    Container, Row, Col   
} from "reactstrap";

import { TipoOficina } from "../othelloConstantes.jsx";

// core components
import ColorNavbarATMSimple from "../components/Navbars/ColorNavbarATMSimple.js";
import FooterATMVenta from "../components/Footers/FooterATMVenta.js";
import FooterATM from "../components/Footers/FooterATM.js";

import { useTranslation } from 'react-i18next';

function PedidoSucces({...props })
{

    const { t, i18n } = useTranslation();

    document.documentElement.classList.remove("nav-open");

    React.useEffect(() => {
        document.body.classList.add("index-page");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
        };
    });

    const [validarPermiso, setValidarPermiso] = React.useState(true);

    React.useEffect(() => {

        let url = props.location.search;
        let params = new URLSearchParams(url);

        let identificador = params.get('id');
        let dt = params.get('data');

        if (identificador != null && identificador != "" && dt != null && dt != "")
        {
            var jsonData = JSON.stringify({ id: identificador, data: dt });

            fetch('api/Data/ValidarUrlPedidoSucess', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: jsonData

            }).then(response => response.json()).then(res =>
            {         
                //Si la url es valida
                if (!res)
                    window.location.assign('/index');

                setValidarPermiso(false);            

            }).catch(error => {
                setValidarPermiso(false);
            });
        }
        else 
            window.location.assign('/index');
        
    }, [validarPermiso]);

    const [loadingIdioma, setLoadingIdioma] = React.useState(true);

    React.useEffect(() => {

        fetch('api/Data/GetIdiomaActual')
            .then(response => response.json())
            .then(data => {
                i18n.changeLanguage(data.idioma);
                setLoadingIdioma(false);
            }).catch(error => {
                i18n.changeLanguage('es');
                setLoadingIdioma(false);
            });
    }, [loadingIdioma]);

    const [user, setUser] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        fetch('api/Data/GetUsuario')
            .then(response => response.json())
            .then(data => {
                setUser(data);
                setLoading(false)

            }).catch(error => {
                setUser(null);
                setLoading(false) 
            });

    }, [loading]);

   
  return (
      <>          
          <ColorNavbarATMSimple color='danger' />          
          <div className="section section-project cd-section" id="projects">              
                  <Container>
                      <Row>
                          <Col className="ml-auto mr-auto text-center" md="8">
                            <div className="space-top" />
                            <img height="130px" alt="..." src={require("../assets/img/ok_venta.png")} />
                          <h3 className="text-success"><b>{t("_compraok")}</b></h3>                             
                          </Col>
                      </Row>                     
                      <Row>
                            <Col className="ml-auto mr-auto text-center" md="8">
                              <div className="space-top" />
                          <span style={{ fontSize: 18 }} ><b>{t("_hola")} {!loading && user != null ? user.nombre + " " + user.apellido : ""}, {t("_comprasuccess")}</b></span>                                                                                                           
                          </Col>
                    </Row>
                  <div className="space-top" />
                   </Container>
                   <Row style={{ backgroundColor: '#e0f3fa' }}>
                      <Col lg="12">
                      <div className="info info-horizontal" >
                          <div className="icon icon-primary">
                              <i className="nc-icon nc-delivery-fast" />
                          </div>
                          <div className="description">
                              <h5 className="info-title"><b>{t("_seguimiento")}</b></h5>
                              <p><b>{t("_accedecuenta")}</b></p>
                              <Button
                                  color="link"
                                  href="/usuario"                                
                              >
                                  {t("_accedetucuenta")} 
                            </Button>
                          </div>
                          </div>
                      </Col>
              </Row>   
              <Container>
                  <Row>
                      <Col className="ml-auto mr-auto text-center" md="8">
                          <div className="space-top" />
                          <Button className="btn-wd" color="danger" href="/index"> {t("_continuarcomprando")} </Button>
                      </Col>
                  </Row>
              </Container>
          </div>    
          {!loading && user != null && user.tipoOfi == TipoOficina.Venta ? <FooterATMVenta /> : <FooterATM /> }   
    </>
  );
}


export default PedidoSucces;