import React from "react";

// reactstrap components
import {
    Button,
    Container, Row, Col,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    CardImg,
    CardHeader,
    Input,
    Badge
 } from "reactstrap";

import { TipoOficina, TiposItemUrl } from "../othelloConstantes.jsx";
import { NombreContieneTextoBusqueda } from "../othelloFunciones";

// core components
import ColorNavbarATMSimple from "../components/Navbars/ColorNavbarATMSimple.js";
import FooterATMVenta from "../components/Footers/FooterATMVenta.js";
import FooterATM from "../components/Footers/FooterATM.js";

import { useTranslation } from 'react-i18next';

function Listado()
{
    const { t, i18n } = useTranslation();

    document.documentElement.classList.remove("nav-open");

    React.useEffect(() => {
        document.body.classList.add("index-page");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
        };
    });

    const [loadingIdioma, setLoadingIdioma] = React.useState(true);

    React.useEffect(() => {

        fetch('api/Data/GetIdiomaActual')
            .then(response => response.json())
            .then(data => {
                i18n.changeLanguage(data.idioma);
                setLoadingIdioma(false);
            }).catch(error => {
                i18n.changeLanguage('es');
                setLoadingIdioma(false);
            });
    }, [loadingIdioma]);

    const [items, setItems] = React.useState(null);
    const [selected, setSelected] = React.useState(-1);
    const [loading, setLoading] = React.useState(true);
    const [textoBusqueda, setTextoBusqueda] = React.useState("");

    React.useEffect(() => {
        fetch('api/Data/GetItemsListado')
            .then(response => response.json())
            .then(data => {
                setItems(data);
                setSelected(data != null && data.idsublistaselected != null ? data.idsublistaselected : 0);
                setLoading(false);
            }).catch(error => {
                setItems(null);
                setSelected(0);
                setLoading(false);
            });

    }, [loading]);

    const sublista = !loading && items != null && items.sublista != null && items.sublista.length > 0 ?
        items.sublista.map(x => {
            return <CardHeader className="card-collapse" id={x.id} role="tab" >
                <h5 className="mb-0 panel-title">
                    <a href="#" onClick={(e) => { e.preventDefault(); setSelected(x.id) }}><b>{x.nombre}</b></a>
                </h5>
            </CardHeader>
        }) : "";

    const itemsfinal = !loading && items != null && items.items != null && items.items.length > 0 ?
        items.items.map(x =>
        {                        
            if (selected == -1 || (items.sublista != null && items.sublista.length > 0 && x.idsublista == selected))
            {
                //Si el nombre del item contiene el texto de busqueda  
                if (NombreContieneTextoBusqueda(x.nombre, textoBusqueda ))
                {
                    let imagen = x.imagen != null ? x.imagen : require("../assets/img/image_placeholder.jpg");
                    //let urlclick = items.region == "1" ? "/area/" + x.nombre.replace(/\s/g, "-").toLowerCase() : "/ficha/" + items.nombre.replace(/\s/g, "-").toLowerCase() + "/" + x.nombre.replace(/\s/g, "-").toLowerCase();
                    let urlclick = items.region == "1" ? x.linkarea : x.linkpoi;


                    //
                    return <Col md="4">
                        <Card className="card-product card-plain">
                            <CardImg top tag="div">
                                {
                                    x.badgepromo ?
                                    <div style={{ position: "absolute", margin: 3 }}>
                                            <Badge color="danger">{t("_promociones")}</Badge>
                                    </div> : ""
                                }
                                <a href={urlclick}>
                                    {!loading && items.idoficina != 207 ?
                                        <img style={{ aspectRatio: "4/3", width: "100%", objectFit: "cover" }}
                                            alt="..."
                                            className="img"
                                            src={imagen}
                                        /> : <img alt="..." className="img" src={imagen} />
                                    }
                                </a> 
                            </CardImg>
                            <CardBody>
                                <a href={urlclick}><CardTitle tag="h4"><center><b>{x.nombre}</b></center></CardTitle></a>
                                <p className="card-description">
                                    <center><b>{x.descripcion}</b></center>
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                }
            }
        }) : "";
    /*
     * ITEM INICIAL
<CardBody>
<h6 className="card-category">Productivity tools</h6>
<CardTitle tag="h4"><b>
    Restaurante Amazonas</b>
</CardTitle>
<p className="card-description">
    As this technology matures it will be cool to see what
    hackers are able to do with it.
</p>
</CardBody>
<Row>
                      <Col className="ml-auto mr-auto text-center" md="8">
                          <div className="space-top" />
                          <h2 className="title">Restaurantes</h2>
                      </Col>
                  </Row>
     */
    const TextOnChange = (event) => {

        if (event.target.value != textoBusqueda)
            setTextoBusqueda(event.target.value);       
    }

  return (
      <>
          <ColorNavbarATMSimple color='danger' />
          <div className="projects-2 section section-dark" style={{ marginTop: 70 }}>
              <Container>
                  <Row>
                      <Col className="ml-auto mr-auto text-center" md="8">
                          <div className="space-top" />
                          <h2 className="title"><b>{!loading && items != null ? (items.tipolista == TiposItemUrl.Promociones ?  t("_promociones") : items.nombre) : "-"}</b></h2>
                          <hr />
                          <h5 className="description">
                              <b>{!loading && items != null ? (items.tipolista == TiposItemUrl.Promociones ? t("_nuetraspromos") : items.descripcion) : "-"}</b>
                          </h5>                          
                      </Col>
                  </Row>
              </Container>
          </div>
          <div className="section">
              <Container>
                  <h3 className="section-title"><b></b></h3>
                  <br />
                  <Row>
                      {sublista != "" ?
                          <Col md="2">
                              <Card className="card-refine">
                                  <div aria-expanded={true} aria-multiselectable={true} className="panel-group" id="accordion">
                                      {sublista}
                                  </div>
                              </Card>
                          </Col> : ""
                      }
                      <Col md={sublista != "" ? "10" : "12"}>
                          <div class="input-group" style={{ marginBottom: 35 }}>
                              <Input type="search" name="text" id="exampleText" placeholder={t('_buscar')} onChange={TextOnChange} />
                              <span class="input-group-append">
                                  <button class="btn" type="button">
                                      <i class="fa fa-search"></i>
                                  </button>
                              </span>
                              <span class="input-group-append" style={{ marginLeft: 2 }}>
                                  <Button class="btn" type="button" href={"/buscando/"}>
                                      <i class="fa fa-search"></i> {t("_avanzada")}
                                  </Button>
                              </span>
                          </div>
                          <div className="products">
                              <Row>
                                  {itemsfinal}
                              </Row>
                          </div>
                      </Col>
                  </Row>
              </Container>
          </div>
         
          {!loading && items != null && items.tipoofi == TipoOficina.Venta ? <FooterATMVenta /> : <FooterATM />}            
    </>
  );
}

export default Listado;

//Opcion anterior que solo se veia una area-subarea
//<div className="projects-3" id="projects-3" style={{ marginTop: 0 }}>
//    <Container>
//        <Row>
//            {itemsfinal}
//        </Row>
//    </Container>
//</div>