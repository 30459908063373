import React from "react";


import Select from "react-select";

// reactstrap components
import {
    Button,
    Container, Row, Col,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    CardImg,
    CardHeader,
    Input,
    Badge,
    Form,
 } from "reactstrap";

import { TipoOficina, TiposItemUrl } from "../othelloConstantes.jsx";
import { NombreContieneTextoBusqueda } from "../othelloFunciones";

// core components
import ColorNavbarATMSimple from "../components/Navbars/ColorNavbarATMSimple.js";
import FooterATMVenta from "../components/Footers/FooterATMVenta.js";
import FooterATM from "../components/Footers/FooterATM.js";

import { useTranslation } from 'react-i18next';

function Listado() {
    const { t, i18n } = useTranslation();

    document.documentElement.classList.remove("nav-open");

    React.useEffect(() => {
        document.body.classList.add("index-page");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
        };
    });

    const [loadingIdioma, setLoadingIdioma] = React.useState(true);

    React.useEffect(() => {

        fetch('api/Data/GetIdiomaActual')
            .then(response => response.json())
            .then(data => {
                i18n.changeLanguage(data.idioma);
                setLoadingIdioma(false);
            }).catch(error => {
                i18n.changeLanguage('es');
                setLoadingIdioma(false);
            });
    }, [loadingIdioma]);

    //Itme recibidos (Areas, SubAreas, etc)
    const [items, setItems] = React.useState(null);

    //Combo principal de busqueda
    const [itemsComboPrincipal, setItemsComboPrincipal] = React.useState([]);
    const [itemcomboprincipalSelected, setitemcomboprincipalSelected] = React.useState(-1);

    //Combo secundario
    const [itemsComboSecun, setItemsComboSecun] = React.useState([]);
    const [itemcombosecunSelected, setitemcombosecunSelected] = React.useState(-1);

    const [itemsResultado, setItemsResultado] = React.useState(null);

    //Otros    
    const [loading, setLoading] = React.useState(true);
    const [textoBusqueda, setTextoBusqueda] = React.useState("");

    const [loadingSearching, setLoadingSearching] = React.useState(false);

    const [savedataerror, setDataError] = React.useState(false);

    React.useEffect(() => {
        fetch('api/Data/GetItemsListado')
            .then(response => response.json())
            .then(data => {
                setItems(data);
                //setSelected(data != null && data.idsublistaselected != null ? data.idsublistaselected : 0);

                if (data) {

                    if (items != null && items.items != null && items.items.length > 0) {
                        let combopciones = [];

                        combopciones.push({
                            value: -1,
                            label: "Todos"
                        });

                        items.items.map(x => {
                            combopciones.push({
                                value: x.id,
                                label: x.nombre
                            });
                        });

                        setItemsComboPrincipal(combopciones);
                    }
                }

                setLoading(false);
            }).catch(error => {
                setItems(null);
                setLoading(false);
            });

    }, [loading]);


    function GetComboSecundarioDesdePrinciapalSelected(seleccion) {
        let subopciones = [];

        if (seleccion != null && seleccion.value > 0) {

            var itemseleccion = items.items.find(x => x.id == seleccion.value);

            if (itemseleccion != null && itemseleccion.sublista != null) {
                subopciones.push({
                    value: -1,
                    label: "Todos"
                });

                if (itemseleccion.sublista.length > 0) {
                    itemseleccion.sublista.map(x => {
                        subopciones.push({
                            value: x.id,
                            label: x.nombre
                        });
                    });
                }
            }
        }

        setitemcombosecunSelected(-1);
        setItemsComboSecun(subopciones);
        setitemcomboprincipalSelected(seleccion);
    }


    function ObtenerResultados(event) {
        event.preventDefault();

        setLoadingSearching(true);

        let formData = new FormData(event.target);

        fetch('api/Data/GetResultadosBusquedaAvanzada', {
            method: 'POST',
            body: formData,

        }).then(response => response.json()).then(res =>
        {
            if (res == null) {
                setDataError(true);
                setItemsResultado([]);
            }
            else
                setItemsResultado(res);
                

            setLoadingSearching(false);

        }).catch(error => {

            setItemsResultado([]);
            setDataError(true);
            setLoadingSearching(false);
        });
    }

    /* const sublista = !loading && items != null && items.sublista != null && items.sublista.length > 0 ?
         items.sublista.map(x => {
             return <CardHeader className="card-collapse" id={x.id} role="tab" >
                 <h5 className="mb-0 panel-title">
                     <a href="#" onClick={(e) => { e.preventDefault(); setSelected(x.id) }}><b>{x.nombre}</b></a>
                 </h5>
             </CardHeader>
         }) : "";*/


    const itemsfinal = !loadingSearching && itemsResultado != null && itemsResultado.length > 0 ?
        itemsResultado.map(x =>
        {
            //Si el nombre del item contiene el texto de busqueda  
            if (NombreContieneTextoBusqueda(x.nombre, textoBusqueda)) {
                let imagen = x.imagen != null ? x.imagen : require("../assets/img/image_placeholder.jpg");
                //let urlclick = items.region == "1" ? "/area/" + x.nombre.replace(/\s/g, "-").toLowerCase() : "/ficha/" + items.nombre.replace(/\s/g, "-").toLowerCase() + "/" + x.nombre.replace(/\s/g, "-").toLowerCase();
                let urlclick = items.region == "1" ? x.linkarea : x.linkpoi;

                //
                return <Col md="4">
                    <Card className="card-product card-plain">
                        <CardImg top tag="div">
                            {
                                x.badgepromo ?
                                    <div style={{ position: "absolute", margin: 3 }}>
                                        <Badge color="danger">{t("_promociones")}</Badge>
                                    </div> : ""
                            }
                            <a href={urlclick}>
                                {!loading && items.idoficina != 207 ?
                                    <img style={{ aspectRatio: "4/3", width: "100%", objectFit: "cover" }}
                                        alt="..."
                                        className="img"
                                        src={imagen}
                                    /> : <img alt="..." className="img" src={imagen} />
                                }
                            </a>
                        </CardImg>
                        <CardBody>
                            <a href={urlclick}><CardTitle tag="h4"><center><b>{x.nombre}</b></center></CardTitle></a>
                            <p className="card-description">
                                <center><b>{x.descripcion}</b></center>
                            </p>
                        </CardBody>
                    </Card>
                </Col>
            }

        }) : "";

    const TextOnChange = (event) => {

        if (event.target.value != textoBusqueda)
            setTextoBusqueda(event.target.value);
    }

    return (
        <>
            <ColorNavbarATMSimple color='danger' />
            <div className="projects-2 section section-dark" style={{ marginTop: 70 }}>
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="8">
                            <div className="space-top" />
                            <h2 className="title"><b>{t("_busquedaavanzada")}</b></h2>
                            {/*<h2 className="title"><b>Busqueda Avanzada{!loading && items != null ? (items.tipolista == TiposItemUrl.Promociones ?  t("_promociones") : items.nombre) : "-"}</b></h2>*/}
                            <hr />
                            <h5 className="description">
                                <b>{!loading && items != null ? (items.tipolista == TiposItemUrl.Promociones ? t("_nuetraspromos") : items.descripcion) : "-"}</b>
                            </h5>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="section">
                <Container>
                    <h3 className="section-title"><b></b></h3>
                    <Card className="card-with-shadow">
                        <CardBody>
                            <div >
                                <span style={{ fontSize: 17, fontWeight: "bold" }}>
                                    {t("_opcionesbusqueda")}
                                </span>
                                <div style={{ backgroundColor: '#fc030f', height: 5, width: 50, marginTop: 5 }} />
                            </div>
                            <br />
                            <Row>
                                <Col className="ml-auto mr-auto" >
                                    <Form className="formmidirecc" onSubmit={(e) => { e.preventDefault(); ObtenerResultados(e) }}>
                                        <Row>
                                            <Col md="6">
                                                <Select
                                                    className="react-select react-select-primary"
                                                    classNamePrefix="react-select"
                                                    id="id"
                                                    name="id"
                                                    value={itemcomboprincipalSelected}
                                                    onChange={value => GetComboSecundarioDesdePrinciapalSelected(value)}
                                                    options={itemsComboPrincipal}
                                                    placeholder="Seleccione Seccion" />
                                            </Col>
                                            {itemsComboSecun != null && itemsComboSecun.length > 0 ?
                                                <Col md="6">
                                                    <Select
                                                        className="react-select react-select-primary"
                                                        classNamePrefix="react-select"
                                                        id="idsublista"
                                                        name="idsublista"
                                                        value={itemcombosecunSelected}
                                                        onChange={value => setitemcombosecunSelected(value)}
                                                        options={itemsComboSecun}
                                                        placeholder="Seleccione Seccion" />
                                                </Col> : ""}
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col md="6">
                                                <Input className="border-input" placeholder={t("_textobuscar")} type="text" id="texto" name="texto" />
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col md="12">
                                                <div className="pull-right">
                                                    <Button className="btn-wd" color="danger" type="submit" >{t("_buscar")}</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <br />
                    <Row>                        
                        <Col md={"12"}>
                            {itemsfinal != null && itemsfinal.length > 0 ?
                                <div className="pull-center" style={{ marginBottom: 35 }}>
                                
                                    <Input type="search" name="text" id="exampleText" placeholder={t('_filtraresultado')} onChange={TextOnChange} />
                                </div> : ""}
                        </Col>
                    </Row>
                    <Row>                        
                        <Col md={"12"}>                            
                            <div className="products">
                                <Row>
                                    {itemsfinal}
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={"12"}>
                            <div className="pull-center">
                                {savedataerror ? <center><span className='text-danger'><b>{t("_nofiltrosbusquedaoerror")}</b></span></center> : ""}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {!loading && items != null && items.tipoofi == TipoOficina.Venta ? <FooterATMVenta /> : <FooterATM />}
        </>
    );
}

export default Listado;