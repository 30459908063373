import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardTitle,
    Form,
    Input,
    Container,
    Row,
    Col,
    FormGroup,
    Label
} from "reactstrap";

import ColorNavbarATMVacio from "../components/Navbars/ColorNavbarATMVacio.js";

import { useTranslation } from 'react-i18next';

function RecoveryPass() {

    const { t, i18n } = useTranslation();

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("register-page");
        document.body.classList.add("full-screen");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("register-page");
            document.body.classList.remove("full-screen");
        };
    });

    const [loadingIdioma, setLoadingIdioma] = React.useState(true);

    React.useEffect(() => {

        fetch('api/Data/GetIdiomaActual')
            .then(response => response.json())
            .then(data => {
                i18n.changeLanguage(data.idioma);
                setLoadingIdioma(false);
            }).catch(error => {
                i18n.changeLanguage('es');
                setLoadingIdioma(false);
            });
    }, [loadingIdioma]);

    const [robot, setRobot] = React.useState(false);
    const [remembererror, setRememberError] = React.useState(false);

    function RecordarPass(event)
    {
        //Si acepto la politica de privacidad
        if (robot) {
            event.preventDefault();
            //this.setState({ loading: true });
            //let formulario = document.getElementById('formlogin');
            let formData = new FormData(event.target);

            fetch('api/Data/RecordarPassUsuario', {
                method: 'POST',
                body: formData,

            }).then(response => response.json()).then(res => {
                //Si hubo error
                if (!res)
                    setRememberError(true);
                else {
                    // setLoginError(false);
                    //this.props.history.push('admin/dashboard')
                    window.location.assign('/login');
                }
            }).catch(error => {
                setRememberError(true);
            });
        }
        else
            setRememberError(true);
    }

    return (
        <>
            <ColorNavbarATMVacio />
            <div className="wrapper">
                <div
                    className="page-header"
                    style={{
                        backgroundImage:
                            "url(" + require("../assets/img/sections/bruno-abatti.jpg") + ")",
                    }}
                >
                    <div className="filter" />
                    <Container>
                        <Row>                            
                            <Col className="ml-auto mr-auto" lg="4" md="6" sm="5" xs="12">
                                <Card className="card-register">
                                    <CardTitle className="text-center" tag="h3">
                                        <span style={{ fontSize: 20, fontWeight: "bold" }}>
                                            <b>{t("_recuperarpass")}</b>
                                        </span>
                                    </CardTitle>
                                    <br/>
                                    <span style={{ fontSize: 13, color: "grey" }}><b>{t("_addmailrecuperarpass")}</b></span>
                                    <br />
                                        <div>
                                        <Form className="register-form" onSubmit={(e) => { e.preventDefault(); RecordarPass(e) }}>
                                            <Input placeholder={t("_email")} type="text" id="email" name="email"/>   
                                            <b>
                                                <FormGroup check>
                                                <Label check style={{ color: '#706b6b' }}>
                                                    <Input defaultValue="" type="checkbox" onClick={(e) => { setRobot(!robot); }}  />
                                                        {t("_nosoyrobot")} 
                                                    <span className="form-check-sign" />
                                                    </Label>
                                                </FormGroup>
                                            </b>
                                            {remembererror ? <center><span className='text-danger'><b>{t("_nosehizooperacion")} </b></span></center> : "" }
                                            <Button block className="btn-wd" color="info" type="submit">
                                                {t("_recuperarpass")}
                                            </Button>
                                            <br/>
                                            <a style={{ textDecoration: "underline" }} href="/login"><b>{t("_backinisesion")}</b></a>
                                            </Form>                                           
                                        </div>                                                                           
                                </Card>
                            </Col>
                        </Row>
                    </Container>

                    <div className="demo-footer text-center">
                        <h6>
                            <center><b>  � Copyright {new Date().getFullYear()} Aplicaciones Turisticas en Movilidad</b></center>
                        </h6>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RecoveryPass;


//function Listado()
//{

//    document.documentElement.classList.remove("nav-open");

//    React.useEffect(() => {
//        document.body.classList.add("index-page");
//        window.scrollTo(0, 0);
//        document.body.scrollTop = 0;
//        return function cleanup() {
//            document.body.classList.remove("index-page");
//        };
//    });

//    const [oficina, setOficina] = React.useState(null);
//    const [loading, setLoading] = React.useState(true);

//    React.useEffect(() => {
//        fetch('api/Data/GetOficinaVirtual')
//            .then(response => response.json())
//            .then(data => {
//                setOficina(data);
//                setLoading(false)
//            }).catch(error => {
//                setOficina(null);
//                setLoading(false)
//            });

//    }, [loading]);

//    const oficinaclick = () => {
//        setLoading(true);
//    };

//    /*
//     * ITEM INICIAL
//<CardBody>
//<h6 className="card-category">Productivity tools</h6>
//<CardTitle tag="h4"><b>
//    Restaurante Amazonas</b>
//</CardTitle>
//<p className="card-description">
//    As this technology matures it will be cool to see what
//    hackers are able to do with it.
//</p>
//</CardBody>

//     */

//  return (
//      <>
//          <ColorNavbarATMSimple color='danger'/>

//          <div className="projects-3" id="projects-3" style={{ marginTop: 20}}>
//              <Container>
//                  <Row>
//                      <Col className="ml-auto mr-auto text-center" md="8">
//                          <div className="space-top" />                        
//                          <h2 className="title">Restaurantes</h2>
//                      </Col>
//                  </Row>
//                  <Row>
//                      <Col md="4">
//                          <Card className="card-profile card-plain">
//                              <CardImg top tag="div">
//                                  <a href="/ficha/test">
//                                      <img
//                                          alt="..."
//                                          className="img"
//                                          src={require("../assets/img/sections/angelo-pantazis.jpg")}
//                                      />
//                                  </a>
//                              </CardImg>
//                              <CardBody>                                  
//                                  <CardTitle tag="h4"><b>Restaurante Amazonas</b></CardTitle>
//                                  <p className="card-description">
//                                    <b>  As this technology matures it will be cool to see what
//                                      hackers are able to do with it.</b>
//                                    </p>
//                              </CardBody>
//                          </Card>
//                      </Col>
//                      <Col md="4">
//                          <Card className="card-profile card-plain">
//                              <CardImg top tag="div">
//                                  <a
//                                      href="http://www.creative-tim.com/product/paper-kit-react?ref=pkpr-sections-page"
//                                      target="_blank"
//                                  >
//                                      <img
//                                          alt="..."
//                                          className="img"
//                                          src={require("../assets/img/sections/patrick-tomasso.jpg")}
//                                      />
//                                  </a>
//                              </CardImg>
//                              <CardBody>
//                                  <h6 className="card-category">Web Design</h6>
//                                  <CardTitle tag="h4">
//                                      Famous Website Redesign Implementation
//                    </CardTitle>
//                                  <p className="card-description">
//                                      Streaming services once again top the list of this year�s
//                                      Emmy nominations
//                    </p>
//                              </CardBody>
//                          </Card>
//                      </Col>
//                      <Col md="4">
//                          <Card className="card-profile card-plain">
//                              <CardImg top tag="div">
//                                  <a
//                                      href="http://www.creative-tim.com/product/paper-kit-react?ref=pkpr-sections-page"
//                                      target="_blank"
//                                  >
//                                      <img
//                                          alt="..."
//                                          className="img"
//                                          src={require("../assets/img/sections/luke-chesser.jpg")}
//                                      />
//                                  </a>
//                              </CardImg>
//                              <CardBody>
//                                  <h6 className="card-category">Marketing tools</h6>
//                                  <CardTitle tag="h4">
//                                      The Best Productivity Applications
//                    </CardTitle>
//                                  <p className="card-description">
//                                      Dietary supplements don�t need approval from the Food and
//                                      Drug Administration.
//                    </p>
//                              </CardBody>
//                          </Card>
//                      </Col>
//                      <Col md="4">
//                          <Card className="card-profile card-plain">
//                              <CardImg top tag="div">
//                                  <a
//                                      href="http://www.creative-tim.com/product/paper-kit-react?ref=pkpr-sections-page"
//                                      target="_blank"
//                                  >
//                                      <img
//                                          alt="..."
//                                          className="img"
//                                          src={require("../assets/img/sections/rawpixel-coms.jpg")}
//                                      />
//                                  </a>
//                              </CardImg>
//                              <CardBody>
//                                  <h6 className="card-category">Website</h6>
//                                  <CardTitle tag="h4">Behance Redesign</CardTitle>
//                                  <p className="card-description">
//                                      The HDR rollout is being enabled via support from two
//                                      major studio partners.
//                    </p>
//                              </CardBody>
//                          </Card>
//                      </Col>
//                      <Col md="4">
//                          <Card className="card-profile card-plain">
//                              <CardImg top tag="div">
//                                  <a
//                                      href="http://www.creative-tim.com/product/paper-kit-react?ref=pkpr-sections-page"
//                                      target="_blank"
//                                  >
//                                      <img
//                                          alt="..."
//                                          className="img"
//                                          src={require("../assets/img/sections/patrick-tomasso.jpg")}
//                                      />
//                                  </a>
//                              </CardImg>
//                              <CardBody>
//                                  <h6 className="card-category">Android App</h6>
//                                  <CardTitle tag="h4">Analytics for Android</CardTitle>
//                                  <p className="card-description">
//                                      Google did not offer an estimate as to when HDR support
//                                      reaches its other global markets.
//                    </p>
//                              </CardBody>
//                          </Card>
//                      </Col>
//                      <Col md="4">
//                          <Card className="card-profile card-plain">
//                              <CardImg top tag="div">
//                                  <a
//                                      href="http://www.creative-tim.com/product/paper-kit-react?ref=pkpr-sections-page"
//                                      target="_blank"
//                                  >
//                                      <img
//                                          alt="..."
//                                          className="img"
//                                          src={require("../assets/img/sections/rawpixel-coms.jpg")}
//                                      />
//                                  </a>
//                              </CardImg>
//                              <CardBody>
//                                  <h6 className="card-category">Material</h6>
//                                  <CardTitle tag="h4">How to find the contacts.</CardTitle>
//                                  <p className="card-description">
//                                      Boom, the invitations start flying and Brella makes it
//                                      easy to accept/decline
//                    </p>
//                              </CardBody>
//                          </Card>
//                      </Col>
//                  </Row>
//              </Container>
//          </div>
      
//      <FooterVenta />
//    </>
//  );
//}

//export default Listado;
