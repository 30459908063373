import React from "react";

// reactstrap components
import {
    Container, Row, Col,
    Card,
    CardBody,   
    CardTitle,
    CardImg,
    Button
} from "reactstrap";

import ItemPromocion from "../../view/ItemPromocion.js";
import { useTranslation } from 'react-i18next';
import vegetariana from "../../assets/img/venta/vegetariana.jpg";
import singluten from "../../assets/img/venta/singluten.jpg";
import frescos from "../../assets/img/venta/frescos.jpg";
import carnes from "../../assets/img/venta/carnes.jpg";

import apreton_manos from "../../assets/img/venta/apreton_manos.png";
import elige_comercio from "../../assets/img/venta/elige_comercio.png";
import cientos_de_comercios from "../../assets/img/venta/cientos_de_comercios.png";
import compra_en_casa from "../../assets/img/venta/compra_en_casa.png";
import fotoback from "../../assets/img/sections/the-how-photographer.jpg";

import Justificacionmojacar from "../../assets/img/Justificacionmojacar.png"

function BodyATMVenta()
{
    const { t } = useTranslation();

    const [areas, setAreas] = React.useState(null);
    const [loading, setLoading] = React.useState(true);   
    const [loadingofi, setLoadingOfi] = React.useState(true);   

    const [oficina, setOficina] = React.useState(null);
    const [promociones, setPromociones] = React.useState(null);
    const [loadingPromociones, setLoadingPromociones] = React.useState(true);

    React.useEffect(() => {
        fetch('api/Data/GetOficinaVirtual')
            .then(response => response.json())
            .then(data => {
                setOficina(data);
                setLoadingOfi(false)
            }).catch(error => {
                setOficina(null);
                setLoadingOfi(false)
            });

    }, [loadingofi]);

    React.useEffect(() => {
        fetch('api/Data/GetAreasDestacadas')
            .then(response => response.json())
            .then(data => {
                setAreas(data);
                setLoading(false)
            }).catch(error => {
                setAreas(null); 
                setLoading(false)
            });

    }, [loading]);

    React.useEffect(() => {
            fetch('api/Data/GetPromociones')
                .then(response => response.json())
                .then(data => {
                    setPromociones(data);
                    setLoadingPromociones(false)
                }).catch(error => {
                    setPromociones(null);
                    setLoadingPromociones(false)
                });

    }, [loadingPromociones]);

    const areacocina = !loading && areas != null && areas.length > 0 ? renderareacocina() : "";
       
    var titulo = "";
    var descripcion = "";
    var col1titulo = "";
    var col2titulo = "";
    var col3titulo = "";
    var col4titulo = "";
    var col1imagen = "";
    var col2imagen = "";
    var col3imagen = "";
    var col4imagen = "";

    if (oficina != null)
    {
        //Si estamos con la oficina de muxamel
        if (oficina.id_Oficina == 376 || oficina.id_Oficina == 114 || oficina.id_Oficina == 384)
        {
            titulo = <>{t("_tus")} <color style={{ color: '#fc030f' }}>{t("_comerciossiempre")}</color> {t("_ahoraencasa")}</>;
            descripcion =  t("_eligecomeryempieza");
            col1titulo = <>{t("_conciertacomerciopago")}</>;
            col2titulo =  t("_apoyacomercio") ;
            col3titulo =  t("_cientoscomercios");
            col4titulo = t("_comprasinsalir");
            col1imagen = apreton_manos;
            col2imagen = elige_comercio;
            col3imagen = cientos_de_comercios;
            col4imagen = compra_en_casa;
        }
        else if (oficina.id_Oficina == 13)
        {
            titulo = <><color style={{ color: '#fc030f' }}>{t("_mojacarlotiene")}</color>. {t("_compraloaqui")}</>;
            descripcion = t("_eligecomeryempieza");
            col1titulo = <>{t("_conciertacomerciopago")}</>;
            col2titulo = t("_apoyacomercio");
            col3titulo = t("_cientoscomercios");
            col4titulo = t("_comprasinsalir");
            col1imagen = apreton_manos;
            col2imagen = elige_comercio;
            col3imagen = cientos_de_comercios;
            col4imagen = compra_en_casa;
        }
        else
        {
            titulo = <>Tus <color style={{ color: '#fc030f' }}>Restaurantes Online en un &uacute;nico</color> lugar</>;
            descripcion = "Cocinas Populares";
            col1titulo = "Comida vegetariana";
            col2titulo = "Sin gluten";
            col3titulo = "Ingredientes frescos";
            col4titulo = "Carnes de calidad";
            col1imagen = vegetariana;
            col2imagen = singluten;
            col3imagen = frescos;
            col4imagen = carnes;
        }
    }     

    const promocionespoi = !loadingPromociones && promociones != null && promociones.length > 0 ?
            promociones.map(x =>
            {
                return <Col className="ml-auto mr-auto" md="4"><ItemPromocion promo={x} desdepoi={false} /></Col>
            }) : "";

    function renderareacocina()
    {        
       let valores = areas.map((x, index) =>
        {
            let imagen = x.imageAux != null ? x.imageAux : require("../../assets/img/image_placeholder.jpg");;
            //let urlclick = "/area/" + x.cod_Area.replace(/\s/g, "-").toLowerCase();

            //Si es mojacar
           if (x.id_Oficina == 13)
           {
               let posicion = index + 1;
               let ColumaPar = posicion % 2 === 0;
               const quotient = posicion / 2;
               const FilaPar = Math.ceil(quotient) % 2 === 0;

               let valorMd = "7";

               if (FilaPar)
                   valorMd = ColumaPar ? "5" : "7";
               else
                   valorMd = ColumaPar ? "7" : "5";


               return <Col md={valorMd}>
                   <Card
                       data-background="image"
                       style={{
                           backgroundImage:
                               "url(" + imagen + ")",
                       }}
                   >
                       <a href={x.linkarea}>
                           <CardBody style={{ textAlign: "center" }}>
                               {/*<h6 className="card-category">Productivy Apps</h6>*/}
                               <CardTitle tag="h3" style={{ marginTop: 50 }}><b>{x.cod_Area.toUpperCase()}</b></CardTitle>
                               <br />
                               {/*<p className="card-description">*/}
                               {/*     desscripcion                                   */}
                               {/*</p>*/}
                               <Button
                                   className="btn-round"
                                   color="dribbble"
                                   href={x.linkarea}
                               >
                                   {t("_vermas")}
                               </Button>
                           </CardBody>
                       </a>
                   </Card>
               </Col>
           }
           else {
               return <Col md="3">
                   <Card data-background="image" style={{ backgroundImage: "url(" + imagen + ")" }}>
                       <a href={x.linkarea}>
                           <CardBody style={{ textAlign: "center", }}>
                               <p style={{ marginTop: 80 }}><h5><b>{x.cod_Area.toUpperCase()}</b></h5></p>
                           </CardBody>
                       </a>
                   </Card>
               </Col>
           }
        });

        return <Container><Row>{valores}</Row></Container>;
    }

    function renderInformacionTiendaMojacar() {
        return <div
            className="features-5 section-dark" /*section-dark*/
            style={{
                //backgroundImage: "url(" + fotoback + ")",
            }}
        >
            <Container>
                <Row>
                    <div className="ml-auto mr-auto">
                        <h2 className="title text-center">
                            {t("_comprasinmoverte")}
                        </h2>
                    </div>
                </Row>
                <Row>
                    <Col className="ml-auto" sm="5">
                        <div className="info">
                            <div className="icon">
                                <i aria-hidden={true} className="nc-icon nc-laptop" />
                            </div>
                            <div className="description">
                                <h4 className="info-title"><b>{t("_compradesdecasa")}</b></h4>
                                <p>
                                    <b>{t("_formafacil")}</b>
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col className="mr-auto" sm="5">
                        <div className="info">
                            <div className="icon">
                                <i aria-hidden={true} className="nc-icon nc-box-2" />
                            </div>
                            <div className="description">
                                <h4 className="info-title"><b>{t("_hazpedido")}</b></h4>
                                <p>
                                    <b>{t("_dopedidosencillo")}</b>
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="bottom-line">
                    <Col className="ml-auto" sm="5">
                        <div className="info">
                            <div className="icon">
                                <i aria-hidden={true} className="nc-icon nc-time-alarm" />
                            </div>
                            <div className="description">
                                <h4 className="info-title"><b>{t("_recuerda")}</b></h4>
                                <p>
                                    <b>{t("_nosepuedecompracombinada")}</b>
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col className="mr-auto" sm="5">
                        <div className="info">
                            <div className="icon">
                                <i aria-hidden={true} className="nc-icon nc-delivery-fast" />
                            </div>
                            <div className="description">
                                <h4 className="info-title"><b>{t("_recogidadomicioliio")}</b></h4>
                                <p>
                                    <b>{t("_obtenpedido")}</b>
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    }

    function renderInformacionTiendaMuxamelPelayos() {
        return <Row className="card-body-row">
            <>
                <Col md="3" sm="3">
                    <div className="info">
                        <div className="icon icon-info">
                            <i className="nc-icon nc-satisfied" />
                        </div>
                        <div className="description">
                            <h4 className="info-title"><b>{t("_compradesdecasa")}</b></h4>
                            <p>
                                <b>{t("_formafacil")}</b>
                            </p>
                        </div>
                    </div>
                </Col>
                <Col md="3" sm="3">
                    <div className="info">
                        <div className="icon icon-danger">
                            <i className="nc-icon nc-tap-01" />
                        </div>
                        <div className="description">
                            <h4 className="info-title"><b>{t("_hazpedido")}</b></h4>
                            <p>
                                <b>{t("_dopedidosencillo")}</b>
                            </p>
                        </div>
                    </div>
                </Col>
                <Col md="3" sm="3">
                    <div className="info">
                        <div className="icon icon-success">
                            <i className="nc-icon nc-bell-55" />
                        </div>
                        <div className="description">
                            <h4 className="info-title"><b>{t("_recuerda")}</b></h4>
                            <p>
                                <b>{t("_nosepuedecompracombinada")}</b>
                            </p>
                        </div>
                    </div>
                </Col>
                <Col md="3" sm="3">
                    <div className="info">
                        <div className="icon icon-warning">
                            <i className="nc-icon nc-basket" />
                        </div>
                        <div className="description">
                            <h4 className="info-title"><b>{t("_recogidadomicioliio")}</b></h4>
                            <p>
                                <b>{t("_obtenpedido")}</b>
                            </p>
                        </div>
                    </div>
                </Col>
            </>
        </Row>;
    }

    function renderInformacionTiendaGlobal() {
        return <Row className="card-body-row">
            <>
                <Col md="3" sm="3">
                    <div className="info">
                        <div className="icon icon-info">
                            <i className="nc-icon nc-basket" />
                        </div>
                        <div className="description">
                            <h4 className="info-title"><b>Recogida/Domicilio</b></h4>
                            <p>
                                <b>Obt&eacute;n tu pedido de la forma que m&aacute;s te convenga.</b>
                            </p>
                        </div>
                    </div>
                </Col>
                <Col md="3" sm="3">
                    <div className="info">
                        <div className="icon icon-danger">
                            <i className="nc-icon nc-satisfied" />
                        </div>
                        <div className="description">
                            <h4 className="info-title"><b>Cientos de platos</b></h4>
                            <p>
                                <b>Disfruta de todo tipo de comida en un click.</b>
                            </p>
                        </div>
                    </div>
                </Col>
                <Col md="3" sm="3">
                    <div className="info">
                        <div className="icon icon-success">
                            <i className="nc-icon nc-tap-01" />
                        </div>
                        <div className="description">
                            <h4 className="info-title"><b>Haz tu Pedido</b></h4>
                            <p>
                                <b>Realiza tu pedido sencillamente en unos pocos pasos.</b>
                            </p>
                        </div>
                    </div>
                </Col>
                <Col md="3" sm="3">
                    <div className="info">
                        <div className="icon icon-warning">
                            <i className="nc-icon nc-delivery-fast" />
                        </div>
                        <div className="description">
                            <h4 className="info-title"><b>Entrega R&aacute;pida</b></h4>
                            <p>
                                <b>Recibe tu pedido gracias a los mejores profesionales.</b>
                            </p>
                        </div>
                    </div>
                </Col>
            </>
        </Row>;
    }

    function renderInformacionTienda(titulo, descripcion) {

        let retorno = "";

        if (!loadingofi && oficina != null && oficina.tienePoisVentaActiva != "0") {
            //Si es mojacar
            if (oficina.id_Oficina == 13)
            {
                retorno = renderInformacionTiendaMojacar();

                return <>{retorno}
                    <Container >
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="10">
                            <h2 className="title" style={{ fontWeight: 'bold', marginTop: 80 }}>{titulo}</h2>
                            <h4 className="description"><b>{descripcion}</b></h4>
                        </Col>
                    </Row>
                </Container></>
            }
            else {
                //Si es muxamel, Pelayos de la Presa Comercios
                if (oficina.id_Oficina == 376 || oficina.id_Oficina == 114 || oficina.id_Oficina == 384)
                    retorno = renderInformacionTiendaMuxamelPelayos();
                else
                    retorno = renderInformacionTiendaGlobal();

                return <Container>
                    {retorno}
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="10">
                            <h2 className="title" style={{ fontWeight: 'bold', marginTop: 80 }}>{titulo}</h2>
                            <h4 className="description"><b>{descripcion}</b></h4>
                        </Col>
                    </Row>
                </Container>
            }
        }
        else
            return "";
    }    


    function renderOpcionEspecial() {

        if (oficina != null && oficina.id_Oficina == 13) {

            return <Container>              
                <Row>                    
                    <Col className="ml-auto mr-auto text-center" md="12">
                        <a target="_blank" href="https://caminolebaniego.com/camino-lebaniego/ano-jubilar-lebaniego"> <img src={Justificacionmojacar} width="100%" alt="Logo" style={{ marginTop: 20, marginBottom: 10 }} /> </a>
                    </Col>
                </Row>
            </Container>;
        }
        else
            return "";
    }

    return (
        <>
            {renderInformacionTienda(titulo, descripcion)}                
          <div className="features-3">
              <Container>
                  <Row>
                      {areacocina}
                  </Row>
              </Container>
            </div>
            {!loadingofi && oficina != null && oficina.id_Oficina != 13 ?
                <Container>
                    <Row>
                        <Col md="3">
                            <Card className="card-profile card-plain">
                                <CardImg top tag="div">
                                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img
                                            alt="..."
                                            className="img"
                                            src={col1imagen}
                                            style={{ aspectRatio: "4/3", width: "100%", objectFit: "cover" }}
                                        />
                                    </a>
                                </CardImg>
                                <CardBody>
                                    <CardTitle tag="h5" style={{ fontWeight: 'bold' }}>{col1titulo}</CardTitle>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="3">
                            <Card className="card-profile card-plain">
                                <CardImg top tag="div">
                                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img
                                            alt="..."
                                            className="img"
                                            src={col2imagen}
                                            style={{ aspectRatio: "4/3", width: "100%", objectFit: "cover" }}
                                        />
                                    </a>
                                </CardImg>
                                <CardBody>
                                    <CardTitle tag="h5" style={{ fontWeight: 'bold' }}>{col2titulo}</CardTitle>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="3">
                            <Card className="card-profile card-plain">
                                <CardImg top tag="div">
                                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img
                                            alt="..."
                                            className="img"
                                            src={col3imagen}
                                            style={{ aspectRatio: "4/3", width: "100%", objectFit: "cover" }}
                                        />
                                    </a>
                                </CardImg>
                                <CardBody>
                                    <CardTitle tag="h5" style={{ fontWeight: 'bold' }}>{col3titulo}</CardTitle>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="3">
                            <Card className="card-profile card-plain">
                                <CardImg top tag="div">
                                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img
                                            alt="..."
                                            className="img"
                                            src={col4imagen}
                                            style={{ aspectRatio: "4/3", width: "100%", objectFit: "cover" }}
                                        />
                                    </a>
                                </CardImg>
                                <CardBody>
                                    <CardTitle tag="h5" style={{ fontWeight: 'bold' }}>{col4titulo}</CardTitle>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container> : ""
            }
            {promocionespoi != null && promocionespoi != "" ?
                <>
                    <div className="space-top" />
                <div className="projects-2 section section-gray">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="8">
                                <h2 className="title"><b>{t("_promociones")}</b></h2>
                                <h5 className="description">
                                    <b>{t("_nuetraspromos")}</b>
                                </h5>
                            </Col>
                        </Row>
                        <br />
                        <div className="space-top" />
                        <Row>
                            <Col className="ml-auto mr-auto" md="12">
                                <Row>
                                    {promocionespoi}
                                </Row>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="4">
                                <center>
                                    <Button color="danger" size="lg" href={"/promociones"}>
                                        {t("_maspromociones")}
                                    </Button>
                                </center>
                            </Col>
                        </Row>
                    </Container>
                    </div></> : ""}
            {renderOpcionEspecial()}
    </>
  );
}

export default BodyATMVenta;
