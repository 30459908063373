import React from "react";
// reactstrap components
import {
    Button,
    Form,
    Input,
    Modal,
    Container,   
    Row,
    Col
} from "reactstrap";

import { useTranslation } from 'react-i18next';

function DireccionModal({...props })
{
    const { t } = useTranslation();

    //variables de las propiedas recibidas
    const { open, setModal, id, loadingpage} = props;

    const [direc, setDireccion] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {

        var jsonData = JSON.stringify(id);

        fetch('api/Data/GetDireccion', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: jsonData

        }).then(response => response.json()).then(data => {
            setDireccion(data);
            setLoading(false)
        }).catch(error => {
            setDireccion(null);
            setLoading(false)
        });
    }, [loading]);

    
    function GuardarDireccion(event) {
        event.preventDefault();
        
        let formData = new FormData(event.target);

        fetch('api/Data/GuardarDireccion', {
            method: 'POST',
            body: formData,

        }).then(response => response.json()).then(res => {
            //Si hubo error
            if (!res)
                setSaveError(true);
            else {
                setModal(-1);

                //Si me llego el evento de recarga de pagina le digo que actualice
                if (loadingpage != null)
                    loadingpage(true);
            }

        }).catch(error => {
            setSaveError(true);
        });
    }

    const [saveerror, setSaveError] = React.useState(false);


    return (
        <>           
            <Modal isOpen={open} toggle={() => setModal(false)}>    
                <div className="modal-header">
                    <span style={{ fontSize: 15, fontWeight: "bold" }}>
                        <b>{t("_addeditdirecc")}</b>
                    </span>
                </div>
               <br/>
                    <Container>
                      
                    <Form className="formmidirecc" onSubmit={(e) => { e.preventDefault(); GuardarDireccion(e) }}>
                        <input id="id_Usuario" name="id_Usuario" type="hidden" value={!loading && direc != null ? direc.id_Usuario : ""} />
                        <input id="id_Direccion" name="id_Direccion" type="hidden" value={!loading && direc != null ? direc.id_Direccion : ""} />
                        <Row>
                            <Col md="6">                                
                                <Input className="border-input" defaultValue={!loading && direc != null ? direc.alias : ""} placeholder={t("_alias") + " *"} type="text" id="alias" name="alias" />
                            </Col>                            
                        </Row>
                        <br/>
                        <Row>
                            <Col md="6">
                                <Input className="border-input" defaultValue={!loading && direc != null ? direc.nombre : ""} placeholder={t("_nombre") + " *"} type="text" id="nombre" name="nombre" />
                            </Col>
                            <Col md="6">
                                <Input className="border-input" defaultValue={!loading && direc != null ? direc.apellidos : ""} placeholder={t("_apellidos") + " *"} type="text" id="apellidos" name="apellidos" />
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md="6">
                                <Input className="border-input" defaultValue={!loading && direc != null ? direc.niF_CIF : ""} placeholder={t("_cifnif") + " *"} type="text" id="nIF_CIF" name="nIF_CIF" />
                            </Col>
                            <Col md="6">
                                <Input className="border-input" defaultValue={!loading && direc != null ? direc.empresa : ""} placeholder={t("_empresa") + " *"} type="text" id="empresa" name="empresa" />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md="6">
                                <Input className="border-input" defaultValue={!loading && direc != null ? direc.pais : ""} placeholder={t("_pais") + " *"} type="text" id="pais" name="pais" />
                            </Col>
                            <Col md="6">
                                <Input className="border-input" defaultValue={!loading && direc != null ? direc.provincia : ""} placeholder={t("_provincia") + " *"} type="text" id="provincia" name="provincia" />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md="6">
                                <Input className="border-input" defaultValue={!loading && direc != null ? direc.direccion : ""} placeholder={t("_direccion") + " *"} type="text" id="direccion" name="direccion" />
                            </Col>
                            <Col md="6">
                                <Input className="border-input" defaultValue={!loading && direc != null ? direc.codPostal : ""} placeholder={t("_codpostal") + " *"} type="text" id="codPostal" name="codPostal" />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md="6">
                                <Input className="border-input" defaultValue={!loading && direc != null ? direc.poblacion : ""} placeholder={t("_poblacion") + " *"} type="text" id="poblacion" name="poblacion" />
                            </Col>
                            <Col md="6">
                                <Input className="border-input" defaultValue={!loading && direc != null ? direc.telefono : ""} placeholder={t("_telefono") + " *"} type="text" id="telefono" name="telefono" />
                            </Col>
                        </Row>
                        <br />
                        {saveerror ? <center><span className='text-danger'><b>{t("_nosehizooperacion")}</b></span></center> : ""}
                        <br />
                        <Row>
                            <Col md="12">
                                <div className="pull-right">
                                    <Button className="btn-wd" color="danger" outline style={{ marginRight: 5 }} onClick={(e) => { e.preventDefault(); setModal(-1) }}>{t("_cancelar")}</Button>
                                    <Button className="btn-wd" color="danger" type="submit" >{t("_guardar")}</Button>                                    
                                </div>
                            </Col>
                        </Row>
                    </Form>
                    <div outline style={{ marginRight: 5, height:25 }} />
                </Container>               
                
            </Modal>
        </>
    );
}

export default DireccionModal;